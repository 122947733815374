import { BaseApiClient } from '@repo/shared/api';
import { AuditTemplatesFilters } from '@application/AuditTemplates/models/AuditTemplatesFilters';
import { IPagedResponse } from '@repo/shared/types';
import { ExportAuditTemplatePdfParams } from '@application/AuditTemplates/models/ExportAuditTemplatePdfParams';
import { CancelToken } from 'axios';
import { AuditTemplateGroupDto } from '@infrastructure/AuditTemplates/models/AuditTemplateGroupDto';
import { transformAuditTemplateGroupDto } from '@infrastructure/AuditTemplates/transformers/transformAuditTemplateGroupDto';
import { Industry, Locale } from '@repo/shared/enums';
import { AuditTemplateSuggestion } from '@domain/AuditTemplates/AuditTemplateSuggestion';
import { getIndustryName } from '@utils';

class AuditTemplatesApiClient extends BaseApiClient {
  public async getAuditTemplateGroups(filters: AuditTemplatesFilters) {
    const { data, meta } = await this._apiService.get<
      IPagedResponse<AuditTemplateGroupDto>
    >({
      url: 'audit/templates/detailed',
      query: {
        ...filters,
      },
    });

    return {
      data: data.map((dto) => transformAuditTemplateGroupDto(dto)),
      meta,
    };
  }

  public async deleteTemplate(id: string, draft?: boolean) {
    await this._apiService.delete({
      url: draft ? `audit/templates/draft/${id}` : `audit/templates/${id}`,
    });
  }

  public async setDefaultTemplate(id: string) {
    await this._apiService.post({
      url: `audit/templates/${id}/set-default`,
    });
  }

  public async copyTemplate(templateId: string, companyId: string) {
    await this._apiService.post({
      url: `library/templates/copy/${companyId}`,
      body: {
        ids: [templateId],
      },
    });
  }

  public async exportTemplatePdf({
    includeAnswerPoints,
    includeInformation,
    locale,
    cancelToken,
    templateId,
  }: ExportAuditTemplatePdfParams & { cancelToken: CancelToken }) {
    const { data } = await this._apiService.get<{
      data: ArrayBuffer;
      headers: Record<string, string>;
    }>({
      responseType: 'arraybuffer',
      url: `audit/templates/${templateId}/export-pdf`,
      query: { includeAnswerPoints, includeInformation, locale },
      withHeaders: true,
      cancelToken,
    });

    return data;
  }

  public async getPrompts(industry: Industry, locale: Locale) {
    return this._apiService.post<string[]>({
      url: `audit/templates/prompts`,
      body: {
        industry: getIndustryName(industry),
        locale,
      },
    });
  }

  public async getSuggestion(prompt: string) {
    return this._apiService.post<AuditTemplateSuggestion>({
      url: `audit/templates/suggestion`,
      body: {
        prompt,
      },
    });
  }
}

export default AuditTemplatesApiClient;
