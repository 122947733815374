import styled from 'styled-components';
import { animated } from 'react-spring';

import { colors, Z_INDEX } from '@repo/shared/config';

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background: ${colors.blue7};
  z-index: ${Z_INDEX.APP_BAR};
`;

export const MenuTriggerBtn = styled.button`
  background: none;
  border: 0;
  padding: 0;
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
    color: ${colors.white};
  }
`;

export const MenuContentWrapper = styled(animated.div)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: ${colors.blue6};
`;

export const MenuHeader = styled.div`
  margin: 20px 20px 0;
`;

export const MenuFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: ${colors.blue7};
  padding: 20px 20px 15px;

  & > * {
    margin-top: 10px;
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -10px;

  & > * {
    margin: 0 10px;
  }
`;
