import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 60px 95px 25px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const AIIcon = styled.div`
  margin-right: 18px;
  width: 64px;
  height: 64px;
`;

export const Title = styled.div`
  font-size: 28px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.gray8};
`;
