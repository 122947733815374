import { lazy } from 'react';
import { lazyWithPreload } from 'react-lazy-with-preload';

export const Roles = lazy(() => import('@components/roles/Roles'));
export const Scores = lazy(() => import('@components/scores/Scores'));
export const Accounts = lazy(
  () => import('@components/account/Accounts/Accounts')
);

export const Notifications = lazy(
  () => import('@components/settings/Notifications/Notifications')
);
export const EditProfile = lazy(
  () => import('@components/account/EditProfile/EditProfile')
);
export const Integrations = lazy(
  () => import('@components/settings/Integrations/Integrations')
);
export const AttributeDetails = lazy(
  () =>
    import(
      '@components/auditObjects/attributes/single/AttributeDetails/AttributeDetails'
    )
);
export const PublicAuditReport = lazy(
  () => import('@presentation/Audits/PublicAuditReport/PublicAuditReport')
);
export const CreateIssuePublicPageError = lazy(
  () =>
    import(
      '@presentation/Issues/CreateIssuePublicPage/CreateIssuePublicPageError/CreateIssuePublicPageError'
    )
);
export const CustomResponseTypes = lazy(
  () => import('@presentation/CustomResponseTypes/CustomResponseTypes')
);
export const Subscription = lazy(
  () => import('@components/account/Billing/Subscription/Subscription')
);
export const Invoices = lazy(
  () => import('@components/account/Billing/Invoices/Invoices')
);
export const Payment = lazy(
  () => import('@components/account/Billing/Payment/Payment')
);
export const GeneralSettings = lazy(
  () => import('@components/settings/General/General')
);
export const Dashboard = lazy(() => import('@components/dashboard/Dashboard'));

export const Schedules = lazy(
  () => import('@presentation/Schedules/Schedules')
);
export const SchedulePlans = lazy(
  () => import('@presentation/SchedulePlans/SchedulePlans')
);
export const SchedulePlanDetails = lazy(
  () =>
    import(
      '@presentation/SchedulePlans/SchedulePlanDetails/SchedulePlanDetails'
    )
);

export const ItemsTags = lazy(() => import('@components/tags/ItemsTags'));
export const AuditsTags = lazy(() => import('@components/tags/AuditsTags'));
export const ActionsTags = lazy(() => import('@components/tags/ActionsTags'));

export const Users = lazy(() => import('@components/users/list/Users'));
export const AttributeAssignments = lazy(
  () =>
    import(
      '@components/auditObjects/attributes/single/AttributesAssignments/AttributeAssignments'
    )
);
export const Attributes = lazy(
  () => import('@components/auditObjects/attributes/list/Attributes')
);
export const UsersGroups = lazy(
  () => import('@components/users/groups/list/UsersGroups')
);
export const JobTitles = lazy(
  () => import('@components/users/jobTitles/JobTitles')
);
export const UserDetails = lazy(
  () => import('@components/users/single/UserDetails/UserDetails')
);
export const UserGroups = lazy(
  () => import('@components/users/single/UserGroups')
);
export const UserAccess = lazy(
  () => import('@components/users/single/UserAccess/UserAccess')
);
export const UserGroupAssignments = lazy(
  () =>
    import(
      '@components/users/groups/single/UserGroupAssignments/UserGroupAssignments'
    )
);
export const UserGroupDetails = lazy(
  () =>
    import('@components/users/groups/single/UserGroupDetails/UserGroupDetails')
);

export const AuditObjects = lazy(
  () => import('@components/auditObjects/list/AuditObjects')
);
export const AuditObjectsGroups = lazy(
  () => import('@components/auditObjects/groups/list/AuditObjectsGroups')
);
export const AuditObjectDetails = lazy(
  () =>
    import(
      '@components/auditObjects/single/AuditObjectDetails/AuditObjectDetails'
    )
);
export const AuditObjectGroups = lazy(
  () => import('@components/auditObjects/single/AuditObjectGroups')
);
export const AuditObjectAccess = lazy(
  () => import('@components/auditObjects/single/AuditObjectAccess')
);
export const AuditObjectAttributes = lazy(
  () => import('@components/auditObjects/single/AuditObjectAttributes')
);
export const AuditObjectGroupDetails = lazy(
  () =>
    import(
      '@components/auditObjects/groups/single/AuditObjectGroupDetails/AuditObjectGroupDetails'
    )
);
export const AuditObjectGroupAssignments = lazy(
  () =>
    import(
      '@components/auditObjects/groups/single/AuditObjectGroupAssignments/AuditObjectGroupAssignments'
    )
);
export const AuditObjectQrCodes = lazy(
  () => import('@components/auditObjects/single/AuditObjectQrCodes')
);
export const AuditObjectGroupQrCodes = lazy(
  () => import('@components/auditObjects/groups/single/AuditObjectGroupQrCodes')
);

export const PendingAudits = lazyWithPreload(
  () => import('@presentation/Audits/PendingAudits')
);
export const CompletedAudits = lazyWithPreload(
  () => import('@presentation/Audits/CompletedAudits')
);
export const ExpiredAudits = lazyWithPreload(
  () => import('@presentation/Audits/ExpiredAudits')
);

export const IssueTypes = lazy(
  () => import('@presentation/IssueTypes/IssueTypes')
);
export const IssueTypeDetails = lazy(
  () =>
    import(
      '@presentation/IssueTypes/IssueType/IssueTypeDetails/IssueTypeDetails'
    )
);
export const PendingIssues = lazyWithPreload(
  () => import('@presentation/Issues/PendingIssues')
);
export const ResolvedIssues = lazyWithPreload(
  () => import('@presentation/Issues/ResolvedIssues')
);
export const CreateIssuePublicPage = lazy(
  () =>
    import('@presentation/Issues/CreateIssuePublicPage/CreateIssuePublicPage')
);
export const IssueTypeQrCodes = lazy(
  () => import('@presentation/IssueTypes/IssueTypeQrCodes/IssueTypeQrCodes')
);
export const IssueTypeDetailsQrCodes = lazy(
  () => import('@presentation/IssueTypes/IssueType/IssueTypeDetailsQrCodes')
);
export const IssueTypeRoot = lazy(
  () => import('@presentation/IssueTypes/IssueType/IssueTypeRoot')
);

export const PendingActions = lazyWithPreload(
  () => import('@presentation/Actions/PendingActions')
);
export const MyActions = lazyWithPreload(
  () => import('@presentation/Actions/MyActions')
);
export const CompletedActions = lazyWithPreload(
  () => import('@presentation/Actions/CompletedActions')
);
export const ExpiredActions = lazyWithPreload(
  () => import('@presentation/Actions/ExpiredActions')
);
export const MyIssues = lazyWithPreload(
  () => import('@presentation/Issues/MyIssues')
);
export const QuestionSets = lazy(
  () => import('@presentation/QuestionSets/QuestionSets')
);
export const AuditTemplates = lazy(
  () => import('@presentation/AuditTemplates/AuditTemplates')
);
export const ActionTemplates = lazy(
  () => import('@presentation/ActionTemplates/ActionTemplates')
);
export const AuditPerformanceReport = lazy(
  () => import('@components/reports/auditPerformance/AuditPerformanceReport')
);
export const CustomReport = lazy(
  () => import('@components/reports/custom/CustomReport')
);
export const ItemAnalysisReport = lazy(
  () => import('@components/reports/itemAnalysis/ItemAnalysisReport')
);
export const HistoricalPerformanceReport = lazy(
  () => import('@components/reports/performance/HistoricalPerformanceReport')
);
export const RealTimePerformanceReport = lazy(
  () => import('@components/reports/performance/RealTimePerformanceReport')
);
export const SummaryReport = lazy(
  () => import('@components/reports/summary/SummaryReport')
);
export const SchedulePlanPeriodDetails = lazy(
  () =>
    import(
      '@presentation/SchedulePlans/SchedulePlanPeriodDetails/SchedulePlanPeriodDetails'
    )
);
export const ImportTasks = lazyWithPreload(
  () => import('@presentation/TransferTasks/ImportTasks')
);
export const ExportTasks = lazyWithPreload(
  () => import('@presentation/TransferTasks/ExportTasks')
);
