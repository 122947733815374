import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IPagedResponse, ITableFilters } from '@repo/shared/types';
import { QuestionSet } from '@domain/QuestionSets/QuestionSet';
import QuestionSetsApiClient from '@infrastructure/QuestionSets/api/QuestionSetsApiClient';
import { getErrorMessage } from '@repo/shared/utils';
import { questionSetsSelectors } from '@application/QuestionSets/store/questionSetsSelectors';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';
import { QuestionSetDetails } from '@domain/QuestionSets/QuestionSetDetails';
import { QuestionSetDetailsModalTab } from '@application/QuestionSets/enums/QuestionSetDetailsModalTab';
import { QuestionSetTemplateMapping } from '@domain/QuestionSets/QuestionSetTemplateMapping';
import { Logger } from '@repo/shared/services';

const questionSetsApiClient = new QuestionSetsApiClient();

const getQuestionSets = createAsyncThunk<
  IPagedResponse<QuestionSet>,
  Partial<ITableFilters> | null | undefined,
  { rejectValue: string }
>(
  'templateComponents/getQuestionSets',
  async (_, { rejectWithValue, getState }) => {
    try {
      return await questionSetsApiClient.getQuestionSets(
        questionSetsSelectors.getQuestionSetsFilters(getState())
      );
    } catch (e) {
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const toggleConfirmEditQuestionSetModal = createAction<string | null>(
  'templateComponents/toggleConfirmEditQuestionSetModal'
);

const toggleDeleteQuestionSetsModal = createAction<
  DeleteModalState<QuestionSetDetails | QuestionSet>
>('templateComponents/toggleDeleteQuestionSetsModal');

const deleteQuestionSets = createAsyncThunk<
  void,
  string[],
  { rejectValue: string }
>('templateComponents/deleteQuestionSets', async (ids, { rejectWithValue }) => {
  try {
    await questionSetsApiClient.deleteQuestionSets(ids);
  } catch (e) {
    return rejectWithValue(getErrorMessage(e));
  }
});

const toggleQuestionSetDetailsModal = createAction<string | null>(
  'templateComponents/toggleQuestionSetDetailsModal'
);

const setQuestionSetDetailsModalTab = createAction<QuestionSetDetailsModalTab>(
  'templateComponents/setQuestionSetDetailsModal'
);

const getQuestionSetDetails = createAsyncThunk<
  QuestionSetDetails,
  string,
  { rejectValue: string }
>('templateComponents/deleteQuestionSets', async (id, { rejectWithValue }) => {
  try {
    return await questionSetsApiClient.getQuestionSetDetails(id);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const getQuestionSetTemplates = createAsyncThunk<
  QuestionSetTemplateMapping[],
  string,
  { rejectValue: string }
>(
  'templateComponents/getQuestionSetTemplates',
  async (questionSetId, { rejectWithValue }) => {
    try {
      return await questionSetsApiClient.getQuestionSetTemplates(questionSetId);
    } catch (e) {
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

export const questionSetsActions = {
  getQuestionSets,
  toggleConfirmEditQuestionSetModal,
  toggleDeleteQuestionSetsModal,
  deleteQuestionSets,
  toggleQuestionSetDetailsModal,
  getQuestionSetDetails,
  setQuestionSetDetailsModalTab,
  getQuestionSetTemplates,
};
