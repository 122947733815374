import styled from 'styled-components';
import Layout from 'antd/es/layout';

import { colors, BREAKPOINTS } from '@repo/shared/config';

const { Content: AntContent, Header: AntHeader } = Layout;

export const Container = styled(Layout)`
  min-height: 100vh;
  min-width: ${BREAKPOINTS.XL}px;
`;

export const Header = styled(AntHeader)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${colors.blue6};
  padding: 0 24px;
`;

export const Content = styled(Layout)`
  background: ${colors.blue6};
`;

export const Main = styled(AntContent)`
  margin: 0 25px 25px;
  padding: 20px 23px 18px;
  background: ${colors.white};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
