import { ImportTaskSummary } from '@domain/TransferTasks/ImportTaskSummary';
import {
  ImportRecord,
  ImportRecordCode,
  ImportRecordType,
} from '@domain/TransferTasks/ImportRecord';

export function convertImportRecordsToSummary(
  records: ImportRecord[]
): ImportTaskSummary {
  return records.reduce<ImportTaskSummary>(
    (acc, record) => {
      const a = acc;

      a.all.push(record);

      switch (record.type) {
        case ImportRecordType.Error:
          a.errors.push(record);
          break;
        case ImportRecordType.Warning:
          a.warnings.push(record);
          break;
      }

      if (record.code === ImportRecordCode.ItemCreate) {
        a.addedRecords++;
      }

      if (record.code === ImportRecordCode.ItemDelete) {
        a.removedRecords++;
      }

      return a;
    },
    {
      all: [],
      warnings: [],
      errors: [],
      addedRecords: 0,
      removedRecords: 0,
    }
  );
}
