import { IRootState } from '@src/core/frameworks/redux';
import { OnboardingChatMessage } from '@application/Onboarding/models/OnboardingChatMessage';
import { onboardingChatMessagesEntityAdapter } from '@application/Onboarding/store/entityAdapters';
import { createSelector, EntityState } from '@reduxjs/toolkit';
import { OnboardingAssistantStep } from '@application/Onboarding/enums/OnboardingAssistantStep';
import { OnboardingChatData } from '@application/Onboarding/models/OnboardingChatData';
import { OnboardingModalMode } from '@application/Onboarding/enums/OnboardingModalMode';
import { CompletedAssistantStep } from '@application/Onboarding/models/CompletedAssistantStep';
import { OnboardingAssistantProgressStep } from '@application/Onboarding/enums/OnboardingAssistantProgressStep';
import { OnboardingStepStatus } from '@presentation/Onboarding/OnboardingModal/OnboardingAssistant/OnboardingAssistantProgress/OnboardingStepStatus';

const getThreadId = (state: IRootState): string | null =>
  state.onboarding.assistant.threadId;

const isStarting = (state: IRootState): boolean =>
  state.onboarding.assistant.starting;

const isAssistantThinking = (state: IRootState): boolean =>
  state.onboarding.assistant.thinking;

const getError = (state: IRootState): string | null =>
  state.onboarding.assistant.error;

const getMessages = createSelector(
  (state: IRootState): EntityState<OnboardingChatMessage, string> =>
    state.onboarding.assistant.messages,
  (messages) =>
    onboardingChatMessagesEntityAdapter.getSelectors().selectAll(messages)
);

const getCurrentStep = (state: IRootState): OnboardingAssistantStep | null =>
  state.onboarding.assistant.currentStep;

const getCollectedData = (state: IRootState): Partial<OnboardingChatData> =>
  state.onboarding.assistant.data;

const getOnboardingModalMode = (
  state: IRootState
): OnboardingModalMode | null => state.onboarding.onboardingModalMode;

const getCompletedStepsSummary = createSelector(
  [
    (
      state: IRootState
    ): Partial<Record<OnboardingAssistantStep, CompletedAssistantStep>> =>
      state.onboarding.assistant.completedSteps,
  ],
  (completedSteps) => {
    const progressStepsMapping = {
      bySteps: {
        [OnboardingAssistantProgressStep.Company]: [
          OnboardingAssistantStep.EnterCompanyName,
          OnboardingAssistantStep.SelectIndustry,
          OnboardingAssistantStep.SelectUsagePurpose,
        ],
        [OnboardingAssistantProgressStep.AuditObject]: [
          OnboardingAssistantStep.EnterObjectNameAndLocation,
        ],
        [OnboardingAssistantProgressStep.InviteMembers]: [
          OnboardingAssistantStep.EnterUsers,
        ],
      },
      allSteps: [
        OnboardingAssistantProgressStep.Company,
        OnboardingAssistantProgressStep.AuditObject,
        OnboardingAssistantProgressStep.InviteMembers,
      ],
    };

    const currentStep = progressStepsMapping.allSteps.find((progressStep) => {
      return !progressStepsMapping.bySteps[progressStep].every(
        (requiredStep) => !!completedSteps[requiredStep]
      );
    });

    return progressStepsMapping.allSteps.map((progressStep) => {
      const requiredSteps = progressStepsMapping.bySteps[progressStep];

      const completedStepsForStep = requiredSteps
        .map((requiredStep) => completedSteps[requiredStep])
        .filter(Boolean) as CompletedAssistantStep[];

      let status: OnboardingStepStatus;

      if (completedStepsForStep.length === requiredSteps.length) {
        status = OnboardingStepStatus.Done;
      } else if (progressStep === currentStep) {
        status = OnboardingStepStatus.Current;
      } else {
        status = OnboardingStepStatus.Waiting;
      }

      return {
        step: progressStep,
        completedSteps: completedStepsForStep,
        status,
      };
    });
  }
);

export const onboardingSelectors = {
  getThreadId,
  isStarting,
  isAssistantThinking,
  getError,
  getMessages,
  getCurrentStep,
  getCollectedData,
  getOnboardingModalMode,
  getCompletedStepsSummary,
};
