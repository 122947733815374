import { createReducer } from '@reduxjs/toolkit';
import { TransferTasksState } from '@application/TransferTasks/models/TransferTasksState';
import {
  exportTasksAdapter,
  importTasksAdapter,
} from '@application/TransferTasks/store/entityAdapters';
import { getInitialFilters } from '@utils';
import { transferTasksActions as actions } from '@application/TransferTasks/store/transferTasksActions';

const createImportTaskModalState = {
  opened: false,
  validation: {
    data: null,
    loading: false,
    error: null,
  },
};

export const transferTasksReducer = createReducer<TransferTasksState>(
  {
    type: null,
    showCreateExportTaskModal: false,
    loading: false,
    importTasks: importTasksAdapter.getInitialState(),
    exportTasks: exportTasksAdapter.getInitialState(),
    error: null,
    meta: null,
    filters: {
      ...getInitialFilters(),
    },
    createImportTaskModal: {
      ...createImportTaskModalState,
    },
    importTaskDetailsModal: {
      id: null,
      data: null,
      loading: false,
      error: null,
    },
  },
  (builder) =>
    builder
      .addCase(actions.setTaskType, (state, { payload }) => {
        state.type = payload;
      })
      .addCase(actions.getImportTasks.pending, (state, { meta: { arg } }) => {
        state.loading = true;
        state.filters =
          arg === null
            ? { ...getInitialFilters() }
            : {
                ...state.filters,
                ...arg,
              };
      })
      .addCase(actions.getImportTasks.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload.meta;
        importTasksAdapter.setAll(state.importTasks, payload.data);
      })
      .addCase(actions.getImportTasks.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload || null;
      })
      .addCase(actions.getExportTasks.pending, (state, { meta: { arg } }) => {
        state.loading = true;
        state.filters =
          arg === null
            ? { ...getInitialFilters() }
            : {
                ...state.filters,
                ...arg,
              };
      })
      .addCase(actions.getExportTasks.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.meta = payload.meta;
        exportTasksAdapter.setAll(state.exportTasks, payload.data);
      })
      .addCase(actions.getExportTasks.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload || null;
      })
      .addCase(actions.resetTableData, (state) => {
        state.importTasks = importTasksAdapter.getInitialState();
        state.exportTasks = exportTasksAdapter.getInitialState();
        state.error = null;
        state.filters = { ...getInitialFilters() };
      })
      .addCase(actions.toggleCreateExportTaskModal, (state, { payload }) => {
        state.showCreateExportTaskModal = payload;
      })
      .addCase(actions.toggleCreateImportTaskModal, (state, { payload }) => {
        state.createImportTaskModal = {
          ...createImportTaskModalState,
          opened: payload,
        };
      })
      .addCase(actions.validateImportTask.pending, (state) => {
        state.createImportTaskModal.validation.loading = true;
      })
      .addCase(actions.validateImportTask.fulfilled, (state, { payload }) => {
        state.createImportTaskModal.validation.loading = false;
        state.createImportTaskModal.validation.data = payload;
      })
      .addCase(actions.validateImportTask.rejected, (state, { payload }) => {
        state.createImportTaskModal.validation.loading = false;
        state.createImportTaskModal.validation.error = payload || null;
      })
      .addCase(actions.getImportTaskDetails.pending, (state) => {
        state.importTaskDetailsModal.loading = true;
      })
      .addCase(actions.getImportTaskDetails.fulfilled, (state, { payload }) => {
        state.importTaskDetailsModal.loading = false;
        state.importTaskDetailsModal.data = payload;
      })
      .addCase(actions.getImportTaskDetails.rejected, (state, { payload }) => {
        state.importTaskDetailsModal.loading = false;
        state.importTaskDetailsModal.error = payload || null;
      })
      .addCase(actions.toggleImportTaskDetailsModal, (state, { payload }) => {
        state.importTaskDetailsModal.id = payload;
      })
      .addCase(actions.updateImportTask, (state, { payload }) => {
        importTasksAdapter.upsertOne(state.importTasks, payload);
      })
      .addCase(actions.updateExportTask, (state, { payload }) => {
        exportTasksAdapter.upsertOne(state.exportTasks, payload);
      })
);
