import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { getErrorMessage } from '@repo/shared/utils';
import { INotification, IIntegrations, FileMetadata } from '@repo/shared/types';
import { IRootState } from '../../../frameworks/redux';
import { Logger } from '@repo/shared/services';
import { apiUrls } from '@src/config/apiUrls';
import { MqaNotification, NotificationsTab } from '@repo/shared/enums';
import { InternalApiService } from '@repo/shared/api';

const apiService = InternalApiService.getInstance();

const deleteApiKey = createAsyncThunk<
  void,
  void,
  { state: IRootState; rejectValue: string }
>('settings/integrations/deleteApiKey', async (_, { rejectWithValue }) => {
  try {
    await apiService.delete({
      url: `${apiUrls.integrations}/api-key`,
    });
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

export const settingsActions = {
  getNotifications: createAsyncThunk<
    INotification[],
    void,
    { state: IRootState; rejectValue: string }
  >('settings/getNotifications', async (_, { rejectWithValue }) => {
    try {
      return apiService.get({
        url: apiUrls.notifications,
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }),
  updateNotification: createAsyncThunk<
    void,
    {
      notification: MqaNotification;
      isEnabled: boolean;
    },
    {
      state: IRootState;
      rejectValue: {
        error: string;
        notification: MqaNotification;
      };
    }
  >('settings/updateNotification', async (update, { rejectWithValue }) => {
    try {
      await apiService.put({
        url: `${apiUrls.notifications}/update`,
        body: update,
      });
    } catch (e) {
      Logger.captureException(e);

      return rejectWithValue({
        error: getErrorMessage(e),
        notification: update.notification,
      });
    }
  }),
  setNotificationsTab: createAction<NotificationsTab>(
    'settings/setNotificationsTab'
  ),
  integrations: {
    getIntegrations: createAsyncThunk<
      IIntegrations,
      void,
      { state: IRootState; rejectValue: string }
    >('settings/integrations/get', async (_, { rejectWithValue }) => {
      try {
        const response = await apiService.get<any>({
          url: apiUrls.integrations,
        });

        return {
          apiKey: response?.apiKey || null,
          webhook: response?.webhook?.url || null,
        };
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }),
    generateApiKey: createAsyncThunk<
      string,
      { regenerate: boolean },
      { state: IRootState; rejectValue: string }
    >(
      'settings/integrations/generateApiKey',
      async ({ regenerate }, { rejectWithValue, dispatch }) => {
        try {
          if (regenerate) {
            await dispatch(deleteApiKey());
          }

          return apiService.post({
            url: `${apiUrls.integrations}/api-key`,
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    toggleConfirmRegenerateModal: createAction<boolean>(
      'settings/integrations/toggleConfirmRegenerateModal'
    ),
    deleteApiKey,
    createWebhook: createAsyncThunk<
      string,
      string,
      { state: IRootState; rejectValue: string }
    >(
      'settings/integrations/createWebhook',
      async (url, { rejectWithValue }) => {
        try {
          await apiService.post({
            url: `${apiUrls.integrations}/webhook`,
            body: { url },
          });

          return url;
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    startDataImport: createAsyncThunk<
      void,
      FileMetadata,
      { state: IRootState; rejectValue: string }
    >(
      'settings/integrations/startDataImport',
      async (file, { rejectWithValue }) => {
        try {
          await apiService.get({
            url: `${apiUrls.import}/${file.id}`,
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    deleteWebhook: createAsyncThunk<
      void,
      void,
      { state: IRootState; rejectValue: string }
    >('settings/integrations/deleteWebhook', async (_, { rejectWithValue }) => {
      try {
        await apiService.delete({
          url: `${apiUrls.integrations}/webhook`,
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }),
  },
};
