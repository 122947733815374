import { UpdateAuditTemplateDetailsDto } from '@infrastructure/AuditBuilder/models/UpdateAuditTemplateDetailsDto';
import { transformTemplateItemToDto } from '@infrastructure/AuditBuilder/transformers/transformTemplateItemToDto';
import { AuditTemplateDetails } from '@domain/AuditTemplates/AuditTemplateDetails';
import { UpdateTemplateItemDto } from '@infrastructure/AuditBuilder/models/UpdateTemplateItemDto';

export function transformTemplateDetailsToDto({
  data,
  scoreSystem,
  ...template
}: AuditTemplateDetails): UpdateAuditTemplateDetailsDto {
  return {
    ...template,
    data: Object.entries(data).reduce<Record<string, UpdateTemplateItemDto>>(
      (acc, [id, item]) => {
        acc[id] = transformTemplateItemToDto(item);
        return acc;
      },
      {}
    ),
    scoreSystemId: scoreSystem.id,
  };
}
