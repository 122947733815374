import React, { useState } from 'react';

import { Container, Button } from './styled';
import { useAppDispatch } from '@hooks';
import { onboardingActions } from '@application/Onboarding/store/onboardingActions';

interface IProps {
  buttons: string[];
}

const ChatButtonsSelect: React.FC<React.PropsWithChildren<IProps>> = ({
  buttons,
}) => {
  const dispatch = useAppDispatch();

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  return (
    <Container>
      {buttons.map((label, i) => (
        <Button
          key={i}
          onClick={() => {
            if (selectedIndex === null) {
              dispatch(onboardingActions.sendUserMessage(label));
              setSelectedIndex(i);
            }
          }}
          selected={i === selectedIndex}
        >
          {label}
        </Button>
      ))}
    </Container>
  );
};

export default ChatButtonsSelect;
