import styled from 'styled-components';
import { animated } from 'react-spring';

import { colors } from '@repo/shared/config';

export const Container = styled(animated.div)`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
`;

export const CloseButton = styled.button`
  position: absolute;
  display: block;
  right: 5px;
  top: 5px;
  z-index: 2;
  width: 44px;
  height: 44px;
  padding: 0;
  background: none;
  border: 0;

  svg {
    color: ${colors.gray9};
    width: 24px;
    height: 24px;
  }
`;
