import { TemplateItem } from '@domain/AuditTemplates/TemplateItem';
import { IAuditTotals } from '@repo/shared/types';
import { AnswerType, ItemType } from '@repo/shared/enums';
import { calculateTemplatePreviewQuestionPoints } from '@application/AuditBuilder/utils/calculateTemplatePreviewQuestionPoints';

export function calculateTemplatePreviewTotals<T extends TemplateItem>(
  items: Record<string, T>,
  rootEntityId: string
) {
  const emptyTotals = {
    items: 0,
    subSections: 0,
    sections: 0,
    points: 0,
    selectedPoints: 0,
  };

  return Object.values(items).reduce(
    (acc: IAuditTotals, entity: T) => {
      const totals = acc;

      if (entity.itemType === ItemType.Root) {
        return acc;
      }

      const { parentId, itemType } = entity;

      if (parentId && !totals[parentId]) {
        totals[parentId] = { ...emptyTotals };
      }

      if (itemType === ItemType.Section) {
        if (parentId === rootEntityId) {
          totals[parentId].sections += 1;
        } else {
          totals[parentId].subSections += 1;
        }
      }

      if (itemType === ItemType.Item || itemType === ItemType.ConditionalItem) {
        const notApplicableItem = entity.disableNotApplicable;

        let totalPoints = 0;
        let selectedPoints = 0;

        if (!notApplicableItem && entity.answerType !== AnswerType.Text) {
          [totalPoints, selectedPoints] =
            calculateTemplatePreviewQuestionPoints(entity.data);
        }

        let currentParent: TemplateItem | null = items[parentId];

        do {
          if (currentParent) {
            if (!totals[currentParent.id]) {
              totals[currentParent.id] = { ...emptyTotals };
            }

            totals[currentParent.id].items += 1;

            totals[currentParent.id].selectedPoints += selectedPoints;
            totals[currentParent.id].points += totalPoints;

            currentParent = items[currentParent.id].parentId
              ? items[items[currentParent.id].parentId!]
              : null;
          }
        } while (currentParent);

        totals[rootEntityId].items += 1;
        totals[rootEntityId].selectedPoints += selectedPoints;
        totals[rootEntityId].points += totalPoints;
      }
      return totals;
    },
    {
      [rootEntityId]: { ...emptyTotals },
    }
  );
}
