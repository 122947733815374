import React from 'react';

import { useXlMediaQuery } from '@repo/shared/hooks';

import DesktopLayout from '@components/account/AppLayout/DesktopLayout/DesktopLayout';
import MobileAndTabletLayout from '@components/account/AppLayout/MobileAndTableLayout/MobileAndTabletLayout';
import ChunkErrorBoundary from '@components/account/AppLayout/ChunkErrorBoundary';

const AppLayout: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const isDesktop = useXlMediaQuery();

  return (
    <ChunkErrorBoundary>
      {isDesktop ? (
        <DesktopLayout>{children}</DesktopLayout>
      ) : (
        <MobileAndTabletLayout>{children}</MobileAndTabletLayout>
      )}
    </ChunkErrorBoundary>
  );
};

export default AppLayout;
