import React from 'react';

import { OnboardingAssistantStep } from '@application/Onboarding/enums/OnboardingAssistantStep';

import IndustryChatButtons from '@presentation/Onboarding/OnboardingModal/OnboardingAssistant/OnboardingAssistantChat/OnboardingAssistantChatMessages/CurrentStepAddons/IndustryChatButtons';
import UsagePurposeChatButtons from '@presentation/Onboarding/OnboardingModal/OnboardingAssistant/OnboardingAssistantChat/OnboardingAssistantChatMessages/CurrentStepAddons/UsagePurposeChatButtons';

interface IProps {
  step: OnboardingAssistantStep | null;
}

const CurrentStepAddons: React.FC<React.PropsWithChildren<IProps>> = ({
  step,
}) => {
  switch (step) {
    case OnboardingAssistantStep.SelectIndustry:
      return <IndustryChatButtons />;
    case OnboardingAssistantStep.SelectUsagePurpose:
      return <UsagePurposeChatButtons />;
    default:
      return null;
  }
};

export default CurrentStepAddons;
