import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { Logger } from '@repo/shared/services';
import { getErrorMessage } from '@repo/shared/utils';
import { AssistantMessage } from '@application/Onboarding/models/OnboardingChatMessage';
import { onboardingSelectors } from '@application/Onboarding/store/onboardingSelectors';
import OnboardingAssistantApiClient from '@infrastructure/Onboarding/api/OnboardingAssistantApiClient';
import { UnexpectedError } from '@repo/shared/errors';
import { OnboardingModalMode } from '@application/Onboarding/enums/OnboardingModalMode';

const apiClient = new OnboardingAssistantApiClient();

const sendUserMessage = createAsyncThunk<
  AssistantMessage,
  string,
  { rejectValue: string }
>('onboarding/sendUserMessage', async (text, { rejectWithValue, getState }) => {
  try {
    const threadId = onboardingSelectors.getThreadId(getState());

    if (!threadId) {
      throw new UnexpectedError(
        'publishOnboardingChatMessage: threadId is required'
      );
    }

    return await apiClient.sendMessage(threadId, text);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const startChat = createAsyncThunk<string, void, { rejectValue: string }>(
  'onboarding/startChat',
  async (_, { rejectWithValue }) => {
    try {
      return await apiClient.startChat();
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const toggleOnboardingModal = createAction<OnboardingModalMode | null>(
  'onboarding/toggleOnboardingModal'
);

export const onboardingActions = {
  startChat,
  sendUserMessage,
  toggleOnboardingModal,
};
