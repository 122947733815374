import { ItemType } from '@repo/shared/enums';
import {
  TemplateConditionalQuestionItem,
  TemplateItem,
  TemplateSectionItem,
} from '@domain/AuditTemplates/TemplateItem';

export function getAncestorsSections(
  items: Record<string, TemplateItem>,
  itemId: string,
  rootItemId: string
): {
  subSection: TemplateSectionItem | null;
  section: TemplateSectionItem | null;
  conditionalItem: TemplateConditionalQuestionItem | null;
} {
  let id: string | null = itemId;

  let section: TemplateSectionItem | null = null;
  let subSection: TemplateSectionItem | null = null;
  let conditionalItem: TemplateConditionalQuestionItem | null = null;

  while (id && id !== rootItemId) {
    const item: TemplateItem = items[id];

    if (item.itemType === ItemType.Section) {
      if (item.parentId === rootItemId) {
        section = item;
      } else {
        subSection = item;
      }
    } else if (item.itemType === ItemType.ConditionalItem) {
      conditionalItem = item;
    }

    id = item.parentId;
  }

  return { section, subSection, conditionalItem };
}
