import { GetTemplateItemDto } from '@infrastructure/AuditBuilder/models/GetTemplateItemDto';
import { TemplateItem } from '@domain/AuditTemplates/TemplateItem';
import { AnswerType, ItemType } from '@repo/shared/enums';
import {
  AnswerData,
  AnswerTypeData,
  ButtonsData,
  ChecklistData,
  DropdownData,
  NumericIntervalsData,
  SliderData,
  TemperatureData,
  TextData,
} from '@domain/AuditTemplates/AnswerTypeData';
import { UnexpectedError } from '@repo/shared/errors';

export function transformDtoToTemplateItem(
  dtoItems: Record<string, GetTemplateItemDto>,
  itemId: string
): TemplateItem {
  const dto = dtoItems[itemId];

  switch (dto.itemType) {
    case ItemType.Root:
      return {
        id: dto.id,
        index: dto.index,
        childrenIds: dto.childrenIds,
        itemType: dto.itemType,
        parentId: null,
        disableNotApplicable: dto.disableNotApplicable,
      };
    case ItemType.Condition:
      if (!dto.condition) {
        throw new UnexpectedError(
          `condition is null for condition item with id ${dto.id}`
        );
      }

      if (!dto.parentId) {
        throw new UnexpectedError(
          `parentId is null for condition with id ${dto.id}`
        );
      }

      return {
        id: dto.id,
        childrenIds: dto.childrenIds,
        itemType: dto.itemType,
        condition: dto.condition,
        questionSetItemId: dto.questionSetItemId,
        index: dto.index,
        parentId: dto.parentId,
      };
    case ItemType.Section:
      if (!dto.parentId) {
        throw new UnexpectedError(
          `parentId is null for section with id ${dto.id}`
        );
      }

      return {
        id: dto.id,
        itemType: dto.itemType,
        childrenIds: dto.childrenIds,
        text: dto.text || '',
        index: dto.index,
        visibility: dto.visibility,
        parentId: dto.parentId,
        disableNotApplicable: dto.disableNotApplicable,
      };
    case ItemType.QuestionSet:
      if (!dto.parentId) {
        throw new UnexpectedError(
          `parentId is null for question set item with id ${dto.id}`
        );
      }

      if (!dto.questionSetId) {
        throw new UnexpectedError(
          `questionSetId is null for question set item with id ${dto.id}`
        );
      }

      return {
        id: dto.id,
        itemType: dto.itemType,
        childrenIds: dto.childrenIds,
        text: dto.text || '',
        index: dto.index,
        parentId: dto.parentId,
        questionSetId: dto.questionSetId,
      };
    case ItemType.Item:
    case ItemType.ConditionalItem: {
      if (!dto.parentId) {
        throw new UnexpectedError(
          `parentId is null for item with id ${dto.id}`
        );
      }

      if (!dto.data) {
        throw new UnexpectedError(`data is null for item with id ${dto.id}`);
      }

      if (dto.answerType === null) {
        throw new UnexpectedError(
          `answerType is null for item with id ${dto.id}`
        );
      }

      return {
        id: dto.id,
        itemType: dto.itemType,
        childrenIds: dto.childrenIds,
        text: dto.text || '',
        index: dto.index,
        parentId: dto.parentId,
        isCritical: dto.isCritical,
        hasInformation: dto.hasInformation,
        isSignatureRequired: dto.isSignatureRequired,
        itemTags: dto.itemTags,
        information: dto.information,
        disableNotApplicable: dto.disableNotApplicable,
        actionTemplates: dto.actionTemplates,
        questionSetItemId: dto.questionSetItemId,
        conditions: dto.conditions,
        ...transformData(dto.answerType, dto.data),
      };
    }
    default:
      throw new UnexpectedError(
        `Unknown item type ${dto.itemType}, itemId ${dto.id}`
      );
  }
}

function transformData(
  answerType: AnswerType,
  data: AnswerData
): AnswerTypeData {
  switch (answerType) {
    case AnswerType.PassFailButtons:
    case AnswerType.YesNoButtons:
      return {
        answerType,
        data: data as ButtonsData,
      };
    case AnswerType.Dropdown:
      return {
        answerType,
        data: data as DropdownData,
      };
    case AnswerType.Temperature:
      return {
        answerType,
        data: data as TemperatureData,
      };
    case AnswerType.Slider:
      return {
        answerType,
        data: data as SliderData,
      };
    case AnswerType.Numeric:
      return {
        answerType,
        data: data as NumericIntervalsData,
      };
    case AnswerType.Checklist:
      return {
        answerType,
        data: data as ChecklistData,
      };
    case AnswerType.Text:
      return {
        answerType,
        data: data as TextData,
      };
    default:
      throw new UnexpectedError(`Unknown answerType: ${answerType}`);
  }
}

function getConditionValue(
  dtoItems: Record<string, GetTemplateItemDto>,
  conditionalItemDtoId: string
): string | null {
  const conditionalItem = dtoItems[conditionalItemDtoId];

  if (
    conditionalItem?.itemType !== ItemType.ConditionalItem ||
    conditionalItem.childrenIds.length === 0
  ) {
    return null;
  }

  const conditionItem = dtoItems[conditionalItem.childrenIds[0]];

  if (
    conditionItem?.itemType !== ItemType.Condition ||
    conditionItem.condition === null
  ) {
    throw new UnexpectedError(
      `Conditional item with id ${conditionalItem.id} does not have condition child`
    );
  }

  return conditionItem.condition.value;
}
