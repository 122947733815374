import styled from 'styled-components';
import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  width: 100%;
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background: ${colors.blue0};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div`
  max-width: calc(100% - 36px);
  flex-grow: 1;
`;
