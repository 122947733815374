import React from 'react';

import { UserMessage } from '@application/Onboarding/models/OnboardingChatMessage';
import { Container } from './styled';

interface IProps {
  message: UserMessage;
}

const UserChatMessage: React.FC<React.PropsWithChildren<IProps>> = ({
  message,
}) => {
  return (
    <Container>
      <div>{message.text}</div>
    </Container>
  );
};

export default UserChatMessage;
