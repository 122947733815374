import cloneDeep from 'lodash/cloneDeep';

import { AnswerType } from '@repo/shared/enums';
import {
  AnswerTypeDataMapping,
  ButtonsAnswerTypeData,
  ChecklistAnswerTypeData,
  DropdownAnswerTypeData,
  NumericAnswerTypeData,
  SliderAnswerTypeData,
  TemperatureAnswerTypeData,
  TextAnswerTypeData,
} from '@domain/AuditTemplates/AnswerTypeData';
import { UnexpectedError } from '@repo/shared/errors';
import { v4 as uuid } from 'uuid';

export function getAnswerTypeData(
  answerType: AnswerType,
  data: AnswerTypeDataMapping[AnswerType]
) {
  let answerTypeData;

  switch (answerType) {
    case AnswerType.PassFailButtons:
    case AnswerType.YesNoButtons: {
      answerTypeData = { answerType, data } as ButtonsAnswerTypeData;
      break;
    }
    case AnswerType.Numeric: {
      answerTypeData = { answerType, data } as NumericAnswerTypeData;
      break;
    }
    case AnswerType.Checklist: {
      answerTypeData = { answerType, data } as ChecklistAnswerTypeData;
      break;
    }
    case AnswerType.Slider: {
      answerTypeData = { answerType, data } as SliderAnswerTypeData;
      break;
    }
    case AnswerType.Text: {
      answerTypeData = { answerType, data } as TextAnswerTypeData;
      break;
    }
    case AnswerType.Dropdown: {
      answerTypeData = { answerType, data } as DropdownAnswerTypeData;
      break;
    }
    case AnswerType.Temperature: {
      answerTypeData = { answerType, data } as TemperatureAnswerTypeData;
      break;
    }
    default:
      throw new UnexpectedError('Unsupported answer type');
  }

  answerTypeData = cloneDeep(answerTypeData);

  if (answerTypeData.answerType === AnswerType.Text) {
    answerTypeData.data.id = uuid();
  } else {
    for (let i = 0; i < answerTypeData.data.conditions.length; i++) {
      answerTypeData.data.conditions[i].id = uuid();
    }
  }

  return answerTypeData;
}
