import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const AvatarContainer = styled.div``;

export const Info = styled.div`
  line-height: 1.4;
  margin-left: 8px;
`;

export const UserName = styled.div`
  font-size: 14px;
  color: ${colors.white};
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Company = styled.div`
  font-size: 12px;
  color: ${colors.gray6};
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DropDownMenuTrigger = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
    margin-left: 5px;
    color: ${colors.white};
  }
`;
