import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Container,
  Content,
  ProgressBar,
  ProgressStep,
  ProgressStepActions,
  ProgressStepActionBtn,
} from './styled';
import { OnboardingStepStatus } from '@presentation/Onboarding/OnboardingModal/OnboardingAssistant/OnboardingAssistantProgress/OnboardingStepStatus';
import { onboardingSelectors } from '@application/Onboarding/store/onboardingSelectors';
import { colors } from '@repo/shared/config';
import { OnboardingAssistantProgressStep } from '@application/Onboarding/enums/OnboardingAssistantProgressStep';
import { OnboardingAssistantStep } from '@application/Onboarding/enums/OnboardingAssistantStep';
import { useAppDispatch } from '@hooks';
import { onboardingActions } from '@application/Onboarding/store/onboardingActions';
import { getEnumKeyByValue } from '@utils';
import { Industry, UsagePurpose } from '@repo/shared/enums';

const progressStepToLangId = {
  [OnboardingAssistantProgressStep.Company]: 'Company',
  [OnboardingAssistantProgressStep.AuditObject]: 'AuditObject',
  [OnboardingAssistantProgressStep.InviteMembers]: 'InviteMembers',
};

interface IProps {}

const OnboardingAssistantProgress: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const completedStepsSummary = useSelector(
    onboardingSelectors.getCompletedStepsSummary
  );

  return (
    <Container>
      <Content>
        <ProgressBar>
          {completedStepsSummary.map(({ step, status }) => (
            <div
              key={step}
              style={{
                background:
                  status === OnboardingStepStatus.Done
                    ? colors.blue2
                    : colors.gray4,
              }}
            />
          ))}
        </ProgressBar>
        {completedStepsSummary.map(({ step, status, completedSteps }) => (
          <ProgressStep key={step} onboardingStepStatus={status}>
            {formatMessage({ id: progressStepToLangId[step] })}
            <ProgressStepActions>
              {completedSteps.map((completedStep) => {
                let name: string;
                let message: string;

                switch (completedStep.step) {
                  case OnboardingAssistantStep.EnterCompanyName:
                    name = completedStep.data;
                    message = 'I would like to change company name';
                    break;
                  case OnboardingAssistantStep.SelectIndustry:
                    name = formatMessage({
                      id: getEnumKeyByValue(Industry, completedStep.data),
                    });
                    message = 'I would like to change industry';
                    break;
                  case OnboardingAssistantStep.SelectUsagePurpose:
                    name = formatMessage({
                      id: getEnumKeyByValue(UsagePurpose, completedStep.data),
                    });
                    message = 'I would like to change audit object type';
                    break;
                  case OnboardingAssistantStep.EnterObjectNameAndLocation:
                    name = `${completedStep.data.name}, ${completedStep.data.address}`;
                    message =
                      'I would like to change audit object name or location';
                    break;
                  case OnboardingAssistantStep.EnterUsers:
                    name = formatMessage(
                      { id: 'XUsers' },
                      { count: completedStep.data.length }
                    );
                    message = 'I would like to change users I invite';
                    break;
                  default:
                    return null;
                }

                return (
                  <ProgressStepActionBtn
                    key={completedStep.step}
                    type="button"
                    onClick={() => {
                      dispatch(onboardingActions.sendUserMessage(message));
                    }}
                  >
                    {name}
                  </ProgressStepActionBtn>
                );
              })}
            </ProgressStepActions>
          </ProgressStep>
        ))}
      </Content>
    </Container>
  );
};

export default OnboardingAssistantProgress;
