import { CompletedAssistantStep } from '@application/Onboarding/models/CompletedAssistantStep';
import { OnboardingAssistantStep } from '@application/Onboarding/enums/OnboardingAssistantStep';

export enum OnboardingChatMessageRole {
  User = 'user',
  Assistant = 'assistant',
}

export interface AssistantMessageDto {
  text: string;
  nextStep: OnboardingAssistantStep | null;
  completedStep: CompletedAssistantStep | null;
}

export interface AssistantMessage extends AssistantMessageDto {
  id: string;
  role: OnboardingChatMessageRole.Assistant;
}

export interface UserMessageDto {
  text: string;
}

export interface UserMessage extends UserMessageDto {
  id: string;
  role: OnboardingChatMessageRole.User;
}

export type OnboardingChatMessage = AssistantMessage | UserMessage;
