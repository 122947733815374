import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import doneIcon from './done.svg';
import inlineEdition from './inline-edition.svg';
import { OnboardingStepStatus } from '@presentation/Onboarding/OnboardingModal/OnboardingAssistant/OnboardingAssistantProgress/OnboardingStepStatus';
import { hexToRGB } from '@repo/shared/utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 289px;
  background: ${colors.gray1};
  padding: 40px 30px 30px;
`;

export const Content = styled.div`
  flex-grow: 1;
  background: ${colors.gray1};
`;

export const ProgressBar = styled.div`
  width: 100%;
  display: flex;
  margin: 0 -3px 25px;

  & > div {
    margin: 0 3px;
    height: 6px;
    width: 54px;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
  }
`;

export const ProgressStep = styled.div`
  position: relative;
  padding-left: 20px;
  font-size: 14px;
  margin-bottom: 10px;

  &:before {
    position: absolute;
    content: '';
    display: block;

    ${({
      onboardingStepStatus,
    }: {
      onboardingStepStatus: OnboardingStepStatus;
    }) => {
      switch (onboardingStepStatus) {
        case OnboardingStepStatus.Done:
          return `
            left: -2px;
            top: 5px;
            width: 14px;
            height: 14px;
            background: url(${doneIcon}) no-repeat center center;
            background-size: cover;
        `;
        case OnboardingStepStatus.Current:
          return `
            left: 0;
            top: 8px;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background: ${colors.blue2};
        `;
        case OnboardingStepStatus.Waiting:
          return ``;
      }
    }}
  }
`;

export const ProgressStepActions = styled.div`
  margin: 6px -1px 0;
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 0 1px 4px;
  }
`;

export const ProgressStepActionBtn = styled.button`
  display: flex;
  align-items: center;
  background: ${hexToRGB(colors.blue2, 0.1)};
  border: 1px solid ${colors.blue2};
  border-radius: 5px;
  padding: 5px 8px;
  font-size: 13px;
  line-height: 1;
  color: ${colors.blue2};

  &:after {
    display: block;
    content: '';
    width: 14px;
    height: 14px;
    background-size: cover;
    margin-left: 5px;
    background: url(${inlineEdition}) no-repeat center center;
  }

  & > span {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const BackToWelcomeBtn = styled.button`
  padding: 0;
  font-size: 12px;
  color: ${colors.gray7};
  background: none;
  border: none;
`;
