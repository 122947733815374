import { UpdateTemplateItemDto } from '@infrastructure/AuditBuilder/models/UpdateTemplateItemDto';
import { TemplateItem } from '@domain/AuditTemplates/TemplateItem';
import { ActionType, ItemType } from '@repo/shared/enums';

const base: UpdateTemplateItemDto = {
  id: '0',
  index: 0,
  text: null,
  itemType: ItemType.Root,
  answerType: null,
  childrenIds: [],
  data: null,
  parentId: null,
  isCritical: false,
  hasInformation: false,
  information: null,
  itemTagsIds: [],
  isSignatureRequired: false,
  condition: null,
  disableNotApplicable: false,
  actionTemplatesIds: [],
  conditions: [],
  visibility: null,
  isDeleted: false,
  questionSetId: null,
  questionSetItemId: null,
};

export function transformTemplateItemToDto(
  item: TemplateItem
): UpdateTemplateItemDto {
  const baseItem = {
    ...base,
    itemType: item.itemType,
    id: item.id,
    index: item.index,
    parentId: item.parentId,
    childrenIds: item.childrenIds,
  };

  switch (item.itemType) {
    case ItemType.Root:
      return {
        ...baseItem,
        disableNotApplicable: item.disableNotApplicable,
      };
    case ItemType.Condition:
      return {
        ...baseItem,
        condition: item.condition,
        questionSetItemId: item.questionSetItemId,
      };
    case ItemType.Section:
      return {
        ...baseItem,
        text: item.text,
        visibility: item.visibility,
        disableNotApplicable: item.disableNotApplicable,
      };
    case ItemType.QuestionSet:
      return {
        ...baseItem,
        text: item.text,
        questionSetId: item.questionSetId,
      };
    case ItemType.Item:
    case ItemType.ConditionalItem:
      return {
        ...baseItem,
        text: item.text,
        disableNotApplicable: item.disableNotApplicable,
        isCritical: item.isCritical,
        information: item.information,
        itemTagsIds: item.itemTags.map((itemTag) => itemTag.id),
        actionTemplatesIds: item.actionTemplates.map((template) => template.id),
        isSignatureRequired: item.isSignatureRequired,
        answerType: item.answerType,
        data: item.data,
        conditions: item.conditions,
        questionSetItemId: item.questionSetItemId,
      };
  }
}
