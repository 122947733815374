import React, { useCallback } from 'react';
import { config, useSpring } from 'react-spring';
import { useSelector } from 'react-redux';

import { Container } from './styled';
import { useWindowSize } from '@repo/shared/hooks';
import { generalSelectors, generalActions } from '@store';
import { useAppDispatch } from '@hooks';

import Scrollbar from '@components/shared/Scrollbar/Scrollbar';

interface IProps {
  theme: 'light' | 'dark';
  children: ({ closeMenu }: { closeMenu: () => void }) => React.ReactNode;
}

const MobileMenu: React.FC<IProps> = ({ children, theme }) => {
  const dispatch = useAppDispatch();

  const { height } = useWindowSize();

  const opened = useSelector(generalSelectors.isMobileMenuOpened);

  const closeMenu = useCallback(() => {
    dispatch(generalActions.toggleMobileMenu(false));
  }, []);

  const styles = useSpring({
    config: config.stiff,
    from: { height: 0 },
    to: {
      height: opened && height ? height - 64 : 0,
    },
  });

  return (
    <Container style={styles} theme={theme}>
      {opened && <Scrollbar>{children({ closeMenu })}</Scrollbar>}
    </Container>
  );
};

export default MobileMenu;
