import React from 'react';

import { Industry } from '@repo/shared/enums';
import { enumToSelectOptions } from '@utils';
import { useTranslatedOptions } from '@utils';

import ChatButtonsSelect from '@presentation/Onboarding/OnboardingModal/OnboardingAssistant/OnboardingAssistantChat/OnboardingAssistantChatMessages/CurrentStepAddons/ChatButtonsSelect/ChatButtonsSelect';

const options = enumToSelectOptions<Industry>(Industry);

interface IProps {}

const IndustryChatButtons: React.FC<React.PropsWithChildren<IProps>> = () => {
  const buttons = useTranslatedOptions(options).map(({ label }) => label);

  return <ChatButtonsSelect buttons={buttons} />;
};

export default IndustryChatButtons;
