import React from 'react';
import { SelectProps } from 'antd/es/select';
import { ChevronDown } from 'react-feather';

import { StyledSelect } from './styled';

interface IProps extends SelectProps<any> {
  width?: string;
  e2eDataAttr?: string;
}

const Select: React.FC<React.PropsWithChildren<IProps>> = React.forwardRef(
  ({ e2eDataAttr, ...props }, _) => {
    const getPopupContainer =
      props.getPopupContainer !== undefined
        ? props.getPopupContainer
        : (trigger: any) => trigger.parentNode;

    return (
      <StyledSelect
        getPopupContainer={getPopupContainer}
        {...props}
        data-e2e={e2eDataAttr}
        suffixIcon={<ChevronDown />}
      />
    );
  }
);

export default Select;
