import styled from 'styled-components';
import { animated } from 'react-spring';

import leftPattern from './left-pattern.svg';
import rightPattern from './right-pattern.svg';

import { BREAKPOINTS, colors } from '@repo/shared/config';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;

  @media (min-width: ${BREAKPOINTS.LG}px) {
    background:
      url(${leftPattern}) no-repeat left top,
      url(${rightPattern}) no-repeat right top;
  }
`;

export const AnimatedContent = styled(animated.div)`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const Close = styled.div`
  width: 50px;
  height: 50px;
  margin-top: 10px;

  svg {
    width: 18px;
    height: 18px;
    color: ${colors.gray8};
  }
`;
