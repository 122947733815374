import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { PubNubProvider } from 'pubnub-react';
import PubNub from 'pubnub';
import { HelmetProvider } from 'react-helmet-async';

import 'antd/dist/reset.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { GlobalStyles } from '@repo/shared/styles';
import store from './core/frameworks/redux';
import TagManager from './core/services/gtm';
import { Logger } from '@repo/shared/services';
import { config } from '@repo/shared/config';
import { browserHistory } from './browserHistory';
import packageJson from '../package.json';

import App from './ui/components/App';

const pubnub = new PubNub({
  subscribeKey: config.pubnub.subKey,
  publishKey: config.pubnub.pubKey,
  uuid: 'not-authenticated',
});

TagManager.init();
Logger.init({
  version: packageJson.version,
  service: 'monitorqa.web-app',
  applicationId: '1e21def8-b9d4-4f6b-aed9-216c1334c6ea',
  clientToken: 'pub2b9782bf7a048df3dd52246f1b8d788a',
});

createRoot(document.getElementById('root')!).render(
  <HelmetProvider>
    <Provider store={store}>
      <PubNubProvider client={pubnub}>
        <HistoryRouter history={browserHistory}>
          <App />
          <GlobalStyles />
        </HistoryRouter>
      </PubNubProvider>
    </Provider>
  </HelmetProvider>
);

serviceWorkerRegistration.unregister();
// export {convertTemplateItemsMapToTree} from '@application/AuditBuilder/utils/convertTemplateItemsMapToTree';
// export {calculateTemplatePreviewQuestionPoints} from '@application/AuditBuilder/utils/calculateTemplatePreviewQuestionPoints';
// export {calculateTemplatePreviewTotals} from '@application/AuditBuilder/utils/calculateTemplatePreviewTotals';
// export {
//     convertTemplateItemToPerformAuditItem
// } from '@application/AuditBuilder/utils/convertTemplateItemToPerformAuditItem';
