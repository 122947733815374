import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: inline-block;
  background: ${colors.blue7};
  border-radius: 5px;
  padding: 7px 10px;
  color: ${colors.white};
  font-size: 12px;
  text-align: center;
  line-height: 1.7;

  strong {
    font-weight: 500;
  }

  a {
    display: block;
    font-weight: 500;
    text-transform: uppercase;
  }
`;
