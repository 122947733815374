import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Container, DeleteSampleDataBtn } from './styled';
import { accountSelectors, accountActions } from '@store';
import { useAppDispatch } from '@hooks';

import HeaderUser from './HeaderUser/HeaderUser';
import Space from 'antd/es/space';

const Header: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isAdmin = useSelector(accountSelectors.isAdmin);
  const company = useSelector(accountSelectors.getCompany);

  return (
    <Container>
      {isAdmin && company.hasSampleData && (
        <>
          <DeleteSampleDataBtn
            type="button"
            onClick={() => {
              dispatch(
                accountActions.toggleSampleDataModal({
                  visible: true,
                  operation: 'delete',
                })
              );
            }}
          >
            {formatMessage({ id: 'DeleteSampleData' })}
          </DeleteSampleDataBtn>
        </>
      )}
      <Space size={24}>
        <HeaderUser />
      </Space>
    </Container>
  );
};

export default Header;
