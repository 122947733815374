import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '../../../frameworks/redux';

import {
  AssigneesModalContext,
  AuditTemplateType,
  FeedbackModalPage,
  Modal,
} from '@repo/shared/enums';
import {
  accessibleAuditObjectsAdapter,
  actionTemplatesConciseAdapter,
  attributesAdapter,
  auditObjectsConciseAdapter,
  auditObjectsGroupConciseAdapter,
  conciseRolesAdapter,
  jobTitlesConciseAdapter,
  participantsAdapter,
  tagsConciseAdapter,
  templatesConciseAdapter,
  userGroupsConciseAdapter,
  usersConciseAdapter,
} from '@store/entityAdapters';
import { sortConciseArrayByName } from '@utils';
import {
  IFileUploads,
  IParticipant,
  IRating,
  ISharedFilters,
  IUserConcise,
} from '@repo/shared/types';

const getConciseUsers = createSelector(
  (state: IRootState) => state.shared.concise.users,
  (users) => ({
    ...users,
    data: usersConciseAdapter.getSelectors().selectAll(users.data),
  })
);
const getConciseUsersMap = createSelector(
  (state: IRootState) => state.shared.concise.users.data,
  (users) => usersConciseAdapter.getSelectors().selectEntities(users)
);
function filterUsersBasedOnPermissions<
  T extends Omit<IUserConcise, 'roleType'>,
>(data: T[], context: AssigneesModalContext) {
  return data.filter(({ canDoAudit, canDoCorrectiveAction, canEditIssue }) => {
    if (context === AssigneesModalContext.Action) {
      return canDoCorrectiveAction;
    }

    if (context === AssigneesModalContext.Audit) {
      return canDoAudit;
    }

    if (context === AssigneesModalContext.Issue) {
      return canEditIssue;
    }

    return false;
  });
}
const getConciseTemplatesMap = createSelector(
  (state: IRootState) => state.shared.concise.auditTemplates.data,
  (data) => templatesConciseAdapter.getSelectors().selectEntities(data)
);
const getSharedFilters = (state: IRootState): ISharedFilters =>
  state.shared.sharedFilters;

export const generalSelectors = {
  getSelectedTableRowsKeys: (state: IRootState): string[] =>
    state.shared.selectedTableRowKeys,
  getTimeZones: (state: IRootState) => state.shared.timeZones,
  getModal: (name: Modal) => (state: IRootState) => state.shared.modals[name],
  getConciseUsers,
  getConciseUsersMap,
  getConciseUserGroups: createSelector(
    (state: IRootState) => state.shared.concise.userGroups,
    (userGroups) => ({
      ...userGroups,
      data: userGroupsConciseAdapter.getSelectors().selectAll(userGroups.data),
    })
  ),
  getConciseUserGroupsMap: createSelector(
    (state: IRootState) => state.shared.concise.userGroups.data,
    (groups) => userGroupsConciseAdapter.getSelectors().selectEntities(groups)
  ),
  getConciseTemplates: createSelector(
    (state: IRootState) => state.shared.concise.auditTemplates,
    (auditTemplates) => ({
      ...auditTemplates,
      data: templatesConciseAdapter
        .getSelectors()
        .selectAll(auditTemplates.data),
    })
  ),
  getConciseTemplatesMap,
  getConciseAuditObjects: createSelector(
    (state: IRootState) => state.shared.concise.auditObjects,
    (auditObjects) => ({
      ...auditObjects,
      data: auditObjectsConciseAdapter
        .getSelectors()
        .selectAll(auditObjects.data),
    })
  ),
  getConciseAuditObjectsMap: createSelector(
    (state: IRootState) => state.shared.concise.auditObjects.data,
    (data) => auditObjectsConciseAdapter.getSelectors().selectEntities(data)
  ),
  getConciseAccessibleAuditObjects: createSelector(
    (state: IRootState) => state.shared.concise.accessibleAuditObjects,
    (accessibleAuditObjects) => ({
      ...accessibleAuditObjects,
      data: accessibleAuditObjectsAdapter
        .getSelectors()
        .selectAll(accessibleAuditObjects.data),
    })
  ),
  getConciseAuditObjectGroups: createSelector(
    (state: IRootState) => state.shared.concise.auditObjectGroups,
    (auditObjectGroups) => ({
      ...auditObjectGroups,
      data: auditObjectsGroupConciseAdapter
        .getSelectors()
        .selectAll(auditObjectGroups.data),
    })
  ),
  getConciseAuditObjectGroupsMap: createSelector(
    (state: IRootState) => state.shared.concise.auditObjectGroups.data,
    (data) =>
      auditObjectsGroupConciseAdapter.getSelectors().selectEntities(data)
  ),
  getConciseTags: createSelector(
    (state: IRootState) => state.shared.concise.tags,
    (tags) => ({
      ...tags,
      data: tagsConciseAdapter.getSelectors().selectAll(tags.data),
    })
  ),
  getConciseTagsMap: createSelector(
    (state: IRootState) => state.shared.concise.tags.data,
    (data) => tagsConciseAdapter.getSelectors().selectEntities(data)
  ),
  getConciseAuditObjectAttributes: createSelector(
    (state: IRootState) => state.shared.concise.auditObjectAttributes,
    (attributes) => ({
      ...attributes,
      data: attributesAdapter.getSelectors().selectAll(attributes.data),
    })
  ),
  getConciseAuditObjectAttributesMap: createSelector(
    (state: IRootState) => state.shared.concise.auditObjectAttributes.data,
    (data) => attributesAdapter.getSelectors().selectEntities(data)
  ),
  getConciseActionTemplates: createSelector(
    (state: IRootState) => state.shared.concise.actionTemplates,
    (actionTemplates) => ({
      ...actionTemplates,
      data: actionTemplatesConciseAdapter
        .getSelectors()
        .selectAll(actionTemplates.data),
    })
  ),
  getConciseActionTemplatesMap: createSelector(
    (state: IRootState) => state.shared.concise.actionTemplates.data,
    (data) => actionTemplatesConciseAdapter.getSelectors().selectEntities(data)
  ),
  getConciseJobTitles: createSelector(
    (state: IRootState) => state.shared.concise.jobTitles,
    (jobTitles) => ({
      ...jobTitles,
      data: jobTitlesConciseAdapter.getSelectors().selectAll(jobTitles.data),
    })
  ),
  getConciseJobTitlesMap: createSelector(
    (state: IRootState) => state.shared.concise.jobTitles.data,
    (data) => jobTitlesConciseAdapter.getSelectors().selectEntities(data)
  ),
  getConciseRoles: createSelector(
    (state: IRootState) => state.shared.concise.roles,
    ({ data, loading, error }) => {
      const rolesList = conciseRolesAdapter.getSelectors().selectAll(data);

      let observers = [];
      let otherRoles = [];

      if (!loading) {
        for (let i = 0; i < rolesList.length; i++) {
          // TODO replace with boolean
          if (rolesList[i].name === 'Observer') {
            observers.push(rolesList[i]);
          } else {
            otherRoles.push(rolesList[i]);
          }
        }
      }

      return {
        data: rolesList,
        loading,
        error,
        observers,
        otherRoles,
      };
    }
  ),
  getConciseRolesMap: createSelector(
    (state: IRootState) => state.shared.concise.roles.data,
    (data) => conciseRolesAdapter.getSelectors().selectEntities(data)
  ),
  getSharedFilters: (state: IRootState): ISharedFilters =>
    state.shared.sharedFilters,
  isChecklistTemplateSelected: createSelector(
    [getSharedFilters, getConciseTemplatesMap],
    (filters, templatesMap) =>
      filters.templateIds.length === 1 &&
      templatesMap[filters.templateIds[0]]?.templateType ===
        AuditTemplateType.Checklist
  ),
  getFilesUploads: (state: IRootState): IFileUploads =>
    state.shared.fileUploads,
  assigneesModal: {
    isVisible: (state: IRootState) => state.shared.assigneesModal.show,
    getData: createSelector(
      [
        (state: IRootState) => state.shared.assigneesModal.participants,
        (state: IRootState) => state.shared.assigneesModal.context,
        getConciseUsers,
      ],
      ({ data, loading, error }, context, users) => {
        const participantsIds = new Set();

        const participants = sortConciseArrayByName<IParticipant>(
          filterUsersBasedOnPermissions(
            participantsAdapter.getSelectors().selectAll(data),
            context
          ).map(({ id, ...rest }) => {
            participantsIds.add(id);

            return {
              id,
              ...rest,
            };
          })
        );

        return {
          loading: loading || users.loading,
          error,
          participants,
          participantsMap: participantsAdapter
            .getSelectors()
            .selectEntities(data),
          users: sortConciseArrayByName(
            filterUsersBasedOnPermissions<IUserConcise>(
              users.data,
              context
            ).filter(({ id }: IUserConcise) => !participantsIds.has(id))
          ),
        };
      }
    ),
    getJobTitlesToParticipantsMap: createSelector(
      [
        (state: IRootState) => state.shared.assigneesModal.participants,
        (state: IRootState) => state.shared.assigneesModal.context,
      ],
      ({ data }, context) => {
        return filterUsersBasedOnPermissions<IParticipant>(
          participantsAdapter.getSelectors().selectAll(data),
          context
        ).reduce<Record<string, IParticipant[]>>((acc, participant) => {
          const a = acc;

          for (let i = 0; i < participant.jobTitles.length; i++) {
            const jobTitle = participant.jobTitles[i];

            if (!a[jobTitle.id]) {
              a[jobTitle.id] = [];
            }

            if (
              a[jobTitle.id].find(({ id }) => participant.id === id) ===
              undefined
            ) {
              a[jobTitle.id].push(participant);
            }
          }

          return a;
        }, {});
      }
    ),
  },
  feedbackModal: {
    getPage: (state: IRootState): FeedbackModalPage | null =>
      state.shared.feedbackModal.page,
    getRating: (state: IRootState): IRating | undefined =>
      state.shared.feedbackModal.rating,
  },
  isMobileMenuOpened: (state: IRootState): boolean =>
    state.shared.showMobileMenu,
};
