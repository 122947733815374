import { AuditTemplateDetailsDto } from '@infrastructure/AuditBuilder/models/AuditTemplateDetailsDto';
import { AuditTemplateDetails } from '@domain/AuditTemplates/AuditTemplateDetails';
import { TemplateItem } from '@domain/AuditTemplates/TemplateItem';
import { transformDtoToTemplateItem } from '@infrastructure/AuditBuilder/transformers/transformDtoToTemplateItem';

export function transformDtoToTemplateDetails(
  dto: AuditTemplateDetailsDto
): AuditTemplateDetails {
  return {
    ...dto,
    data: Object.keys(dto.data).reduce<Record<string, TemplateItem>>(
      (acc, itemId) => {
        acc[itemId] = transformDtoToTemplateItem(dto.data, itemId);
        return acc;
      },
      {}
    ),
  };
}
