import React from 'react';

import { Container, MenuHeader, MenuFooter, Controls } from './styled';

import AppLogo from '@components/shared/AppLogo';
import TrialNotifier from '@components/account/AppLayout/TrialNotifier/TrialNotifier';
import MainMenu from '@components/account/AppLayout/MainMenu/MainMenu';
import AccountsSelector from '@components/account/AppLayout/AccountsSelector/AccountsSelector';
import HeaderUser from '@components/account/AppLayout/DesktopLayout/Header/HeaderUser/HeaderUser';
import MobileMenuTrigger from '@components/shared/MobileMenu/MobileMenuTrigger/MobileMenuTrigger';
import MobileMenu from '@components/shared/MobileMenu/MobileMenu';
import HelpCenterFullScreenModal from '@components/account/AppLayout/HelpCenter/HelpCenterFullScreenModal';

interface IProps {}

const Header: React.FC<React.PropsWithChildren<IProps>> = () => {
  return (
    <Container>
      <AppLogo />
      <Controls>
        <HeaderUser />
        <MobileMenuTrigger theme="dark" />
      </Controls>
      <MobileMenu theme="dark">
        {({ closeMenu }) => (
          <>
            <MenuHeader>
              <AccountsSelector onManageAccountsBtnClick={closeMenu} />
            </MenuHeader>
            <MainMenu onSelect={closeMenu} />
            <MenuFooter>
              <TrialNotifier />
            </MenuFooter>
          </>
        )}
      </MobileMenu>
      <HelpCenterFullScreenModal />
    </Container>
  );
};

export default Header;
