import React from 'react';
import { ChevronDown } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Info,
  UserName,
  Company,
  DropDownMenuTrigger,
  AvatarContainer,
} from './styled';
import { accountActions, accountSelectors, generalActions } from '@store';
import { routes, e2eTestElements } from '@config';
import { useAppDispatch } from '@hooks';
import { useLgMediaQuery } from '@repo/shared/hooks';

import DropDownMenu from '../../../../../shared/DropDownMenu/DropDownMenu';
import Avatar from '../../../../../shared/ant/Avatar';

const HeaderUser: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const user = useSelector(accountSelectors.getCurrentUser);
  const company = useSelector(accountSelectors.getCompany);

  const isTablet = useLgMediaQuery();

  if (!user) {
    return null;
  }

  return (
    <DropDownMenu
      menuItems={[
        {
          name: formatMessage({ id: 'MyProfile' }),
          onClick: () => {
            navigate(routes.profile);
            dispatch(generalActions.toggleMobileMenu(false));
          },
        },
        {
          name: formatMessage({ id: 'LogOut' }),
          e2eDataAttr: e2eTestElements.common.header.signOutButton,
          onClick: async () => {
            await dispatch(accountActions.signOut());
            navigate(routes.signin);
          },
        },
      ]}
    >
      <DropDownMenuTrigger data-e2e={e2eTestElements.common.header.userMenu}>
        <AvatarContainer>
          <Avatar user={user} />
        </AvatarContainer>
        {isTablet && (
          <Info>
            <UserName>{user.name}</UserName>
            <Company>{company.name}</Company>
          </Info>
        )}
        <ChevronDown />
      </DropDownMenuTrigger>
    </DropDownMenu>
  );
};

export default HeaderUser;
