import React from 'react';
import { useSelector } from 'react-redux';

import { CopyRight, YearCopy } from './styled';
import { accountSelectors } from '@store';

import ChangeLocale from '@components/shared/ChangeLocale/ChangeLocale';

interface IProps {}

const CopyrightAndLocale: React.FC<React.PropsWithChildren<IProps>> = () => {
  const appConfiguration = useSelector(accountSelectors.getAppConfiguration);

  return (
    <YearCopy>
      <CopyRight>
        &copy; {new Date().getFullYear()} {appConfiguration?.appName}
      </CopyRight>
      <ChangeLocale />
    </YearCopy>
  );
};

export default CopyrightAndLocale;
