import React, {
  useState,
  useEffect,
  useRef,
  TextareaHTMLAttributes,
} from 'react';

import { useIsVisible } from '@repo/shared/hooks';

const AutoTextArea = React.forwardRef(
  (props: TextareaHTMLAttributes<HTMLTextAreaElement>, _) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [text, setText] = useState(props.value);

    const isVisible = useIsVisible(textAreaRef);

    useEffect(() => {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = 'auto';
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      }
    }, [text, isVisible]);

    useEffect(() => {
      if (text !== props.value) {
        setText(props.value);
      }
    }, [props.value]);

    const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setText(event.target.value);

      if (props.onChange) {
        props.onChange(event);
      }
    };

    return (
      <textarea
        {...props}
        ref={textAreaRef}
        rows={1}
        style={{
          // height: textAreaHeight,
          overflow: 'hidden',
          ...(props.style || {}),
        }}
        onChange={onChangeHandler}
      />
    );
  }
);

export default AutoTextArea;
