import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { accountActions, accountSelectors } from '@store';
import { AnalyticsEvent, LiveChatVisibility } from '@repo/shared/enums';
import {
  Adhoc,
  Chat,
  Email,
  Phone,
  QuestionCircle,
  Search,
  Sync,
} from '@icons';
import { colors, config } from '@repo/shared/config';
import { useAppDispatch } from '@hooks';

import HelpCenterButton from '@components/account/AppLayout/HelpCenter/HelpCenterButton/HelpCenterButton';

interface IProps {}

const HelpCenterContent: React.FC<React.PropsWithChildren<IProps>> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const isAdmin = useSelector(accountSelectors.isAdmin);

  useEffect(() => {
    return () => {
      dispatch(accountActions.toggleHelpCenter(false));
    };
  }, []);

  return (
    <>
      <HelpCenterButton
        onClick={() => {
          dispatch(
            accountActions.setLiveChatVisibility(LiveChatVisibility.maximized)
          );
        }}
        icon={<Chat color={colors.gray10} />}
        name={formatMessage({ id: 'LiveChat' })}
        description={formatMessage({ id: 'EngageInLiveChatForSupport' })}
      />
      <HelpCenterButton
        onClick={() => {
          window?.open(config.urls.submitTicket, '_blank');
        }}
        icon={<Search color={colors.gray10} />}
        name={formatMessage({ id: 'SubmitRequest' })}
        description={formatMessage({
          id: 'IssuesQuestionsBugReportsWarrantyClaims',
        })}
        external
      />
      <HelpCenterButton
        onClick={() => {
          window.open(config.urls.support, '_blank');
        }}
        icon={<QuestionCircle color={colors.gray10} />}
        name={formatMessage({ id: 'HelpCenter' })}
        description={formatMessage({ id: 'OurKnowledgeBase' })}
        external
      />
      {isAdmin && (
        <>
          <HelpCenterButton
            onClick={() => {
              window?.open(config.urls.interactiveDemo, '_blank');

              dispatch(
                accountActions.sendAnalyticsEvent(
                  AnalyticsEvent.LiveConsultationClicked
                )
              );
            }}
            icon={<Adhoc color={colors.gray10} size={20} />}
            name={formatMessage({ id: 'BookDemonstration' })}
            external
          />
          <HelpCenterButton
            onClick={() => {
              dispatch(accountActions.toggleFormsDigitizationModal(true));
            }}
            icon={<Sync color={colors.gray10} />}
            name={formatMessage({ id: 'DigitizeYourAuditForms' })}
            description={formatMessage({
              id: 'ConvertYourAuditFormsIntoTemplates',
            })}
          />
        </>
      )}
      <HelpCenterButton
        onClick={() => {
          window.location.href = `mailto:${config.urls.supportEmail}`;
        }}
        icon={<Email color={colors.gray10} />}
        name={config.urls.supportEmail}
      />
      <HelpCenterButton
        onClick={() => {
          window.location.href = `tel::${config.urls.supportPhone}`;
        }}
        icon={<Phone color={colors.gray10} />}
        name={config.urls.supportPhone}
        description={formatMessage({ id: 'Support' })}
      />
    </>
  );
};

export default HelpCenterContent;
