import { BaseApiClient } from '@repo/shared/api';
import { IConcise, IPagedResponse, ITableFilters } from '@repo/shared/types';
import { QuestionSet } from '@domain/QuestionSets/QuestionSet';
import { QuestionSetTemplateMapping } from '@domain/QuestionSets/QuestionSetTemplateMapping';
import { transformQuestionSetDetailsDto } from '@infrastructure/QuestionSets/transformers/transformQuestionSetDetailsDto';
import { QuestionSetDetailsDto } from '@infrastructure/QuestionSets/models/QuestionSetDetailsDto';
import { transformTemplateItemToDto } from '@infrastructure/AuditBuilder/transformers/transformTemplateItemToDto';
import { UpdateTemplateItemDto } from '@infrastructure/AuditBuilder/models/UpdateTemplateItemDto';
import { QuestionSetDetails } from '@domain/QuestionSets/QuestionSetDetails';

class QuestionSetsApiClient extends BaseApiClient {
  public async getQuestionSets(filters: ITableFilters) {
    return this._apiService.get<IPagedResponse<QuestionSet>>({
      url: 'library/question-sets',
      query: {
        ...filters,
      },
    });
  }

  public async getQuestionSetsConcise() {
    return this._apiService.get<IConcise[]>({
      url: 'library/question-sets/concise',
    });
  }

  public async getQuestionSetDetails(questionSetId: string) {
    const questionSetDetails =
      await this._apiService.get<QuestionSetDetailsDto>({
        url: `library/question-sets/${questionSetId}`,
      });

    return transformQuestionSetDetailsDto(questionSetDetails);
  }

  public async createQuestionSet(questionSet: Omit<QuestionSetDetails, 'id'>) {
    const { id } = await this._apiService.post<{ id: string }>({
      url: 'library/question-sets',
      body: {
        ...questionSet,
        data: Object.entries(questionSet.data).reduce<
          Record<string, UpdateTemplateItemDto>
        >((acc, [id, item]) => {
          acc[id] = transformTemplateItemToDto(item);
          return acc;
        }, {}),
      },
    });

    return id;
  }

  public async updateQuestionSet(questionSet: QuestionSetDetails) {
    return this._apiService.put({
      url: `library/question-sets/${questionSet.id}`,
      body: {
        ...questionSet,
        data: Object.entries(questionSet.data).reduce<
          Record<string, UpdateTemplateItemDto>
        >((acc, [id, item]) => {
          acc[id] = transformTemplateItemToDto(item);
          return acc;
        }, {}),
      },
    });
  }

  public async deleteQuestionSets(ids: string[]) {
    return this._apiService.delete({
      url: 'library/question-sets',
      body: {
        ids,
      },
    });
  }

  public async getQuestionSetTemplates(questionSetId: string) {
    return this._apiService.get<QuestionSetTemplateMapping[]>({
      url: `library/question-sets/${questionSetId}/templates`,
    });
  }
}

export default QuestionSetsApiClient;
