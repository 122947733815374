import React, { useState, useImperativeHandle, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Modal from 'antd/es/modal';

import { accountSelectors, generalActions, generalSelectors } from '@store';
import { IAbortRequest, FileMetadata, IFileUpload } from '@repo/shared/types';
import { FileSourceType, MimeType, UploadStatus } from '@repo/shared/enums';
import { useAppDispatch } from '@hooks';

import UploadZone from '@components/shared/UploadFiles/UploadZone/UploadZone';
import UploadList from '@components/shared/UploadFiles/UploadList/UploadList';

export interface IUploadsContainerRef {
  cancel: ({
    onFinishCancel,
  }: {
    onFinishCancel: () => Promise<void>;
  }) => Promise<void>;
}

const imageMimes = [MimeType.jpeg, MimeType.gif, MimeType.png, MimeType.heic];

const nonImageMimes = [
  MimeType.mov,
  MimeType.webm,
  MimeType.mp4,
  MimeType.mpeg,
  MimeType.avi,
  MimeType.avi2,
  MimeType.avi3,
  MimeType.pdf,
];

interface IProps {
  disabled?: boolean;
  onUploadStarted?: () => void;
  onUploadFinished: (files: FileMetadata[]) => void;
  onUploadFailed?: (files: FileMetadata[]) => void;
  onRemoveFile?: (fileId: string) => void;
  e2eDataAttr?: string;
  getUploadPath?: (fileId: string) => string;
  skipCompanyId?: boolean;
  source: FileSourceType;
  active?: boolean;
  uploadLimit?: number;
  allowedMimeTypes?: MimeType[];
  hasError?: boolean;
  showUploadList?: boolean;
  extra?: React.ReactNode;
}

const UploadFiles = React.forwardRef<
  IUploadsContainerRef,
  React.PropsWithChildren<IProps>
>(
  (
    {
      e2eDataAttr,
      disabled,
      onUploadStarted,
      onUploadFinished,
      onUploadFailed,
      onRemoveFile,
      source,
      getUploadPath,
      skipCompanyId,
      active = true,
      allowedMimeTypes,
      uploadLimit,
      hasError,
      showUploadList = true,
      extra,
    },
    ref
  ) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();

    const fileUploads = useSelector(generalSelectors.getFilesUploads);
    const company = useSelector(accountSelectors.getCompany);

    const allowedMimes = allowedMimeTypes
      ? allowedMimeTypes
      : company?.allowVideoUpload
        ? [...imageMimes, ...nonImageMimes]
        : imageMimes;

    const [cancelTokens, setCancelTokens] = useState<
      Record<string, IAbortRequest>
    >({});

    useImperativeHandle(ref, () => ({
      cancel: async ({
        onFinishCancel,
      }: {
        onFinishCancel: () => Promise<void>;
      }) => {
        const activeUploads = Object.values<IFileUpload>(fileUploads).filter(
          (upload) =>
            upload.status === UploadStatus.Pending ||
            upload.status === UploadStatus.Uploading
        );

        if (activeUploads.length > 0) {
          Modal.confirm({
            title: formatMessage({ id: 'Warning' }),
            content: formatMessage({
              id: 'SomeUploadsAreStillInProgressOrPending',
            }),
            okText: formatMessage({ id: 'Confirm' }),
            icon: null,
            styles: {
              body: {
                marginBottom: 0,
              },
            },
            onOk: async () => {
              activeUploads.forEach(({ file: { id } }) => {
                if (cancelTokens[id] !== undefined) {
                  cancelTokens[id]();
                }
              });

              await onFinishCancel();
            },
          });
        } else {
          await onFinishCancel();
        }
      },
    }));

    useEffect(() => {
      if (!active && Object.keys(cancelTokens).length > 0) {
        setCancelTokens({});
      }
    }, [active, cancelTokens]);

    const alreadyUploadedQty = Object.values(fileUploads).filter(
      ({ status }) => status !== UploadStatus.Error
    ).length;
    const alreadyUploadedRef = useRef(alreadyUploadedQty);
    alreadyUploadedRef.current = alreadyUploadedQty;

    return (
      <>
        <UploadZone
          disabled={
            disabled || (!!uploadLimit && alreadyUploadedQty === uploadLimit)
          }
          e2eDataAttr={e2eDataAttr}
          allowedMimeTypes={allowedMimes}
          uploadLimit={uploadLimit}
          hasError={hasError}
          onChange={async (files) => {
            if (onUploadStarted) {
              onUploadStarted();
            }

            const tasks = [];
            const tokens: Record<string, IAbortRequest> = {};

            const filesQtyToUpload = !uploadLimit
              ? files.length
              : uploadLimit - alreadyUploadedRef.current > files.length
                ? files.length
                : uploadLimit - alreadyUploadedRef.current;

            for (let i = 0; i < filesQtyToUpload; i++) {
              const file = files[i];

              const uploadFilePromise = dispatch(
                generalActions.uploadFile({
                  file,
                  getUploadPath,
                  source,
                  allowedMimeTypes: allowedMimes,
                  skipCompanyId,
                })
              );

              tokens[file.id] = uploadFilePromise.abort;

              tasks.push(uploadFilePromise);
            }

            setCancelTokens({
              ...cancelTokens,
              ...tokens,
            });

            const results = await Promise.all(tasks);
            const uploadedFiles: FileMetadata[] = [];
            const notUploadedFiles: FileMetadata[] = [];

            for (let i = 0; i < results.length; i++) {
              if (generalActions.uploadFile.fulfilled.match(results[i])) {
                uploadedFiles.push(files[i]);
              } else {
                notUploadedFiles.push(files[i]);
              }
            }

            if (uploadedFiles.length > 0) {
              onUploadFinished(uploadedFiles);
            }

            if (notUploadedFiles.length > 0 && onUploadFailed) {
              onUploadFailed(notUploadedFiles);
            }
          }}
        />
        {showUploadList && (
          <UploadList
            fileUploads={fileUploads}
            onRemove={(fileId) => {
              if (onRemoveFile) {
                onRemoveFile(fileId);
              }
            }}
            cancelTokens={cancelTokens}
          />
        )}
        {extra}
      </>
    );
  }
);

export default UploadFiles;
