import { TemplateItem } from '@domain/AuditTemplates/TemplateItem';

export function getChildrenIds(
  items: Record<string, TemplateItem>,
  ids: string[]
): string[] {
  let result: string[] = [];

  for (let i = 0; i < ids.length; i++) {
    if (!items[ids[i]]) {
      continue;
    }

    const { childrenIds, id } = items[ids[i]];

    result.push(id);

    if (Array.isArray(childrenIds) && childrenIds.length > 0) {
      result = [...result, ...getChildrenIds(items, childrenIds)];
    }
  }

  return result;
}
