import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { AI } from '@icons';
import { Container, Header, AIIcon, Title, SubTitle } from './styled';
import { onboardingActions } from '@application/Onboarding/store/onboardingActions';
import { useAppDispatch } from '@hooks';

import OnboardingAssistantChatMessages from '@presentation/Onboarding/OnboardingModal/OnboardingAssistant/OnboardingAssistantChat/OnboardingAssistantChatMessages/OnboardingAssistantChatMessages';
import SendOnboardingAssistantChatMessage from '@presentation/Onboarding/OnboardingModal/OnboardingAssistant/OnboardingAssistantChat/OnboardingAssistantChatMessages/SendOnboardingAssistantChatMessage/SendOnboardingAssistantChatMessage';

interface IProps {}

const OnboardingAssistantChat: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function startChat() {
      const resultAction = await dispatch(onboardingActions.startChat());

      if (onboardingActions.startChat.fulfilled.match(resultAction)) {
        dispatch(
          onboardingActions.sendUserMessage(
            formatMessage({ id: 'OnboardingChatStartMessage' })
          )
        );
      }
    }

    startChat();
  }, []);

  return (
    <Container>
      <Header>
        <AIIcon>
          <AI width={64} height={64} />
        </AIIcon>
        <div>
          <Title>{formatMessage({ id: 'Welcome' })}!</Title>
          <SubTitle>
            {formatMessage({ id: 'GetStartedWithYourFirstAudit' })}
          </SubTitle>
        </div>
      </Header>
      <OnboardingAssistantChatMessages />
      <SendOnboardingAssistantChatMessage />
    </Container>
  );
};

export default OnboardingAssistantChat;
