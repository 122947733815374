import {
  MutableRefObject,
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { config, useTransition } from 'react-spring';
import { useMemo } from 'react';
import Form from 'antd/es/form';
import React, { ReactNode, isValidElement } from 'react';

export function useHover<T extends HTMLElement = HTMLElement>(): [
  MutableRefObject<T>?,
  boolean?,
] {
  const [value, setValue] = useState<boolean>(false);
  const ref = useRef<T>(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;

      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);

        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
    },
    // Recall only if ref changes
    [ref.current] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return [ref as MutableRefObject<T>, value];
}

export function usePrevious<T>(value: T) {
  // initialise the ref with previous and current values
  const ref = useRef<{ value: T; prev: T | null }>({
    value: value,
    prev: null,
  });

  const current = ref.current.value;

  // if the value passed into hook doesn't match what we configureStore as "current"
  // move the "current" to the "previous"
  // and configureStore the passed value as "current"
  if (value !== current) {
    ref.current = {
      value: value,
      prev: current,
    };
  }

  // return the previous value only
  return ref.current.prev;
}

export const useLazyEffect: typeof useEffect = (cb, dep) => {
  const initializeRef = useRef<boolean>(false);

  useEffect((...args) => {
    if (initializeRef.current) {
      cb(...args);
    } else {
      initializeRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dep);
};

export const useFormatMessage = () => {
  const { formatMessage } = useIntl();
  return formatMessage;
};

export function useOpacityTransition<T = boolean>(
  item: T,
  immediate: boolean = false
) {
  return useTransition(item, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
    immediate,
  });
}

export function useDocumentDimensions(updater?: any) {
  const [docHeight, setDocHeight] = useState<{ width: number; height: number }>(
    { width: document.body.scrollWidth, height: document.body.scrollHeight }
  );
  const { pathname } = useLocation();

  useEffect(() => {
    setDocHeight({
      width: document.body.scrollWidth,
      height: document.body.scrollHeight,
    });
  }, [updater, pathname]);

  useEffect(() => {
    function handleResize() {
      setDocHeight({
        width: document.body.scrollWidth,
        height: document.body.scrollHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return docHeight;
}

type Event = MouseEvent | TouchEvent;

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback);

  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
};

const extractStrings = (nodes: ReactNode[]): string[] => {
  const strings: string[] = [];

  const extract = (node: ReactNode): void => {
    if (typeof node === 'string' || typeof node === 'number') {
      strings.push(String(node));
    } else if (Array.isArray(node)) {
      node.forEach(extract);
    } else if (isValidElement(node)) {
      React.Children.forEach(node.props.children, extract);
    }
  };

  nodes.forEach(extract);

  return strings;
};

export function useStringFormItemErrors() {
  const { errors, status } = Form.Item.useStatus();

  const errs = extractStrings(errors);
  const prevErrors = usePrevious(errs);

  return useMemo(
    () => (status === 'validating' && prevErrors?.length ? prevErrors : errs),
    [status]
  );
}
