import { createReducer } from '@reduxjs/toolkit';

import { AuditBuilderState } from '@application/AuditBuilder/models/AuditBuilderState';
import { auditBuilderActions as actions } from '@application/AuditBuilder/store/auditBuilderActions';
import { UnexpectedError } from '@repo/shared/errors';
import { convertArrayToHashMap } from '@utils';
import { ZERO_UUID } from '@config';
import { AuditBuilderPage } from '@application/AuditBuilder/enums/AuditBuilderPage';
import { ValidationStatus } from '@application/AuditBuilder/enums/ValidationStatus';
import { transformAnswerTypeDataArrayToMapping } from '@domain/AuditTemplates/AnswerTypeData';
import {
  isRootItem,
  isTemplateSectionItem,
} from '@domain/AuditTemplates/TemplateItem';

const addQuestionSetModalInitialState = {
  targetPlace: null,
  search: '',
  selectedQuestionSetId: null,
  questionSets: {
    loading: true,
    data: [],
    error: null,
  },
  questionSetPreview: {
    loading: true,
    data: null,
    rootItemId: null,
    error: null,
  },
};

const initialState: AuditBuilderState = {
  page: null,
  template: null,
  questionSet: null,
  items: null,
  hasChanges: false,
  rootItemId: null,
  loading: false,
  error: null,
  dragItem: null,
  expandedQuestionSetId: null,
  validationStatus: ValidationStatus.Idle,
  expandedSections: {},
  confirmDeleteItem: {
    entityId: null,
    itemType: null,
  },
  openedSectionId: null,
  sectionVisibilityModalId: null,
  saveChangesModal: {
    show: false,
  },
  showPublishChangesModal: false,
  showConfirmTemplateDeleteModal: false,
  showEditAsDraftModal: false,
  showCreateDraftCopyModal: false,
  isInformationPhotosUploading: false,
  answerValidation: {
    errors: {} as Record<string, Record<string, string>>,
  },
  itemsByActionTemplateModalTemplateId: null,
  scrollElement: null,
  editQuestionModal: {
    itemId: null,
  },
  answerTypes: {
    loading: false,
    error: null,
    data: null,
    dataArr: [],
  },
  addQuestionSetModal: {
    ...addQuestionSetModalInitialState,
  },
};

export const auditBuilderReducer = createReducer<AuditBuilderState>(
  initialState,
  (builder) =>
    builder
      .addCase(actions.loadTemplateBuilder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        actions.loadTemplateBuilder.fulfilled,
        (state, { payload: { template, rootItemId, items } }) => {
          state.loading = false;
          state.template = template;
          state.rootItemId = rootItemId;
          state.items = items;
          state.page = AuditBuilderPage.TemplateSetup;
        }
      )
      .addCase(actions.loadTemplateBuilder.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload || new UnexpectedError().message;
      })
      .addCase(actions.loadQuestionSetBuilder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        actions.loadQuestionSetBuilder.fulfilled,
        (state, { payload: { questionSet, rootItemId, items } }) => {
          state.loading = false;
          state.questionSet = questionSet;
          state.rootItemId = rootItemId;
          state.items = items;
          state.page = AuditBuilderPage.QuestionSetSetup;
        }
      )
      .addCase(
        actions.loadQuestionSetBuilder.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload || new UnexpectedError().message;
        }
      )
      .addCase(
        actions.saveTemplate.fulfilled,
        (state, { payload: { templateId, newItems, rootItemId } }) => {
          state.hasChanges = false;

          if (state.template) {
            if (state.template.id === ZERO_UUID) {
              state.template.id = templateId;
            }
          }

          state.rootItemId = rootItemId;
          state.items = newItems;
        }
      )
      .addCase(
        actions.saveQuestionSet.fulfilled,
        (state, { payload: { rootItemId, questionSetId, newItems } }) => {
          state.hasChanges = false;

          if (state.questionSet) {
            if (state.questionSet.id === ZERO_UUID) {
              state.questionSet.id = questionSetId;
            }
          }

          state.rootItemId = rootItemId;
          state.items = newItems;
        }
      )
      .addCase(actions.publishDraft.fulfilled, (state, { payload }) => {
        state.hasChanges = false;

        if (state.template) {
          state.template.id = payload;
          state.template.isDraft = false;
        }
      })
      .addCase(actions.startDrag, (state, { payload }) => {
        state.dragItem = payload;
      })
      .addCase(actions.stopDrag, (state) => {
        state.dragItem = null;
      })
      .addCase(
        actions.createTemplateQuestion.fulfilled,
        (state, { payload }) => {
          if (!state.items) {
            return;
          }

          state.items[payload.id] = payload;

          state.items[payload.parentId].childrenIds.splice(
            payload.index,
            0,
            payload.id
          );

          for (
            let i = 0;
            i < state.items[payload.parentId].childrenIds.length;
            i++
          ) {
            const id = state.items[payload.parentId].childrenIds[i];
            state.items[id].index = i;
          }
        }
      )
      .addCase(
        actions.createTemplateSection.fulfilled,
        (state, { payload }) => {
          if (!state.items) {
            return;
          }

          for (const item of payload) {
            state.items[item.id] = item;

            if (isTemplateSectionItem(item)) {
              state.items[item.parentId].childrenIds.splice(
                item.index,
                0,
                item.id
              );

              for (
                let i = 0;
                i < state.items[item.parentId].childrenIds.length;
                i++
              ) {
                const id = state.items[item.parentId].childrenIds[i];
                state.items[id].index = i;
              }
            }
          }

          state.hasChanges = true;
        }
      )
      .addCase(actions.deleteTemplateItem.fulfilled, (state, { payload }) => {
        if (!state.items) {
          return;
        }

        for (let i = 0; i < payload.length; i++) {
          const item = state.items[payload[i]];
          const parent = item.parentId && state.items[item.parentId];

          if (parent) {
            parent.childrenIds.splice(item.index, 1);

            for (let j = 0; j < parent.childrenIds.length; j++) {
              const siblingId = parent.childrenIds[j];
              state.items[siblingId].index = j;
            }
          }

          delete state.items[item.id];
        }

        state.hasChanges = true;
      })
      .addCase(
        actions.updateTemplateQuestion.fulfilled,
        (state, { payload }) => {
          if (!state.items) {
            return;
          }

          for (const item of payload) {
            state.items[item.id] = item;
          }

          state.hasChanges = true;
        }
      )
      .addCase(actions.dropTemplateQuestion, (state, { payload }) => {
        if (!state.items || !state.items[payload.id]) {
          return;
        }

        state.items[payload.id] = payload;
      })
      .addCase(actions.dragTemplateItem, (state, { payload }) => {
        const { nextParentId, nextIndex } = payload;

        if (state.dragItem === null || state.items === null) {
          return;
        }

        const { index: prevIndex, parentId: prevParentId } = state.dragItem;

        if (nextParentId === prevParentId && nextIndex === prevIndex) {
          return;
        }

        const [id] = state.items[prevParentId].childrenIds.splice(prevIndex, 1);

        state.items[nextParentId].childrenIds.splice(nextIndex, 0, id);

        for (let i = 0; i < state.items[nextParentId].childrenIds.length; i++) {
          const id = state.items[nextParentId].childrenIds[i];
          state.items[id].index = i;
        }

        if (prevParentId !== nextParentId) {
          state.items[id].parentId = nextParentId;

          for (
            let i = 0;
            i < state.items[prevParentId].childrenIds.length;
            i++
          ) {
            const id = state.items[prevParentId].childrenIds[i];
            state.items[id].index = i;
          }
        }

        state.dragItem.parentId = nextParentId;
        state.dragItem.index = nextIndex;

        state.hasChanges = true;
      })
      .addCase(actions.moveTemplateSection, (state, { payload }) => {
        if (state.items === null) {
          return;
        }

        const rootItemId = state.rootItemId;
        const { nextIndex, sectionId } = payload;
        const { parentId } = state.items[sectionId];

        if (parentId === null) {
          return;
        }

        const prevIndex = state.items[parentId].childrenIds.indexOf(sectionId);
        const targetId = parentId || rootItemId;

        if (targetId) {
          state.items[targetId].childrenIds.splice(
            nextIndex,
            0,
            ...state.items[targetId].childrenIds.splice(prevIndex, 1)
          );

          for (let i = 0; i < state.items[targetId].childrenIds.length; i++) {
            const id = state.items[targetId].childrenIds[i];
            state.items[id].index = i;
          }

          state.items[payload.sectionId].index = payload.nextIndex;

          state.hasChanges = true;
        }
      })
      .addCase(actions.setPage, (state, { payload }) => {
        state.page = payload;
      })
      .addCase(actions.updateTemplate, (state, { payload }) => {
        if (state.template) {
          state.template = {
            ...state.template,
            ...payload,
          };
        }
      })
      .addCase(actions.showDeleteEntityConfirmModal, (state, { payload }) => {
        state.confirmDeleteItem = payload;
      })
      .addCase(actions.toggleSections, (state, { payload: { ids, show } }) => {
        if (show) {
          state.expandedSections = {
            ...state.expandedSections,
            ...convertArrayToHashMap(ids),
          };
        } else {
          for (let i = 0; i < ids.length; i++) {
            delete state.expandedSections[ids[i]];
          }
        }
      })
      .addCase(
        actions.setSectionId.pending,
        (state, { meta: { arg: sectionId } }) => {
          state.openedSectionId = sectionId;
        }
      )
      .addCase(actions.toggleSectionSettingsModal, (state, { payload }) => {
        state.sectionVisibilityModalId = payload;
      })
      .addCase(actions.toggleSaveChangesModal, (state, { payload }) => {
        state.saveChangesModal = payload;
      })
      .addCase(actions.togglePublishChangesModal, (state, { payload }) => {
        state.showPublishChangesModal = payload;
      })
      .addCase(actions.setInformationPhotosUploading, (state, { payload }) => {
        state.isInformationPhotosUploading = payload;
      })
      .addCase(actions.setValidationStatus, (state, { payload }) => {
        state.validationStatus = payload;
      })
      .addCase(
        actions.toggleItemsByActionTemplateModal,
        (state, { payload }) => {
          state.itemsByActionTemplateModalTemplateId = payload;
        }
      )
      .addCase(actions.goToItem.fulfilled, (state, { payload }) => {
        state.scrollElement = `.template-item-id-${payload}` || null;
      })
      .addCase(actions.scrollToBottom, (state, { payload }) => {
        state.scrollElement = 'scroll-to-bottom';
      })
      .addCase(actions.resetScrollElement, (state) => {
        state.scrollElement = null;
      })
      .addCase(actions.resetData, () => {
        return {
          ...initialState,
        };
      })
      .addCase(actions.toggleCreateDraftCopyModal, (state, { payload }) => {
        state.showCreateDraftCopyModal = payload;
      })
      .addCase(actions.updateQuestionSet, (state, { payload }) => {
        if (state.questionSet) {
          state.questionSet = {
            ...state.questionSet,
            ...payload,
          };
        }
      })
      .addCase(actions.toggleEditQuestionModal, (state, { payload }) => {
        state.editQuestionModal.itemId = payload?.itemId || null;
      })
      .addCase(actions.getAnswerTypes.pending, (state) => {
        state.answerTypes.loading = true;
        state.answerTypes.error = null;
      })
      .addCase(actions.getAnswerTypes.fulfilled, (state, { payload }) => {
        state.answerTypes.loading = false;
        state.answerTypes.data = transformAnswerTypeDataArrayToMapping(payload);
        state.answerTypes.dataArr = payload;
      })
      .addCase(actions.getAnswerTypes.rejected, (state, { payload }) => {
        state.answerTypes.loading = false;
        state.answerTypes.error = payload || null;
      })
      .addCase(
        actions.updateTemplateSection.fulfilled,
        (state, { payload }) => {
          if (state.items) {
            state.items[payload.id] = payload;
          }
        }
      )
      .addCase(actions.toggleAddQuestionSetModal, (state, { payload }) => {
        state.addQuestionSetModal = {
          ...addQuestionSetModalInitialState,
          targetPlace: payload,
        };
      })
      .addCase(actions.getQuestionSets.pending, (state) => {
        state.addQuestionSetModal.questionSets.loading = true;
      })
      .addCase(actions.getQuestionSets.fulfilled, (state, { payload }) => {
        state.addQuestionSetModal.questionSets.loading = false;
        state.addQuestionSetModal.questionSets.data = payload;

        if (payload.length === 0) {
          state.addQuestionSetModal.questionSetPreview.loading = false;
        }
      })
      .addCase(actions.getQuestionSets.rejected, (state, { payload }) => {
        state.addQuestionSetModal.questionSets.loading = false;
        state.addQuestionSetModal.questionSets.error = payload || null;
        state.addQuestionSetModal.questionSetPreview.loading = false;
      })
      .addCase(actions.setQuestionSetsSearchTerm, (state, { payload }) => {
        state.addQuestionSetModal.search = payload;
      })
      .addCase(actions.getQuestionSetPreview.pending, (state) => {
        state.addQuestionSetModal.questionSetPreview.loading = true;
      })
      .addCase(
        actions.getQuestionSetPreview.fulfilled,
        (state, { payload }) => {
          state.addQuestionSetModal.questionSetPreview.loading = false;
          state.addQuestionSetModal.questionSetPreview.data = payload;
        }
      )
      .addCase(actions.getQuestionSetPreview.rejected, (state, { payload }) => {
        state.addQuestionSetModal.questionSetPreview.loading = false;
        state.addQuestionSetModal.questionSetPreview.error = payload || null;
      })
      .addCase(actions.setQuestionSetId, (state, { payload }) => {
        state.addQuestionSetModal.selectedQuestionSetId = payload;
      })
      .addCase(
        actions.createQuestionSetItemFromPreview.fulfilled,
        (state, { payload: { questionSetItem, questions } }) => {
          if (!state.items) {
            return;
          }

          state.items = {
            ...state.items,
            ...questions,
            [questionSetItem.id]: questionSetItem,
          };

          if (isTemplateSectionItem(state.items[questionSetItem.parentId])) {
            state.items[questionSetItem.parentId].childrenIds.splice(
              questionSetItem.index,
              0,
              questionSetItem.id
            );

            for (
              let i = 0;
              i < state.items[questionSetItem.parentId].childrenIds.length;
              i++
            ) {
              const id = state.items[questionSetItem.parentId].childrenIds[i];
              state.items[id].index = i;
            }
          }
        }
      )
      .addCase(actions.setRootItemNotApplicable, (state, { payload }) => {
        if (state.items && state.rootItemId && state.items[state.rootItemId]) {
          const rootItem = state.items[state.rootItemId];

          if (isRootItem(rootItem)) {
            rootItem.disableNotApplicable = payload;
          }
        }
      })
      .addCase(actions.expandQuestionSet, (state, { payload }) => {
        state.expandedQuestionSetId = payload;
      })
);
