import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';

import { AuditTemplateDetails } from '@domain/AuditTemplates/AuditTemplateDetails';
import { Logger } from '@repo/shared/services';
import TemplateBuilderApiClient from '@infrastructure/AuditBuilder/api/TemplateBuilderApiClient';
import {
  accountActions,
  generalActions,
  scoresActions,
  scoresSelectors,
} from '@store';
import { getAncestorsSections } from '@application/AuditBuilder/utils/getAncestorsSections';
import { delay, getErrorMessage } from '@repo/shared/utils';
import { createAuditTemplateDetails } from '@application/AuditBuilder/utils/createAuditTemplateDetails';
import { hasTemplatesCreatedByUser } from '@application/AuditBuilder/utils/hasTemplatesCreatedByUser';
import { findRootItemId } from '@application/AuditBuilder/utils/findRootItemId';
import { IRootState } from '@src/core/frameworks/redux';
import {
  ActionType,
  AnalyticsEvent,
  AnswerType,
  AuditTemplateType,
  ItemType,
  TriggerType,
} from '@repo/shared/enums';
import { UnexpectedError } from '@repo/shared/errors';
import {
  AddQuestionSetModalTargetPlace,
  EditQuestionModalState,
  SaveChangesModalState,
} from '@application/AuditBuilder/models/AuditBuilderState';
import { IDragItem } from '@application/AuditBuilder/models/DragItem';
import { auditBuilderSelectors } from '@application/AuditBuilder/store/auditBuilderSelectors';
import { intl } from '@repo/shared/components/IntlGlobalProvider';
import {
  createConditionItem,
  createQuestionItem,
  createQuestionSetItem,
  createSectionItem,
} from '@application/AuditBuilder/utils/createTemplateItem';
import { apiUrls, ZERO_UUID } from '@config';
import { createTemplateFromSuggestion } from '@application/AuditBuilder/utils/createTemplateFromSuggestion';
import { auditTemplatesSelectors } from '@application/AuditTemplates/store/auditTemplatesSelectors';
import QuestionSetsApiClient from '@infrastructure/QuestionSets/api/QuestionSetsApiClient';
import { QuestionSetDetails } from '@domain/QuestionSets/QuestionSetDetails';
import { createQuestionSet } from '@application/AuditBuilder/utils/createQuestionSet';
import { AuditBuilderPage } from '@application/AuditBuilder/enums/AuditBuilderPage';
import { ValidationStatus } from '@application/AuditBuilder/enums/ValidationStatus';
import {
  isRootItem,
  isTemplateConditionalQuestionItem,
  isTemplateConditionItem,
  isTemplateQuestionItem,
  isTemplateSectionItem,
  TemplateConditionItem,
  TemplateItem,
  TemplateQuestionItem,
  TemplateQuestionSetItem,
  TemplateSectionItem,
} from '@domain/AuditTemplates/TemplateItem';
import { AnswerTypeData } from '@domain/AuditTemplates/AnswerTypeData';
import { InternalApiService } from '@repo/shared/api';
import { getChildrenIds } from '@application/AuditBuilder/utils/getChildrenIds';
import { ConditionValue, IConcise } from '@repo/shared/types';

const templateBuilderApiClient = new TemplateBuilderApiClient();
const questionSetsApiClient = new QuestionSetsApiClient();
const apiService = InternalApiService.getInstance();

const getAnswerTypes = createAsyncThunk<
  AnswerTypeData[],
  void,
  { state: IRootState; rejectValue: string }
>('audits/getAnswerTypes', async (_, { rejectWithValue }) => {
  try {
    return apiService.get({
      url: `${apiUrls.auditTemplates}/answer-types`,
    });
  } catch (e) {
    Logger.captureException(e);

    return rejectWithValue(getErrorMessage(e));
  }
});

const loadTemplateBuilder = createAsyncThunk<
  {
    template: Omit<AuditTemplateDetails, 'data'>;
    items: Record<string, TemplateItem>;
    rootItemId: string;
  },
  {
    templateId: string | undefined;
    templateType: AuditTemplateType;
    isDraft: boolean;
    useSuggestion: boolean;
  },
  { rejectValue: string; state: IRootState }
>(
  'auditBuilder/loadTemplateBuilder',
  async (
    { templateId, templateType, isDraft, useSuggestion },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      await Promise.all([
        dispatch(getAnswerTypes()),
        dispatch(generalActions.getConciseAuditObjectAttributes()),
        dispatch(generalActions.getConciseActionTemplates()),
        dispatch(scoresActions.getScores()),
        dispatch(scoresActions.getScoresConcise()),
      ]);

      const state = getState();

      const scoresDictionary = scoresSelectors.getScoresLookup(state);
      const defaultScore = scoresDictionary[Object.keys(scoresDictionary)[0]];

      let template: Omit<AuditTemplateDetails, 'data'>;
      let items: Record<string, TemplateItem>;

      if (useSuggestion) {
        const suggestion = auditTemplatesSelectors.getSuggestion(state);

        if (!suggestion.data) {
          throw new UnexpectedError(
            'loadBuilder is called with useSuggestion but suggestion is not set'
          );
        }

        const { data, ...tmpl } = createTemplateFromSuggestion(
          {
            templateType,
            suggestion: suggestion.data,
            scoreSystem: defaultScore,
          },
          state
        );
        template = tmpl;
        items = data;
      } else if (templateId) {
        const { data, ...tmpl } =
          await templateBuilderApiClient.getTemplateDetails(
            templateId,
            isDraft
          );
        template = tmpl;
        items = data;
      } else {
        const { data, ...tmpl } = createAuditTemplateDetails(
          {
            templateType,
            scoreSystem: defaultScore,
          },
          state
        );
        template = tmpl;
        items = data;
      }

      const rootItemId = findRootItemId(items);

      if (!rootItemId) {
        throw new UnexpectedError('Root item is not found');
      }

      if (!(await hasTemplatesCreatedByUser(dispatch, getState))) {
        dispatch(accountActions.sendAnalyticsEvent(AnalyticsEvent.AddTemplate));
      }

      return {
        template: {
          ...template,
          isDraft,
        },
        items,
        rootItemId,
      };
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const loadQuestionSetBuilder = createAsyncThunk<
  {
    questionSet: Omit<QuestionSetDetails, 'data'>;
    items: Record<string, TemplateItem>;
    rootItemId: string;
  },
  string | undefined,
  { rejectValue: string; state: IRootState }
>(
  'auditBuilder/loadQuestionSetBuilder',
  async (questionSetId, { rejectWithValue, dispatch, getState }) => {
    try {
      await Promise.all([
        dispatch(getAnswerTypes()),
        dispatch(generalActions.getConciseAuditObjectAttributes()),
        dispatch(generalActions.getConciseActionTemplates()),
        dispatch(scoresActions.getScores()),
        dispatch(scoresActions.getScoresConcise()),
      ]);

      const questionSetDetails = questionSetId
        ? await questionSetsApiClient.getQuestionSetDetails(questionSetId)
        : createQuestionSet(getState());

      if (!questionSetDetails) {
        throw new UnexpectedError(
          'loadQuestionSetBuilder: question set is not found'
        );
      }

      const { data, ...questionSet } = questionSetDetails;

      const rootItemId = findRootItemId(data);

      if (!rootItemId) {
        throw new UnexpectedError(
          'loadQuestionSetBuilder: rootItemId is not found'
        );
      }

      return { rootItemId, questionSet, items: data };
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const startDrag = createAction<IDragItem>('auditBuilder/startDrag');

const stopDrag = createAction('auditBuilder/endDrag');

const dragTemplateItem = createAction<{
  nextParentId: string;
  nextIndex: number;
}>('auditBuilder/dragTemplateItem');

const setInformationPhotosUploading = createAction<boolean>(
  'auditBuilder/setInformationPhotosUploading'
);

const saveTemplate = createAsyncThunk<
  {
    rootItemId: string;
    templateId: string;
    newItems: Record<string, TemplateItem>;
  },
  void,
  { rejectValue: string | null; state: IRootState }
>(
  'auditBuilder/saveTemplate',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setPage(AuditBuilderPage.TemplateSetup));

      await delay(100);

      dispatch(setValidationStatus(ValidationStatus.Validate));

      await delay(100);

      if (
        auditBuilderSelectors.getValidationStatus(getState()) !==
        ValidationStatus.Passed
      ) {
        return rejectWithValue('form-validation-failed');
      }

      const template = auditBuilderSelectors.getTemplateDetails(getState());

      if (!template) {
        throw new UnexpectedError(
          'saveChanges: template is not set in the builder'
        );
      }

      let templateId = template.id;

      if (template.id === ZERO_UUID) {
        templateId = await templateBuilderApiClient.createDraft(template);
      } else {
        await templateBuilderApiClient.saveTemplate(template);
      }

      const { data } = await templateBuilderApiClient.getTemplateDetails(
        templateId,
        template.isDraft
      );

      const rootItemId = findRootItemId(data);

      if (!rootItemId) {
        throw new UnexpectedError('saveTemplate: rootItemId is not found');
      }

      return {
        rootItemId,
        templateId,
        newItems: data,
      };
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const publishDraft = createAsyncThunk<
  string,
  void,
  { rejectValue: string; state: IRootState }
>('auditBuilder/publishChanges', async (_, { rejectWithValue, getState }) => {
  try {
    const template = auditBuilderSelectors.getTemplateDetails(getState());

    if (!template) {
      throw new UnexpectedError('publishDraft: template is not found');
    }

    return await templateBuilderApiClient.publishDraft(template.id);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const createDraft = createAsyncThunk<
  string,
  void,
  { rejectValue: string; state: IRootState }
>('auditBuilder/createDraft', async (_, { rejectWithValue, getState }) => {
  try {
    const template = auditBuilderSelectors.getTemplateDetails(getState());

    if (!template) {
      throw new UnexpectedError(
        'createDraft: template is not set in the builder'
      );
    }

    const items = auditBuilderSelectors.getItemsDictionary(getState());

    if (!items) {
      throw new UnexpectedError(
        'saveChanges: items are not set in the builder'
      );
    }

    return await templateBuilderApiClient.createDraft({
      ...template,
      data: items,
    });
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const saveAsDraft = createAsyncThunk<
  string,
  void,
  { rejectValue: string; state: IRootState }
>('auditBuilder/saveAsDraft', async (_, { rejectWithValue, getState }) => {
  try {
    const template = auditBuilderSelectors.getTemplateDetails(getState());

    if (!template) {
      throw new UnexpectedError(
        'createDraft: template is not set in the builder'
      );
    }

    return await templateBuilderApiClient.saveAsDraft(template.id);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const createTemplateItem = createAction<TemplateItem>(
  'auditBuilder/addTemplateItem'
);

const createTemplateQuestion = createAsyncThunk<
  TemplateQuestionItem,
  {
    index: number;
    text?: string;
    parentId: string;
    answerType: AnswerType;
  }
>(
  'auditBuilder/createTemplateQuestion',
  async ({ index, parentId, text, answerType }, { getState }) => {
    return createQuestionItem(
      { index, parentId, text, answerType },
      getState()
    );
  }
);

const updateTemplate = createAction<Partial<AuditTemplateDetails>>(
  'auditBuilder/updateTemplate'
);

const updateQuestionSet = createAction<Partial<QuestionSetDetails>>(
  'auditBuilder/updateQuestionSet'
);

const createTemplateSection = createAsyncThunk<
  (TemplateSectionItem | TemplateQuestionItem)[],
  {
    parentId: string;
    index: number;
  },
  { state: IRootState; rejectValue: string }
>(
  'auditBuilder/createTemplateSection',
  async ({ parentId, index }, { getState, rejectWithValue }) => {
    try {
      const state = getState();

      const itemsDictionary = auditBuilderSelectors.getItemsDictionary(state);
      const rootItemId = auditBuilderSelectors.getRootItemId(state);

      if (!rootItemId) {
        throw new UnexpectedError(
          'createTemplateSection: rootItemId is not set in the builder'
        );
      }

      if (!itemsDictionary) {
        throw new UnexpectedError(
          'createTemplateSection: items are not set in the builder'
        );
      }

      const parent = itemsDictionary[parentId];

      // if parent is sub-section take grandparent (section) instead
      // to prevent sub-sections creation inside other sub-sections:
      if (
        parent?.itemType === ItemType.Section &&
        parent.parentId !== rootItemId
      ) {
        parentId = parent.id;
        // find index of parent inside grandparent's children ids
        index = itemsDictionary[parentId].childrenIds.indexOf(parent.id) + 1;
      }

      const section = createSectionItem({
        parentId,
        text: intl.formatMessage({
          id: parent.id === rootItemId ? 'Section' : 'SubSection',
        }),
        index,
      });

      const items: (TemplateSectionItem | TemplateQuestionItem)[] = [section];

      const template = auditBuilderSelectors.getTemplateDetails(state);
      const templateType = template?.templateType;

      for (let i = 0; i < 2; i++) {
        const answerType =
          templateType === AuditTemplateType.Checklist
            ? AnswerType.Checklist
            : AnswerType.PassFailButtons;

        const question = createQuestionItem(
          {
            parentId: section.id,
            answerType,
            index: i,
          },
          state
        );

        section.childrenIds.push(question.id);

        items.push(question);
      }

      return items;
    } catch (e) {
      Logger.captureException(e);

      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const setPage = createAction<AuditBuilderPage>('auditBuilder/setPage');

const setSectionId = createAsyncThunk<void, string | null>(
  'auditBuilder/setSectionId',
  async (sectionId, { dispatch }) => {
    dispatch(
      setPage(sectionId ? AuditBuilderPage.EditSection : AuditBuilderPage.Build)
    );
  }
);

const deleteTemplateItem = createAsyncThunk<string[], string>(
  'auditBuilder/deleteTemplateItem',
  async (itemId, { getState, rejectWithValue }) => {
    try {
      const state = getState();

      const itemsDictionary = auditBuilderSelectors.getItemsDictionary(state);

      if (!itemsDictionary) {
        throw new UnexpectedError(
          'deleteTemplateItem: items are not set in the builder'
        );
      }

      const itemToDelete = itemsDictionary[itemId];

      if (!itemToDelete) {
        throw new UnexpectedError(
          `deleteTemplateItem: there is no item with id: ${itemId} to delete`
        );
      }

      if (itemToDelete.itemType === ItemType.Root) {
        throw new UnexpectedError(
          `deleteTemplateItem: root deletion is not allowed`
        );
      }

      return [
        itemId,
        ...getChildrenIds(itemsDictionary, itemToDelete.childrenIds),
      ];
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const updateTemplateSection = createAsyncThunk<
  TemplateSectionItem,
  {
    sectionId: string;
    update: Partial<TemplateSectionItem>;
  }
>(
  'auditBuilder/updateTemplateSection',
  async ({ sectionId, update }, { rejectWithValue, getState }) => {
    try {
      const items = auditBuilderSelectors.getItemsDictionary(getState());
      const section = items?.[sectionId];

      if (!section || !isTemplateSectionItem(section)) {
        throw new UnexpectedError(
          `updateTemplateSection: section with id ${sectionId} was not found`
        );
      }

      return {
        ...section,
        ...update,
      };
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const updateTemplateQuestion = createAsyncThunk<
  (TemplateQuestionItem | TemplateConditionItem)[],
  TemplateQuestionItem
>(
  'auditBuilder/updateTemplateQuestion',
  async (update, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();

      const itemsDictionary = auditBuilderSelectors.getItemsDictionary(state);

      if (!itemsDictionary) {
        throw new UnexpectedError(
          'updateTemplateQuestion: items are not set in the builder'
        );
      }

      const prevVersionOfQuestion = itemsDictionary[update.id];

      if (
        !prevVersionOfQuestion ||
        !isTemplateQuestionItem(prevVersionOfQuestion)
      ) {
        throw new UnexpectedError(
          `updateTemplateQuestion: question with id ${update.id} was not found`
        );
      }

      const updatedQuestion = {
        ...update,
      };
      const items: (TemplateQuestionItem | TemplateConditionItem)[] = [
        updatedQuestion,
      ];

      // remove all potential children of question if update is basic item or
      // there is no add items condition in the update
      if (
        update.itemType === ItemType.Item ||
        !update.conditions.find(
          ({ actionType }) => actionType === ActionType.AddItems
        )
      ) {
        for (const childId of prevVersionOfQuestion.childrenIds) {
          dispatch(deleteTemplateItem(childId));
        }

        updatedQuestion.childrenIds = [];
      }

      // create conditional children if update is conditional item and has
      // add items condition in it
      if (update.itemType === ItemType.ConditionalItem) {
        const addItemsCondition = updatedQuestion.conditions.find(
          ({ actionType }) => actionType === ActionType.AddItems
        );

        if (addItemsCondition) {
          let passedAnswerValue = 'Passed';
          let failedAnswerValue = 'Failed';

          if (update.answerType === AnswerType.YesNoButtons) {
            update.data.conditions.forEach(({ markAsFailed, name }) => {
              if (markAsFailed) {
                failedAnswerValue = name;
              } else {
                passedAnswerValue = name;
              }
            });
          }

          const triggerTypeToConditionValueDict = {
            [TriggerType.Passed]: passedAnswerValue,
            [TriggerType.IsAnswered]: passedAnswerValue,
            [TriggerType.Failed]: failedAnswerValue,
          };

          const newConditionValue = triggerTypeToConditionValueDict[
            addItemsCondition.triggerType
          ] as ConditionValue;

          if (prevVersionOfQuestion.childrenIds.length === 0) {
            const condition = createConditionItem({
              parentId: update.id,
            });

            condition.condition.value = newConditionValue;

            updatedQuestion.childrenIds = [condition.id];

            items.push(condition);

            const question = createQuestionItem(
              {
                itemType: ItemType.Item,
                index: 0,
                parentId: condition.id,
                answerType: AnswerType.PassFailButtons,
              },
              state
            );

            condition.childrenIds = [question.id];

            items.push(question);
          } else if (itemsDictionary[prevVersionOfQuestion.childrenIds[0]]) {
            const condition = cloneDeep(
              itemsDictionary[prevVersionOfQuestion.childrenIds[0]]
            );

            if (
              isTemplateConditionItem(condition) &&
              condition.condition.value !== newConditionValue
            ) {
              condition.condition.value = newConditionValue;
              items.push(condition);
            }
          }

          updatedQuestion.conditions = updatedQuestion.conditions.filter(
            ({ actionType }) => actionType !== ActionType.AddItems
          );
        }
      }

      return items;
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const dropTemplateQuestion = createAction<TemplateQuestionItem>(
  'auditBuilder/dropTemplateQuestion'
);

const toggleSection = createAction<{ id: string; show: boolean }>(
  'auditBuilder/toggleSection'
);

const moveTemplateSection = createAction<{
  sectionId: string;
  nextIndex: number;
}>('auditBuilder/moveTemplateSection');

const showDeleteEntityConfirmModal = createAction<{
  entityId: string | null;
  itemType: ItemType | null;
}>('auditBuilder/showDeleteEntityConfirmModal');

const toggleSections = createAction<{ ids: string[]; show: boolean }>(
  'auditBuilder/toggleSections'
);

const toggleSectionSettingsModal = createAction<string | null>(
  'auditBuilder/toggleSectionSettingsModal'
);

const toggleSaveChangesModal = createAction<SaveChangesModalState>(
  'auditBuilder/toggleSaveChangesModal'
);

const togglePublishChangesModal = createAction<boolean>(
  'auditBuilder/togglePublishChangesModal'
);

const toggleCreateDraftCopyModal = createAction<boolean>(
  'auditBuilder/toggleCreateDraftCopyModal'
);

const setValidationStatus = createAction<ValidationStatus>(
  'auditBuilder/setValidationStatus'
);

const toggleItemsByActionTemplateModal = createAction<string | null>(
  'auditBuilder/toggleItemsByActionTemplateModal'
);

const goToItem = createAsyncThunk<string | void, string, { state: any }>(
  'auditBuilder/goToItem',
  async (itemId, { dispatch, getState }) => {
    const state = getState();

    const itemsMap = auditBuilderSelectors.getItemsDictionary(state);
    const rootItemId = auditBuilderSelectors.getRootItemId(state);
    const isPublished = auditBuilderSelectors.isPublished(state);
    const item = itemsMap?.[itemId];

    if (
      !item ||
      !rootItemId ||
      item.itemType === ItemType.Root ||
      item.itemType === ItemType.Condition
    ) {
      return;
    }

    dispatch(setPage(AuditBuilderPage.Build));

    if (item.itemType === ItemType.Section) {
      dispatch(setSectionId(item.id));
      return;
    }

    const { section, subSection, conditionalItem } = getAncestorsSections(
      itemsMap,
      itemId,
      rootItemId
    );

    if (section) {
      dispatch(setSectionId(subSection ? subSection.id : section.id));
    } else {
      const questionSet = auditBuilderSelectors.getQuestionSet(state);

      if (!questionSet) {
        return;
      }

      dispatch(setPage(AuditBuilderPage.EditSection));
    }

    dispatch(
      toggleEditQuestionModal({
        itemId: conditionalItem ? conditionalItem.id : item.id,
      })
    );

    return itemId;
  }
);

const scrollToBottom = createAction('auditBuilder/scrollToBottom');

const resetScrollElement = createAction('auditBuilder/resetScrollElement');

const resetData = createAction('auditBuilder/resetData');

const saveQuestionSet = createAsyncThunk<
  {
    rootItemId: string;
    questionSetId: string;
    newItems: Record<string, TemplateItem>;
  },
  void,
  { rejectValue: string | null; state: IRootState }
>(
  'auditBuilder/saveQuestionSet',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setPage(AuditBuilderPage.QuestionSetSetup));

      await delay(100);

      dispatch(setValidationStatus(ValidationStatus.Validate));

      await delay(100);

      if (
        auditBuilderSelectors.getValidationStatus(getState()) !==
        ValidationStatus.Passed
      ) {
        return rejectWithValue('form-validation-failed');
      }

      const questionSet = auditBuilderSelectors.getQuestionSet(getState());

      if (!questionSet) {
        throw new UnexpectedError(
          'saveQuestionSet: questionSet is not set in the builder'
        );
      }

      let questionSetId: string = questionSet.id;

      if (questionSet.id === ZERO_UUID) {
        const { id: _, ...update } = questionSet;
        questionSetId = await questionSetsApiClient.createQuestionSet(update);
      } else {
        await questionSetsApiClient.updateQuestionSet(questionSet);
      }

      const { data } =
        await questionSetsApiClient.getQuestionSetDetails(questionSetId);

      const rootItemId = findRootItemId(data);

      if (!rootItemId) {
        throw new UnexpectedError('saveQuestionSet: rootItemId is not found');
      }

      return {
        rootItemId,
        questionSetId,
        newItems: data,
      };
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const toggleEditQuestionModal = createAction<EditQuestionModalState | null>(
  'auditBuilder/toggleEditQuestionModal'
);

const toggleAddQuestionSetModal =
  createAction<AddQuestionSetModalTargetPlace | null>(
    'auditBuilder/toggleAddQuestionSetModal'
  );

const getQuestionSets = createAsyncThunk<
  IConcise[],
  void,
  { rejectValue: string }
>('auditBuilder/getQuestionSets', async (_, { rejectWithValue, dispatch }) => {
  try {
    const data = await questionSetsApiClient.getQuestionSetsConcise();

    if (data.length > 0) {
      dispatch(setQuestionSetId(data[0].id));
    }

    return data;
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const getQuestionSetPreview = createAsyncThunk<
  {
    rootItemId: string;
    questionSetDetails: QuestionSetDetails;
  },
  string,
  { rejectValue: string }
>(
  'auditBuilder/getQuestionSetPreview',
  async (questionSetId, { rejectWithValue }) => {
    try {
      const questionSetDetails =
        await questionSetsApiClient.getQuestionSetDetails(questionSetId);

      const rootItemId = findRootItemId(questionSetDetails.data);

      if (!rootItemId) {
        throw new UnexpectedError(`Root item is missing`);
      }

      return {
        questionSetDetails,
        rootItemId,
      };
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const setQuestionSetId = createAction<string>('auditBuilder/setQuestionSetId');

const setQuestionSetsSearchTerm = createAction<string>(
  'auditBuilder/setQuestionSetsSearchTerm'
);

const createQuestionSetItemFromPreview = createAsyncThunk<
  {
    questionSetItem: TemplateQuestionSetItem;
    questions: Record<string, TemplateQuestionItem | TemplateConditionItem>;
  },
  {
    parentId: string;
    index: number;
  }
>(
  'auditBuilder/createQuestionSetItemFromPreview',
  async ({ parentId, index }, { getState, rejectWithValue }) => {
    try {
      const { data } = auditBuilderSelectors.getQuestionSetPreview(getState());

      if (!data) {
        throw new UnexpectedError('Question set details are missing');
      }

      const { rootItemId, questionSetDetails } = data;

      const rootItem = questionSetDetails.data[rootItemId];

      if (!isRootItem(rootItem)) {
        throw new UnexpectedError('Root item is missing');
      }

      const questionSetItem = createQuestionSetItem({
        index,
        parentId,
        text: questionSetDetails.name,
        questionSetId: questionSetDetails.id,
      });

      const questions = rootItem.childrenIds.reduce<
        Record<string, TemplateQuestionItem | TemplateConditionItem>
      >((acc, questionSetItemId) => {
        const a = acc;

        const item = questionSetDetails.data[questionSetItemId];

        if (isTemplateQuestionItem(item)) {
          const newId = uuid();

          const newItem = cloneDeep({
            ...item,
            id: newId,
            questionSetItemId: item.id,
            parentId: questionSetItem.id,
          });

          if (newItem.answerType === AnswerType.Text) {
            newItem.data.questionSetItemAnswerId = newItem.data.id;
            newItem.data.id = uuid();
          } else {
            for (let i = 0; i < newItem.data.conditions.length; i++) {
              newItem.data.conditions[i].questionSetItemAnswerId =
                newItem.data.conditions[i].id;
              newItem.data.conditions[i].id = uuid();
            }
          }

          a[newId] = newItem;

          if (isTemplateConditionalQuestionItem(item)) {
            const condition = questionSetDetails.data[item.childrenIds[0]];

            if (!isTemplateConditionItem(condition)) {
              throw new UnexpectedError(
                `Condition is missing in conditional item children`
              );
            }

            if (condition.childrenIds.length === 0) {
              throw new UnexpectedError(`Condition children are missing`);
            }

            const newCondition = cloneDeep(condition);

            newCondition.id = uuid();
            newCondition.parentId = newItem.id;
            newCondition.questionSetItemId = condition.id;

            a[newCondition.id] = newCondition;

            a[newItem.id].childrenIds[0] = newCondition.id;

            for (let i = 0; i < condition.childrenIds.length; i++) {
              const conditionChildId = condition.childrenIds[i];
              const conditionChild = questionSetDetails.data[conditionChildId];

              if (isTemplateQuestionItem(conditionChild)) {
                const newConditionChild = cloneDeep(conditionChild);

                newConditionChild.id = uuid();
                newConditionChild.parentId = newCondition.id;
                newConditionChild.questionSetItemId = conditionChild.id;

                a[newConditionChild.id] = newConditionChild;

                a[newCondition.id].childrenIds[i] = newConditionChild.id;

                if (newConditionChild.answerType === AnswerType.Text) {
                  newConditionChild.data.questionSetItemAnswerId =
                    newConditionChild.data.id;
                  newConditionChild.data.id = uuid();
                } else {
                  for (
                    let j = 0;
                    j < newConditionChild.data.conditions.length;
                    j++
                  ) {
                    newConditionChild.data.conditions[
                      j
                    ].questionSetItemAnswerId =
                      newConditionChild.data.conditions[j].id;
                    newConditionChild.data.conditions[j].id = uuid();
                  }
                }
              }
            }
          }

          questionSetItem.childrenIds.push(newId);
        }

        return a;
      }, {});

      return {
        questionSetItem,
        questions,
      };
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const setRootItemNotApplicable = createAction<boolean>(
  'auditBuilder/setRootItemNotApplicable'
);

const expandQuestionSet = createAction<string | null>(
  'auditBuilder/expandQuestionSet'
);

export const auditBuilderActions = {
  loadQuestionSetBuilder,
  loadTemplateBuilder,
  startDrag,
  stopDrag,
  dragTemplateItem,
  setInformationPhotosUploading,
  saveTemplate,
  publishDraft,
  createTemplateItem,
  updateTemplate,
  createTemplateSection,
  setSectionId,
  setPage,
  toggleSection,
  deleteTemplateItem,
  updateTemplateQuestion,
  moveTemplateSection,
  showDeleteEntityConfirmModal,
  toggleSections,
  createDraft,
  toggleSectionSettingsModal,
  toggleSaveChangesModal,
  togglePublishChangesModal,
  scrollToBottom,
  setValidationStatus,
  toggleItemsByActionTemplateModal,
  goToItem,
  resetScrollElement,
  resetData,
  toggleCreateDraftCopyModal,
  saveAsDraft,
  updateQuestionSet,
  saveQuestionSet,
  toggleEditQuestionModal,
  getAnswerTypes,
  createTemplateQuestion,
  updateTemplateSection,
  toggleAddQuestionSetModal,
  getQuestionSets,
  setQuestionSetsSearchTerm,
  getQuestionSetPreview,
  setQuestionSetId,
  createQuestionSetItemFromPreview,
  setRootItemNotApplicable,
  dropTemplateQuestion,
  expandQuestionSet,
};
