import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { accountSelectors, accountActions } from '@store';
import { useAppDispatch } from '@hooks';
import { FullScreenModalContent } from './styled';

import MobileFullScreenModal from '@components/shared/MobileFullScreenModal/MobileFullScreenModal';
import HelpCenterContent from '@components/account/AppLayout/HelpCenter/HelpCenterContent';

interface IProps {}

const HelpCenterFullScreenModal: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const dispatch = useAppDispatch();

  const opened = useSelector(accountSelectors.isHelpCenterOpened);

  useEffect(() => {
    return () => {
      dispatch(accountActions.toggleHelpCenter(false));
    };
  }, []);

  return (
    <MobileFullScreenModal
      opened={opened}
      onClose={() => {
        dispatch(accountActions.toggleHelpCenter(false));
      }}
    >
      <FullScreenModalContent>
        <HelpCenterContent />
      </FullScreenModalContent>
    </MobileFullScreenModal>
  );
};

export default HelpCenterFullScreenModal;
