import { forwardRef, SVGAttributes } from 'react';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
}

const AIWhite = forwardRef<SVGSVGElement, IProps>(
  ({ size = 32, title, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <path
          d="M9.36842 5.67688L4 12.559C5.74409 14.7948 8.5717 14.7948 10.3158 12.559L15.6842 5.67688C13.9401 3.44104 11.1125 3.44104 9.36842 5.67688Z"
          fill="white"
        />
        <path
          d="M21.6842 19.0362L16.3158 25.9183C18.0599 28.1541 20.8875 28.1541 22.6316 25.9183L28 19.0362C26.2559 16.8004 23.4283 16.8004 21.6842 19.0362Z"
          fill="white"
        />
        <path
          d="M8.42105 26.3231L20.7368 10.5348C22.4809 8.29898 25.3085 8.29898 27.0526 10.5348L14.7368 26.3231C12.9928 28.559 10.1651 28.559 8.42105 26.3231Z"
          fill="white"
        />
        <path
          d="M16.3158 6.48654L4 22.2748C5.74409 24.5107 8.5717 24.5107 10.3158 22.2748L22.6316 6.48654C20.8875 4.2507 18.0599 4.2507 16.3158 6.48654Z"
          fill="white"
        />
      </svg>
    );
  }
);

export default AIWhite;
