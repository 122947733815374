import React from 'react';

import {
  Container,
  Text,
  Name,
  Icon,
  ExternalIcon,
  Description,
} from './styled';
import { useAppDispatch } from '@hooks';
import { accountActions } from '@store';

interface IProps {
  onClick: () => void;
  icon: React.ReactNode;
  name: string;
  description?: string;
  external?: boolean;
}

const HelpCenterButton: React.FC<React.PropsWithChildren<IProps>> = ({
  onClick,
  icon,
  name,
  description,
  external,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Container
      type="button"
      onClick={() => {
        dispatch(accountActions.toggleHelpCenter(false));

        onClick();
      }}
    >
      <Icon>{icon}</Icon>
      <Text>
        <Name>{name}</Name>
        {description && <Description>{description}</Description>}
      </Text>
      {external && <ExternalIcon />}
    </Container>
  );
};

export default HelpCenterButton;
