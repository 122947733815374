import { v4 as uuid } from 'uuid';

import { BaseApiClient } from '@repo/shared/api';
import {
  AssistantMessage,
  AssistantMessageDto,
  OnboardingChatMessageRole,
} from '@application/Onboarding/models/OnboardingChatMessage';

class OnboardingAssistantApiClient extends BaseApiClient {
  public async startChat() {
    const { threadId } = await this._apiService.post<{
      threadId: string;
    }>({
      url: 'onboarding/assistant/start',
    });

    return threadId;
  }

  public async sendMessage(
    threadId: string,
    text: string
  ): Promise<AssistantMessage> {
    const dto = await this._apiService.post<AssistantMessageDto>({
      url: `onboarding/assistant/messages`,
      body: { threadId, text },
    });

    return {
      ...dto,
      id: uuid(),
      role: OnboardingChatMessageRole.Assistant,
    };
  }
}

export default OnboardingAssistantApiClient;
