import { createEntityAdapter } from '@reduxjs/toolkit';

import {
  IAccount,
  IAuditObject,
  IAuditObjectAttribute,
  IAuditObjectAttributeAssignment,
  IAuditObjectGroup,
  IAuditObjectGroupAssignment,
  ICompletedAuditDetails,
  IConcise,
  IDetailsModalAudit,
  IFailedCriticalItem,
  IFailedItem,
  IJobTitle,
  INotification,
  IOpenAction,
  IParticipant,
  IRole,
  IRoleConcise,
  IScore,
  ITagConcise,
  ITemplateSection,
  IUser,
  IUserAccess,
  IUserConcise,
  IUserGroup,
  IUserGroupAssignment,
  TemplateConcise,
} from '@repo/shared/types';
import { Audit } from '@domain/Audits/models/Audit';

export const accountEntityAdapter = createEntityAdapter<IAccount>();
export const scoresAdapter = createEntityAdapter<IScore>();
export const completedAuditDetailsAdapter =
  createEntityAdapter<ICompletedAuditDetails>();
export const mostFailedItemsAdapter = createEntityAdapter<IFailedItem>();
export const templateSectionsAdapter = createEntityAdapter<ITemplateSection>();
export const failedCriticalItemsAdapter = createEntityAdapter<
  IFailedCriticalItem,
  string
>({
  selectId: (entity) => entity.auditItemId,
});
export const openActionsAdapter = createEntityAdapter<IOpenAction>();
export const rolesAdapter = createEntityAdapter<IRole>();
export const usersConciseAdapter = createEntityAdapter<IUserConcise>();
export const userGroupsConciseAdapter = createEntityAdapter<IConcise>();
export const templatesConciseAdapter = createEntityAdapter<TemplateConcise>();
export const auditObjectsConciseAdapter = createEntityAdapter<IConcise>();
export const auditObjectsGroupConciseAdapter = createEntityAdapter<IConcise>();
export const tagsConciseAdapter = createEntityAdapter<ITagConcise>();
export const accessibleAuditObjectsAdapter = createEntityAdapter<IConcise>();
export const conciseRolesAdapter = createEntityAdapter<IRoleConcise>();
export const auditsDashboardAdapter = createEntityAdapter<Audit>();
export const actionTemplatesConciseAdapter = createEntityAdapter<IConcise>();
export const attributesAdapter = createEntityAdapter<IAuditObjectAttribute>();
export const jobTitlesConciseAdapter = createEntityAdapter<IConcise>();
export const auditObjectGroupAssignmentAdapter =
  createEntityAdapter<IAuditObjectGroupAssignment>();
export const userAccessAdapter = createEntityAdapter<IUserAccess>();
export const usersAdapter = createEntityAdapter<IUser>();
export const usersGroupAdapter = createEntityAdapter<IUserGroup>();
export const jobTitlesAdapter = createEntityAdapter<IJobTitle>();
export const auditObjectsAdapter = createEntityAdapter<IAuditObject>();
export const groupAdapter = createEntityAdapter<IAuditObjectGroup>();
export const attributesAssignmentsAdapter =
  createEntityAdapter<IAuditObjectAttributeAssignment>();
export const userGroupsAssignmentsAdapter =
  createEntityAdapter<IUserGroupAssignment>();
export const participantsAdapter = createEntityAdapter<IParticipant>();
export const detailsModalAuditsAdapter =
  createEntityAdapter<IDetailsModalAudit>();
export const notificationsAdapter = createEntityAdapter<INotification, number>({
  selectId: (notification) => notification.notificationSettingType,
});
