import React from 'react';

import { Container, Content, Main } from './styled';

import Layout from 'antd/es/layout';
import Sidebar from '@components/account/AppLayout/Sidebar/Sidebar';
import Header from '@components/account/AppLayout/DesktopLayout/Header/Header';
import SharedModals from '@components/account/AppLayout/DesktopLayout/SharedModals';
import ChunkErrorBoundary from '@components/account/AppLayout/ChunkErrorBoundary';
import Announcement from '@components/shared/Announcement/Announcement';

interface IProps {}

const DesktopLayout: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
}) => {
  return (
    <>
      <Announcement />
      <Container>
        <Layout>
          <Sidebar />
          <Content>
            <Header />
            <Main>
              <ChunkErrorBoundary>{children}</ChunkErrorBoundary>
            </Main>
            <SharedModals />
          </Content>
        </Layout>
      </Container>
    </>
  );
};

export default DesktopLayout;
