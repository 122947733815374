import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Button = styled.button`
  background: none;
  border: 0;
  padding: 0;
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
    color: ${({ theme }: { theme: 'light' | 'dark' }) =>
      theme === 'light' ? colors.gray10 : colors.white};
  }
`;
