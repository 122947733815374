import { createReducer } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { OnboardingState } from '@application/Onboarding/models/OnboardingState';
import { onboardingChatMessagesEntityAdapter } from '@application/Onboarding/store/entityAdapters';
import { onboardingActions } from '@application/Onboarding/store/onboardingActions';
import { OnboardingChatMessageRole } from '@application/Onboarding/models/OnboardingChatMessage';

const initialAssistantState = {
  currentStep: null,
  starting: false,
  thinking: false,
  error: null,
  messages: onboardingChatMessagesEntityAdapter.getInitialState(),
  completedSteps: {},
  data: {},
  threadId: null,
};

export const onboardingReducer = createReducer<OnboardingState>(
  {
    onboardingModalMode: null,
    assistant: {
      ...initialAssistantState,
    },
  },
  (builder) =>
    builder
      .addCase(onboardingActions.startChat.pending, (state) => {
        state.assistant.starting = true;
      })
      .addCase(onboardingActions.startChat.fulfilled, (state, { payload }) => {
        state.assistant.threadId = payload;
        state.assistant.starting = false;
      })
      .addCase(onboardingActions.startChat.rejected, (state, { payload }) => {
        state.assistant.starting = false;
        state.assistant.error = payload || null;
      })
      .addCase(
        onboardingActions.sendUserMessage.pending,
        (state, { meta: { arg } }) => {
          state.assistant.thinking = true;

          onboardingChatMessagesEntityAdapter.addOne(state.assistant.messages, {
            id: uuid(),
            text: arg,
            role: OnboardingChatMessageRole.User,
          });
        }
      )
      .addCase(
        onboardingActions.sendUserMessage.fulfilled,
        (state, { payload }) => {
          state.assistant.thinking = false;

          onboardingChatMessagesEntityAdapter.addOne(
            state.assistant.messages,
            payload
          );

          if (payload.nextStep !== undefined) {
            state.assistant.currentStep = payload.nextStep;
          }

          if (payload.completedStep !== null) {
            state.assistant.completedSteps[payload.completedStep.step] =
              payload.completedStep;
          }
        }
      )
      .addCase(
        onboardingActions.sendUserMessage.rejected,
        (state, { payload }) => {
          state.assistant.thinking = false;
          state.assistant.error = payload || null;
        }
      )
      .addCase(
        onboardingActions.toggleOnboardingModal,
        (state, { payload }) => {
          state.onboardingModalMode = payload;

          if (payload === null) {
            state.assistant = {
              ...initialAssistantState,
            };
          }
        }
      )
);
