import { createAction, createAsyncThunk, Update } from '@reduxjs/toolkit';

import { IConcise, IPagedResponse } from '@repo/shared/types';
import { ActionsFilters } from '@application/Actions/models/ActionsFilters';
import { Action, ActionDetails } from '@domain/Actions/models/Action';
import { Comment } from '@domain/common/Comment';
import { CreateActionDto } from '@infrastructure/Actions/models/CreateActionDto';
import { ActionStatus, ExportType } from '@repo/shared/enums';
import { ActionsPage } from '@application/Actions/enums/ActionsPage';
import { date } from '@utils';
import {
  getErrorMessage,
  saveFile,
  getExportFileMetaData,
} from '@repo/shared/utils';
import { IRootState } from '@src/core/frameworks/redux';
import { Logger } from '@repo/shared/services';
import { apiUrls } from '@config';
import { config } from '@repo/shared/config';
import { actionsSelectors } from '@application/Actions/store/actionsSelectors';
import { InternalApiService } from '@repo/shared/api';
import ActionsApiClient from '@infrastructure/Actions/api/clients/ActionsApiClient';
import { ChangeStatusModalState } from '@application/Actions/models/ChangeStatusModalState';
import { DeleteActionCommentModalState } from '@application/Actions/models/DeleteActionCommentModalState';

const apiService = InternalApiService.getInstance();
const apiClient = new ActionsApiClient();

const setPage = createAction<ActionsPage>('actions/setPage');

const getActions = createAsyncThunk<
  IPagedResponse<Action>,
  Partial<ActionsFilters> | null | undefined,
  { state: IRootState; rejectValue: string }
>('actions/getActions', async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState();

    const page = actionsSelectors.getPage(state);
    const filters = {
      ...actionsSelectors.getFilters(state),
    };

    return await apiClient.getActions(page, filters);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const updateAction = createAsyncThunk<
  ActionDetails,
  ActionDetails,
  { state: IRootState; rejectValue: string }
>('actions/updateAction', async (action, { rejectWithValue }) => {
  try {
    await apiClient.updateAction(action);

    return action;
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const deleteActions = createAsyncThunk<
  void,
  string[],
  { state: IRootState; rejectValue: string }
>('actions/deleteActions', async (ids, { rejectWithValue }) => {
  try {
    await apiClient.deleteActions(ids);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const changeActionsStatuses = createAsyncThunk<
  {
    status:
      | ActionStatus.Approved
      | ActionStatus.Rejected
      | ActionStatus.Submitted;
    ids: string[];
  },
  {
    status:
      | ActionStatus.Approved
      | ActionStatus.Rejected
      | ActionStatus.Submitted;
    ids: string[];
  },
  { state: IRootState; rejectValue: string }
>(
  'actions/changeActionsStatuses',
  async ({ ids, status }, { rejectWithValue }) => {
    try {
      await apiClient.changeActionsStatus(ids, status);

      return {
        ids,
        status,
      };
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const expireActions = createAsyncThunk<
  string[],
  string[],
  { rejectValue: string }
>('actions/expireActions', async (ids, { rejectWithValue }) => {
  try {
    await apiClient.expireActions(ids);
    return ids;
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const createCorrectiveAction = createAsyncThunk<
  string,
  CreateActionDto,
  { rejectValue: string }
>('actions/createAction', async (dto, { rejectWithValue }) => {
  try {
    return await apiClient.createAction(dto);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const toggleChangeStatusModal = createAction<ChangeStatusModalState>(
  'actions/toggleChangeStatusModal'
);

const updateActionDetailsAssignees = createAction<{
  actionId: string;
  assignees: IConcise[];
}>('actions/updateActionDetailsAssignees');

const toggleActionDetailsModal = createAction<string | null>(
  'actions/toggleActionDetailsModal'
);

const getActionDetails = createAsyncThunk<
  ActionDetails,
  string,
  { state: IRootState; rejectValue: string }
>('actions/getActionDetails', async (id, { rejectWithValue }) => {
  try {
    return await apiClient.getActionDetails(id);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const toggleFiltersModal = createAction<boolean>('actions/toggleFiltersModal');

const resetListData = createAction('actions/resetActionsData');

const resetActionDetailsData = createAction('actions/resetActionDetailsData');

const createActionComment = createAsyncThunk<
  void,
  {
    actionId: string;
    comment: Comment;
  },
  { state: IRootState }
>(
  'actions/createActionComment',
  async ({ actionId, comment }, { rejectWithValue }) => {
    try {
      await apiClient.createActionComment(actionId, comment);
    } catch (e) {
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const editActionComment = createAsyncThunk<
  void,
  {
    actionId: string;
    comment: Comment;
  },
  { state: IRootState }
>(
  'actions/editActionComment',
  async ({ actionId, comment }, { rejectWithValue }) => {
    try {
      await apiClient.editActionComment(actionId, comment);
    } catch (e) {
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const toggleDeleteActionCommentModal =
  createAction<DeleteActionCommentModalState>(
    'actions/toggleDeleteActionCommentModal'
  );

const deleteActionComment = createAsyncThunk<
  string,
  {
    actionId: string;
    commentId: string;
  },
  { state: IRootState }
>(
  'actions/deleteComment',
  async ({ actionId, commentId }, { rejectWithValue }) => {
    try {
      await apiClient.deleteActionComment(actionId, commentId);
      return commentId;
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const setEditCommentId = createAction<string | null>(
  'actions/setEditCommentId'
);

const exportActions = createAsyncThunk<void, ExportType, { state: IRootState }>(
  'actions/exportActions',
  async (exportType, { getState, rejectWithValue }) => {
    try {
      const state = getState();

      const data = await apiClient.exportActions(
        actionsSelectors.getPage(state),
        exportType,
        actionsSelectors.getFilters(state)
      );

      const { extension, mimeType } = getExportFileMetaData(exportType);

      saveFile({
        data,
        mimeType,
        fileName: `All Corrective Actions - ${date().format(
          config.dateFormat
        )}.${extension}`,
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const toggleAssignUsersToActionsModal = createAction<{
  actionsIds: string[];
  auditObjectId: string;
} | null>('actions/toggleAssignUsersToActionsModal');

const assignUsersToActions = createAsyncThunk<
  void,
  {
    usersIds: string[];
    actionsIds: string[];
  },
  { state: IRootState }
>(
  'actions/assignUsersToActions',
  async ({ usersIds, actionsIds }, { rejectWithValue }) => {
    try {
      await apiService.post({
        url: `${apiUrls.correctiveActions}/assign`,
        body: {
          usersIds,
          correctiveActionsIds: actionsIds,
        },
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const getAllAuditActions = createAsyncThunk<
  Action[],
  string,
  { state: IRootState; rejectValue: string }
>('actions/getAllAuditActions', async (auditId, { rejectWithValue }) => {
  try {
    return await apiClient.getAllAuditActions(auditId);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const updateActionLocally = createAction<Update<Action, string>>(
  'actions/updateActionLocally'
);

const deleteActionLocally = createAction<string>('actions/deleteActionLocally');

const deleteViewAuditModalAction = createAction<string>(
  'actions/deleteViewAuditModalAction'
);

const updateViewAuditModalAction = createAction<{
  id: string;
  changes: Partial<Action>;
}>('actions/updateViewAuditModalAction');

export const actionsActions = {
  setPage,
  getActions,
  updateAction,
  deleteActions,
  changeActionsStatuses,
  expireActions,
  createAction: createCorrectiveAction,
  toggleActionDetailsModal,
  getActionDetails,
  toggleChangeStatusModal,
  updateActionDetailsAssignees,
  toggleFiltersModal,
  resetListData,
  resetActionDetailsData,
  createActionComment,
  editActionComment,
  toggleDeleteActionCommentModal,
  deleteActionComment,
  setEditCommentId,
  exportActions,
  toggleAssignUsersToActionsModal,
  deleteActionLocally,
  assignUsersToActions,
  getAllAuditActions,
  updateActionLocally,
  deleteViewAuditModalAction,
  updateViewAuditModalAction,
};
