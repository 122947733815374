import { AnswerType, ItemType } from '@repo/shared/enums';
import {
  createQuestionItem,
  createRootItem,
} from '@application/AuditBuilder/utils/createTemplateItem';
import { QuestionSetDetails } from '@domain/QuestionSets/QuestionSetDetails';
import { TemplateItem } from '@domain/AuditTemplates/TemplateItem';
import { IRootState } from '@src/core/frameworks/redux';
import { ZERO_UUID } from '@config';

export function createQuestionSet(state: IRootState): QuestionSetDetails {
  const rootItem = createRootItem();

  const items: TemplateItem[] = [rootItem];

  for (let i = 0; i < 1; i++) {
    const question = createQuestionItem(
      {
        parentId: rootItem.id,
        itemType: ItemType.Item,
        answerType: AnswerType.PassFailButtons,
        index: i,
      },
      state
    );

    rootItem.childrenIds.push(question.id);
    items.push(question);
  }

  return {
    id: ZERO_UUID,
    name: 'New question set',
    description: '',
    data: items.reduce<Record<string, TemplateItem>>((acc, item) => {
      const a = acc;
      a[item.id] = item;
      return a;
    }, {}),
  };
}
