import React, { forwardRef, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { onboardingSelectors } from '@application/Onboarding/store/onboardingSelectors';
import { OnboardingChatMessageRole } from '@application/Onboarding/models/OnboardingChatMessage';
import { Container, Content } from './styled';

import AssistantChatMessage from '@presentation/Onboarding/OnboardingModal/OnboardingAssistant/OnboardingAssistantChat/OnboardingAssistantChatMessages/AssistantChatMessage/AssistantChatMessage';
import UserChatMessage from '@presentation/Onboarding/OnboardingModal/OnboardingAssistant/OnboardingAssistantChat/OnboardingAssistantChatMessages/UserChatMessage/UserChatMessage';
import ReactScrollbarsCustom from 'react-scrollbars-custom';
import Scrollbar from '@repo/shared/components/Scrollbar/Scrollbar';
import CurrentStepAddons from '@presentation/Onboarding/OnboardingModal/OnboardingAssistant/OnboardingAssistantChat/OnboardingAssistantChatMessages/CurrentStepAddons/CurrentStepAddons';

interface IProps {}

const OnboardingAssistantChatMessages: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const messages = useSelector(onboardingSelectors.getMessages);

  const scrollbarRef = useRef<ReactScrollbarsCustom>(null);

  useEffect(() => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollToBottom();
    }
  }, [messages]);

  return (
    <Container>
      <Scrollbar ref={scrollbarRef}>
        <Content>
          {messages.map((message) => {
            switch (message.role) {
              case OnboardingChatMessageRole.Assistant:
                return (
                  <React.Fragment key={message.id}>
                    <AssistantChatMessage message={message} />
                    <CurrentStepAddons step={message.nextStep} />
                  </React.Fragment>
                );
              case OnboardingChatMessageRole.User:
                return <UserChatMessage key={message.id} message={message} />;
            }
          })}
        </Content>
      </Scrollbar>
    </Container>
  );
};

export default OnboardingAssistantChatMessages;
