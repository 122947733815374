import { BaseApiClient } from '@repo/shared/api';
import { ITableFilters } from '@repo/shared/types';
import {
  ImportRecord,
  ImportRecordCode,
  ImportRecordType,
} from '@domain/TransferTasks/ImportRecord';
import { TransferTaskType } from '@domain/TransferTasks/TransferTaskType';
import { TransferTaskDataType } from '@domain/TransferTasks/TransferTaskDataType';
import { IPagedResponse } from '@repo/shared/types';
import {
  ExportTask,
  ImportTask,
  TransferTask,
} from '@domain/TransferTasks/TransferTask';
import { date, delay } from '@repo/shared/utils';
import { v4 as uuid } from 'uuid';
import { config } from '@repo/shared/config';
import { TransferTaskStatus } from '@domain/TransferTasks/TransferTaskStatus';
import { ImportTaskDetails } from '@domain/TransferTasks/ImportTaskDetails';
import { GetTransferTaskDto } from '@infrastructure/TransferTasks/models/GetTransferTaskDto';
import { transformAuditTemplateGroupDto } from '@infrastructure/AuditTemplates/transformers/transformAuditTemplateGroupDto';

class TransferTasksApiClient extends BaseApiClient {
  public async getImportTasks(filters: ITableFilters) {
    // await delay(500);
    //
    // return {
    //   meta: {
    //     totalCount: 3,
    //     pageSize: 10,
    //     pageNumber: 1,
    //   },
    //   data: [
    //     {
    //       id: uuid(),
    //       dataType: TransferTaskDataType.Users,
    //       startDate: date().format(config.apiDateFormat),
    //       status: TransferTaskStatus.Succeeded,
    //     },
    //     {
    //       id: uuid(),
    //       dataType: TransferTaskDataType.Objects,
    //       startDate: date().format(config.apiDateFormat),
    //       status: TransferTaskStatus.InProgress,
    //     },
    //     {
    //       id: uuid(),
    //       dataType: TransferTaskDataType.Objects,
    //       startDate: date().format(config.apiDateFormat),
    //       status: TransferTaskStatus.Failed,
    //     },
    //   ],
    // };

    return await this._apiService.get<IPagedResponse<ImportTask>>({
      url: 'tasks/import',
      query: {
        ...filters,
      },
    });
  }

  public async getExportTasks(filters: ITableFilters) {
    return await this._apiService.get<IPagedResponse<ExportTask>>({
      url: 'tasks/export',
      query: {
        ...filters,
      },
    });
  }

  public async getImportTaskDetails(id: string) {
    // await delay(500);
    //
    // return {
    //   id: uuid(),
    //   dataType: TransferTaskDataType.Objects,
    //   status: TransferTaskStatus.Failed,
    //   startDate: date().format(config.apiDateFormat),
    //   records: getRecords(),
    // };

    return await this._apiService.get<ImportTaskDetails>({
      url: `tasks/import/${id}`,
    });
  }

  public async validateImportTask(
    fileId: string,
    dataType: TransferTaskDataType
  ) {
    const { result } = await this._apiService.post<{ result: ImportRecord[] }>({
      url: `tasks/import/validate`,
      body: {
        dataType,
        fileId,
      },
    });

    return result;
  }

  public async createImportTask(
    fileId: string,
    dataType: TransferTaskDataType
  ) {
    await this._apiService.post({
      url: `tasks/import`,
      body: {
        dataType,
        fileId,
      },
    });
  }

  public async createExportTask(dataType: TransferTaskDataType) {
    await this._apiService.post({
      url: `tasks/export`,
      body: {
        dataType,
      },
    });
  }
}

export default TransferTasksApiClient;
