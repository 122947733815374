import { IAnyObject, IParam } from '@repo/shared/types';
import { intl } from '@repo/shared/components/IntlGlobalProvider';

export function parseContentDispositionHeader(header: string): string | null {
  if (!header) return null;

  // Extract the filename part (assumes "filename=" is present)
  let filenamePart = header.split('filename=')[1];

  if (!filenamePart) return null;

  // Get rid of any extra parameters and trim whitespace.
  filenamePart = filenamePart.split(';')[0].trim();

  // Remove wrapping quotes if present
  if (
    (filenamePart.startsWith('"') && filenamePart.endsWith('"')) ||
    (filenamePart.startsWith("'") && filenamePart.endsWith("'"))
  ) {
    filenamePart = filenamePart.substring(1, filenamePart.length - 1);
  }

  // Decode URI components (if the filename was URL encoded)
  try {
    filenamePart = decodeURIComponent(filenamePart);
  } catch (e) {
    // Ignore decode errors; use the raw value if decoding fails.
  }

  // Remove any wrapping underscores (e.g. _filename_) and trailing underscores.
  filenamePart = filenamePart.replace(/^_+/, '').replace(/_+$/, '');

  // Remove trailing dots and spaces.
  filenamePart = filenamePart.replace(/[. ]+$/, '');

  if (!/\.[a-zA-Z0-9]{1,5}$/.test(filenamePart)) {
    filenamePart += '.pdf';
  }

  return filenamePart;
}

export const getQueryStringParams = (allowedQueryFilters?: string[]) => {
  const query = window.location.search;

  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params: IAnyObject, param) => {
          let [key, value] = param.split('=');
          const isArray = key.includes('[]');
          const keyName = key.split('[]')[0];

          if (
            Array.isArray(allowedQueryFilters) &&
            allowedQueryFilters.includes(keyName)
          ) {
            const valStr = value
              ? decodeURIComponent(value.replace(/\+/g, ' '))
              : '';

            if (isArray) {
              if (!Array.isArray(params[keyName])) {
                params[keyName] = [];
              }

              params[keyName].push(valStr);
            } else {
              params[keyName] = valStr;
            }
          }

          return params;
        }, {})
    : {};
};

export const getQueryString = (params: { [name: string]: IParam }): string => {
  return Object.keys(params)
    .reduce((acc: string[], key: string) => {
      let param;

      if (Array.isArray(params[key])) {
        const arrayParams = params[key] as (string | number)[];
        param = arrayParams
          .map((value) => `${key}[]=${encodeURIComponent(value)}`)
          .join('&');
      } else {
        param = `${key}=${encodeURIComponent(params[key] as string | number)}`;
      }

      acc.push(param);

      return acc;
    }, [])
    .join('&');
};

export const getErrorMessage = (e: any) => {
  if (!intl) {
    return 'An unexpected error occurred. Please try again later.';
  }

  if (e.message && intl.messages[e.message]) {
    return intl.formatMessage({ id: e.message });
  }

  return intl.formatMessage({ id: 'UnexpectedError' });
};
