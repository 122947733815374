import React from 'react';
import { useRoutes } from 'react-router';

import { useAppRoutes } from '@src/core/router/useAppRoutes';

const AppRoutes: React.FC = () => {
  const appRoutes = useAppRoutes();
  const router = useRoutes(appRoutes);

  return <>{router}</>;
};

export default AppRoutes;
