import React, { useEffect, useState } from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import AntSelect from 'antd/es/select';
import { FormInstance } from 'antd/es/form';
import { useIntl } from 'react-intl';

import { CompanySize, Industry, UsagePurpose } from '@repo/shared/enums';
import {
  disallowURL,
  enumToSelectOptions,
  useTranslatedOptions,
  validations,
  filterOptionsByChildren,
  filterOptionsByLabel,
} from '@utils';
import { e2eTestElements } from '@config';

import Form from '../shared/ant/Form';
import { Input } from '../shared/ant/Input';
import Select from '../shared/ant/Select/Select';
import TimeZonesSelect from '@components/shared/EntitySelects/TimeZonesSelect';

const companySizeOptions = enumToSelectOptions(CompanySize);
const industryOptions = enumToSelectOptions(Industry);
const { Option } = AntSelect;

interface IProps {
  form: FormInstance;
  initialValues?: {
    fullName?: string;
    phone?: string;
    name: string;
    companySize: CompanySize | null;
    industry: Industry | null;
    usagePurpose: UsagePurpose | null;
    usagePurposeObjectName: string;
    defaultIanaTimeZone: string | null;
  };
  onSubmit?: (values: any) => Promise<any>;
  showAdminFields?: boolean;
  disabled?: boolean;
}

const CompanyForm: React.FC<React.PropsWithChildren<IProps>> = ({
  form,
  initialValues,
  onSubmit,
  showAdminFields,
  disabled,
}) => {
  const { formatMessage } = useIntl();

  const [usagePurpose, setUsagePurpose] = useState<UsagePurpose | null>(null);
  const industryOptionsTranslated = useTranslatedOptions(industryOptions);

  useEffect(() => {
    if (initialValues) {
      if (initialValues.defaultIanaTimeZone) {
        form.resetFields(['defaultIanaTimeZone']);
      }

      if (Object.keys(initialValues || {}).includes('usagePurpose')) {
        setUsagePurpose(initialValues.usagePurpose);
      }
    }
  }, [JSON.stringify(initialValues || {})]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={
        initialValues || {
          fullName: '',
          phone: '',
          name: '',
          companySize: null,
          industry: null,
          usagePurpose: null,
          usagePurposeObjectName: '',
          defaultIanaTimeZone: null,
        }
      }
      onFinish={onSubmit}
      onValuesChange={(update) => {
        if (Object.keys(update).includes('usagePurpose')) {
          setUsagePurpose(update.usagePurpose);
        }
      }}
    >
      {showAdminFields && (
        <>
          <Form.Item
            name="fullName"
            label={formatMessage({ id: 'FullName' })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
              {
                max: 300,
                message: formatMessage(
                  {
                    id: 'NameMustNotExceedXCharacters',
                  },
                  { count: 300 }
                ),
              },
              disallowURL(),
            ]}
          >
            <Input
              disabled={disabled}
              data-e2e={e2eTestElements.account.companyForm.adminFullName}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            label={formatMessage({ id: 'PhoneNumber' })}
            rules={[
              {
                pattern: validations.phone,
                message: formatMessage({
                  id: 'PhoneNumberMustContainFrom10To12Chars',
                }),
              },
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
            ]}
          >
            <Input
              disabled={disabled}
              data-e2e={e2eTestElements.account.companyForm.adminPhone}
            />
          </Form.Item>
        </>
      )}
      <Form.Item
        name="name"
        label={formatMessage({ id: 'CompanyName' })}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'RequiredField' }),
          },
          {
            max: 80,
            message: formatMessage(
              {
                id: 'NameMustNotExceedXCharacters',
              },
              { count: 80 }
            ),
          },
          disallowURL(),
        ]}
      >
        <Input
          data-e2e={e2eTestElements.account.companyForm.nameInput}
          disabled={disabled}
        />
      </Form.Item>
      <Row gutter={[14, 0]}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="companySize"
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
            ]}
            label={formatMessage({ id: 'EstimatedNumberOfUser' })}
          >
            <Select
              e2eDataAttr={
                e2eTestElements.account.companyForm.companySizeSelect
              }
              showSearch
              filterOption={filterOptionsByLabel}
              options={companySizeOptions}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="industry"
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
            ]}
            label={formatMessage({ id: 'Industry' })}
          >
            <Select
              options={industryOptionsTranslated}
              showSearch
              filterOption={filterOptionsByLabel}
              e2eDataAttr={e2eTestElements.account.companyForm.industrySelect}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="defaultIanaTimeZone"
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'RequiredField' }),
          },
        ]}
        label={formatMessage({
          id: 'Timezone',
        })}
      >
        <TimeZonesSelect
          disabled={disabled}
          e2eDataAttr={e2eTestElements.account.companyForm.tzSelect}
          dropdownAlign={{
            overflow: { adjustY: 0 },
          }}
        />
      </Form.Item>
      <Form.Item
        name="usagePurpose"
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'RequiredField' }),
          },
        ]}
        label={formatMessage({
          id: 'WeWillUseMonitorQAToAuditOrInspect',
        })}
      >
        <Select
          e2eDataAttr={e2eTestElements.account.companyForm.usagePurposeSelect}
          disabled={disabled}
          showSearch
          filterOption={filterOptionsByChildren}
          dropdownAlign={{
            overflow: { adjustY: 0 },
          }}
        >
          <Option value={UsagePurpose.Locations}>
            {formatMessage({ id: 'Locations' })}
          </Option>
          <Option value={UsagePurpose.Stores}>
            {formatMessage({ id: 'Stores' })}
          </Option>
          <Option value={UsagePurpose.Sites}>
            {formatMessage({ id: 'Sites' })}
          </Option>
          <Option value={UsagePurpose.Machines}>
            {formatMessage({ id: 'Machines' })}
          </Option>
          <Option value={UsagePurpose.Plants}>
            {formatMessage({ id: 'Plants' })}
          </Option>
          <Option value={UsagePurpose.Vehicles}>
            {formatMessage({ id: 'Vehicles' })}
          </Option>
          <Option value={UsagePurpose.Processes}>
            {formatMessage({ id: 'Processes' })}
          </Option>
          <Option value={UsagePurpose.SomethingElse}>
            {formatMessage({ id: 'SomethingElse' })}
          </Option>
        </Select>
      </Form.Item>
      {usagePurpose === UsagePurpose.SomethingElse && (
        <Form.Item
          name="usagePurposeObjectName"
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
            {
              max: 20,
              message: formatMessage(
                {
                  id: 'NameMustNotExceedXCharacters',
                },
                { count: 20 }
              ),
            },
          ]}
          label={formatMessage({ id: 'NameOfYourAuditObject' })}
        >
          <Input
            disabled={disabled}
            data-e2e={e2eTestElements.account.companyForm.usagePurposeInput}
          />
        </Form.Item>
      )}
    </Form>
  );
};

export default CompanyForm;
