import React, { ReactNode, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { accountSelectors } from '@store';
import { HAS_RESET_PASSWORD_TEMP, routes } from '@config';
import * as UserPreferencesService from '@services/userPreferences.service';

import AppLayout from '../AppLayout/AppLayout';
import Loader from '@repo/shared/components/Loader';

interface IProps {
  children: ReactNode;
  skipChangePasswordCheck?: boolean;
  Layout?: React.ElementType;
}

const ProtectedRoute: React.FC<IProps> = ({
  children,
  skipChangePasswordCheck,
  Layout = AppLayout,
}) => {
  const user = useSelector(accountSelectors.getCurrentUser);
  const authUserId = useSelector(accountSelectors.getAuthUserId);

  if (!user) {
    return <Navigate to={routes.signin} replace />;
  }

  if (
    !skipChangePasswordCheck &&
    user?.hasResetPassword === false &&
    UserPreferencesService.getItemSync(authUserId, HAS_RESET_PASSWORD_TEMP) !==
      true
  ) {
    return <Navigate to={routes.changePassword} replace />;
  }

  return (
    <Layout>
      <Suspense fallback={<Loader />}>{children}</Suspense>
    </Layout>
  );
};

export default ProtectedRoute;
