import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@src/core/frameworks/redux';
import {
  AddQuestionSetModalTargetPlace,
  ConfirmDeleteItem,
  EditQuestionModalState,
  SaveChangesModalState,
} from '@application/AuditBuilder/models/AuditBuilderState';
import {
  calculateItemTreeStats,
  checkIsChildNADisabledInTree,
  convertPerformAuditItemsDictToTree,
} from '@utils';
import { getAncestorsSections } from '@application/AuditBuilder/utils/getAncestorsSections';
import { checkIsParentNADisabled } from '@application/AuditBuilder/utils/checkIsParentNADisabled';
import { IScore } from '@repo/shared/types';
import { AuditTemplateDetails } from '@domain/AuditTemplates/AuditTemplateDetails';
import { AuditTemplateType, ItemType } from '@repo/shared/enums';
import { colors } from '@repo/shared/config';
import { QuestionSetDetails } from '@domain/QuestionSets/QuestionSetDetails';
import { AuditBuilderPage } from '@application/AuditBuilder/enums/AuditBuilderPage';
import { ValidationStatus } from '@application/AuditBuilder/enums/ValidationStatus';
import {
  getQuestionSetParentIfExists,
  isTemplateQuestionItem,
  isTemplateSectionItem,
  TemplateItem,
  TemplateQuestionItem,
  TemplateSectionItem,
} from '@domain/AuditTemplates/TemplateItem';
import { calculateTemplatePreviewTotals } from '@application/AuditBuilder/utils/calculateTemplatePreviewTotals';
import { convertTemplateItemToPerformAuditItem } from '@application/AuditBuilder/utils/convertTemplateItemToPerformAuditItem';
import { PerformAuditItem } from '@domain/PerformAudit/PerformAuditItem';
import {
  AnswerTypeData,
  AnswerTypeDataMapping,
} from '@domain/AuditTemplates/AnswerTypeData';
import { QuestionSet } from '@domain/QuestionSets/QuestionSet';
import { ZERO_UUID } from '@config';

const getItemsDictionary = (
  state: IRootState
): Record<string, TemplateItem> | null => state.auditBuilder.items;

const getTemplateDetails = createSelector(
  [
    (state: IRootState): Omit<AuditTemplateDetails, 'data'> | null =>
      state.auditBuilder.template,
    getItemsDictionary,
  ],
  (template, data) =>
    template && data
      ? {
          ...template,
          data,
        }
      : null
);

const getQuestionSet = createSelector(
  [
    (state: IRootState): Omit<QuestionSetDetails, 'data'> | null =>
      state.auditBuilder.questionSet,
    getItemsDictionary,
  ],
  (questionSet, data) =>
    questionSet && data
      ? {
          ...questionSet,
          data,
        }
      : null
);

const getRootItemId = (state: IRootState): string | null =>
  state.auditBuilder.rootItemId;

const isOpened = (state: IRootState): boolean =>
  state.auditBuilder.page !== null;

const getPage = (state: IRootState): AuditBuilderPage =>
  state.auditBuilder.page;

const isLoading = (state: IRootState): boolean => state.auditBuilder.loading;

const hasUnsavedChanges = (state: IRootState): boolean =>
  state.auditBuilder.hasChanges;

const getValidationStatus = (state: IRootState): ValidationStatus =>
  state.auditBuilder.validationStatus;

const getOpenedSectionId = (state: IRootState): string | null =>
  state.auditBuilder.openedSectionId;

const getOpenedSection = createSelector(
  [getOpenedSectionId, getItemsDictionary],
  (openedSectionId, itemsDictionary) =>
    openedSectionId &&
    itemsDictionary?.[openedSectionId] &&
    isTemplateSectionItem(itemsDictionary[openedSectionId])
      ? itemsDictionary[openedSectionId]
      : null
);

const getScrollElement = (state: IRootState): string | null =>
  state.auditBuilder.scrollElement;

const makeGetTemplatePreviewItemTree = (
  getItemsDictionarySelector: (
    state: IRootState
  ) => Record<string, TemplateItem> | null = getItemsDictionary
) =>
  createSelector(
    [
      getItemsDictionarySelector,
      (state: IRootState, parentId?: string | null) => parentId,
    ],
    (items, rootItemId) => {
      if (!items || !rootItemId) {
        return {
          tree: null,
          isParentNADisabled: false,
          isChildNADisabled: false,
        };
      }

      const itemsTree = convertPerformAuditItemsDictToTree({
        itemsMap: Object.values(items).reduce<Record<string, PerformAuditItem>>(
          (acc, templateItem) => {
            const a = acc;
            a[templateItem.id] =
              convertTemplateItemToPerformAuditItem(templateItem);
            return a;
          },
          {}
        ),
        rootItemId,
      });

      return {
        tree: itemsTree,
        isParentNADisabled: checkIsParentNADisabled(items, rootItemId, true),
        isChildNADisabled: checkIsChildNADisabledInTree(itemsTree),
      };
    }
  );

const geTemplateScoreSystem = (state: IRootState): IScore | null =>
  state.auditBuilder.scoreSystem;

const makeGetStatsById = (
  getItemsDictionarySelector: (
    state: IRootState
  ) => Record<string, TemplateItem> | null = getItemsDictionary
) =>
  createSelector(
    [
      geTemplateScoreSystem,
      getItemsDictionarySelector,
      getRootItemId,
      (state: IRootState, rootItemId: string | null) => rootItemId,
      getTemplateDetails,
    ],
    (
      auditScoreSystem,
      items,
      templateRootItemId,
      rootItemId,
      templateDetails
    ) => {
      if (!items || !rootItemId || !items[rootItemId] || !templateRootItemId) {
        return null;
      }

      return calculateItemTreeStats({
        itemsMap: Object.values(items).reduce<Record<string, PerformAuditItem>>(
          (acc, item) => {
            const a = acc;
            a[item.id] = convertTemplateItemToPerformAuditItem(item);
            return a;
          },
          {}
        ),
        rootItemId,
        templateOrAuditRootItemId: templateRootItemId,
        auditScoreSystem,
        isChecklist:
          templateDetails?.templateType === AuditTemplateType.Checklist,
      });
    }
  );

const makeGetTemplateItemPreviewById = (
  getItemsDictionarySelector: (
    state: IRootState
  ) => Record<string, TemplateItem> | null = getItemsDictionary
) =>
  createSelector(
    [getItemsDictionarySelector, (_: IRootState, id: string | null) => id],
    (items, id) =>
      id && items?.[id]
        ? convertTemplateItemToPerformAuditItem(items[id])
        : null
  );

const getTemplatePreviewTotals = createSelector(
  [getItemsDictionary, getRootItemId],
  (items, rootItemId) => {
    return rootItemId && items
      ? calculateTemplatePreviewTotals(items, rootItemId)
      : null;
  }
);

const getRootItem = createSelector(
  [getItemsDictionary, getRootItemId],
  (items, rootItemId) =>
    items && rootItemId && items[rootItemId].itemType === ItemType.Root
      ? items[rootItemId]
      : null
);

const getItemsCount = createSelector(getItemsDictionary, (items) =>
  items ? Object.keys(items).length : 0
);

const makeIsNADisabled = () =>
  createSelector(
    [
      getItemsDictionary,
      (state: IRootState, id: string | null | undefined) => id,
    ],
    (items, itemId) => {
      return !itemId || !items?.[itemId]
        ? false
        : checkIsParentNADisabled(items, itemId);
    }
  );

const isInformationPhotosUploading = (state: IRootState): boolean | null =>
  state.auditBuilder.isInformationPhotosUploading;

const getConfirmDeleteItem = (state: IRootState): ConfirmDeleteItem =>
  state.auditBuilder.confirmDeleteItem;

const isSectionExpanded = (sectionId: string) => (state: IRootState) =>
  state.auditBuilder.expandedSections[sectionId] !== undefined;

const getTemplateSections = createSelector(
  [getItemsDictionary, getRootItemId],
  (items, rootItemId) => {
    if (!items || !rootItemId || !items[rootItemId]) {
      return [];
    }

    return items[rootItemId].childrenIds
      .slice()
      .sort((id1: string, id2: string) => items[id1].index - items[id2].index)
      .map((id: string) => items[id])
      .filter(
        (item): item is TemplateSectionItem =>
          item.itemType === ItemType.Section
      );
  }
);

const getSectionVisibilityModalData = createSelector(
  [
    getItemsDictionary,
    (state: IRootState): string | null =>
      state.auditBuilder.sectionVisibilityModalId,
  ],
  (items, sectionVisibilityModalId) => {
    if (
      sectionVisibilityModalId === null ||
      !items?.[sectionVisibilityModalId] ||
      !isTemplateSectionItem(items[sectionVisibilityModalId])
    ) {
      return null;
    }

    return items[sectionVisibilityModalId];
  }
);

const getSaveChangesModalState = (state: IRootState): SaveChangesModalState =>
  state.auditBuilder.saveChangesModal;

const isPublishChangesModalOpened = (state: IRootState): boolean =>
  state.auditBuilder.showPublishChangesModal;

const isConfirmTemplateDeleteModalOpened = (state: IRootState): boolean =>
  state.auditBuilder.showConfirmTemplateDeleteModal;

const isCreateDraftCopyModalOpened = (state: IRootState): boolean =>
  state.auditBuilder.showCreateDraftCopyModal;

const getActionTemplatesItemsQty = createSelector(
  getItemsDictionary,
  (items) => {
    if (!items) {
      return [];
    }

    const actionTemplatesItemsQty: Record<
      string,
      { actionTemplateId: string; itemsQty: number }
    > = {};

    for (const itemId in items) {
      const item = items[itemId];

      if (isTemplateQuestionItem(item) && item.actionTemplates.length > 0) {
        for (let i = 0; i < item.actionTemplates.length; i++) {
          const actionTemplateId = item.actionTemplates[i].id;

          if (!actionTemplatesItemsQty[actionTemplateId]) {
            actionTemplatesItemsQty[actionTemplateId] = {
              actionTemplateId,
              itemsQty: 0,
            };
          }

          actionTemplatesItemsQty[actionTemplateId].itemsQty++;
        }
      }
    }

    return Object.values(actionTemplatesItemsQty);
  }
);

const getItemsByActionTemplateGroupBySection = createSelector(
  [
    (state: IRootState): string | null =>
      state.auditBuilder.itemsByActionTemplateModalTemplateId,
    getItemsDictionary,
    getRootItemId,
  ],
  (itemsByActionTemplateModalTemplateId, items, rootItemId) => {
    if (!itemsByActionTemplateModalTemplateId || !items || !rootItemId) {
      return null;
    }

    const itemsWithActionTemplate = Object.values(items)
      .filter((item) => isTemplateQuestionItem(item))
      .filter((item) =>
        item.actionTemplates.some(
          (actionTemplate) =>
            actionTemplate.id === itemsByActionTemplateModalTemplateId
        )
      );

    const sectionsMap: Record<
      string,
      {
        section: {
          id: string;
          text: string;
        };
        questions: TemplateQuestionItem[];
      }
    > = {};

    const questionsArr: TemplateQuestionItem[] = [];

    for (let i = 0; i < itemsWithActionTemplate.length; i++) {
      const { section } = getAncestorsSections(
        items,
        itemsWithActionTemplate[i].id,
        rootItemId
      );

      if (!section) {
        questionsArr.push(itemsWithActionTemplate[i]);

        continue;
      }

      if (!sectionsMap[section.id]) {
        sectionsMap[section.id] = {
          section: {
            id: section.id,
            text: section.text,
          },
          questions: [],
        };
      }

      sectionsMap[section.id].questions.push(itemsWithActionTemplate[i]);
    }

    return {
      itemsQty: itemsWithActionTemplate.length,
      sections: Object.values(sectionsMap),
      items: questionsArr,
      actionTemplateId: itemsByActionTemplateModalTemplateId,
    };
  }
);

const getAccentColor = createSelector(
  [(state: IRootState) => state.account.company, getTemplateDetails],
  (company, template) =>
    template?.pdfSettings.accentColor || company?.accentColor || colors.blue2
);

const isPublished = createSelector(
  getTemplateDetails,
  (template) => !!template && !template.isDraft
);

const isGalleryUsageAllowed = (state: IRootState): boolean =>
  !state.auditBuilder.template?.restrictGalleryUsage;

const getEditQuestionModalState = createSelector(
  [
    getItemsDictionary,
    getRootItemId,
    (state: IRootState): EditQuestionModalState =>
      state.auditBuilder.editQuestionModal,
    getQuestionSet,
  ],
  (items, rootItemId, { itemId }) => {
    if (
      !items ||
      !rootItemId ||
      !itemId ||
      !isTemplateQuestionItem(items[itemId])
    ) {
      return null;
    }

    const question = items[itemId];
    const questionSetParent = getQuestionSetParentIfExists(items, question);
    const isConditionChild =
      items[question.parentId].itemType === ItemType.Condition;

    if (questionSetParent) {
      return {
        question,
        modalTitle: questionSetParent.text,
        isQuestionSetChild: true,
        isConditionChild,
      };
    }

    const { subSection, section } = getAncestorsSections(
      items,
      itemId,
      rootItemId
    );

    return {
      question,
      modalTitle: subSection?.text || section?.text || null,
      isQuestionSetChild: false,
      isConditionChild,
    };
  }
);

const getAnswerTypes = (state: IRootState): AnswerTypeDataMapping | null =>
  state.auditBuilder.answerTypes.data;

const getAnswerTypesArray = (state: IRootState): AnswerTypeData[] =>
  state.auditBuilder.answerTypes.dataArr;

const getAddQuestionSetModalTargetPlace = (
  state: IRootState
): AddQuestionSetModalTargetPlace | null =>
  state.auditBuilder.addQuestionSetModal.targetPlace;

const getQuestionSetsSearchTerm = (state: IRootState): string =>
  state.auditBuilder.addQuestionSetModal.search;

const getQuestionSets = createSelector(
  [
    (
      state: IRootState
    ): { loading: boolean; data: QuestionSet[]; error: string | null } =>
      state.auditBuilder.addQuestionSetModal.questionSets,
    getQuestionSetsSearchTerm,
  ],
  ({ loading, data, error }, search) => ({
    loading,
    data: data.filter(({ name }) =>
      name.toLowerCase().includes(search.trim().toLowerCase())
    ),
    error,
  })
);

const getQuestionSetPreview = (
  state: IRootState
): {
  loading: boolean;
  data: {
    rootItemId: string;
    questionSetDetails: QuestionSetDetails;
  } | null;
  error: string | null;
} => state.auditBuilder.addQuestionSetModal.questionSetPreview;

const getQuestionSetPreviewItems = (
  state: IRootState
): Record<string, TemplateItem> | null =>
  state.auditBuilder.addQuestionSetModal.questionSetPreview.data
    .questionSetDetails?.data || null;

const getSelectedQuestionSetId = (state: IRootState): string | null =>
  state.auditBuilder.addQuestionSetModal.selectedQuestionSetId;

const isNewQuestionSet = createSelector(
  getQuestionSet,
  (questionSet) => questionSet?.id === ZERO_UUID
);

const getExpandedQuestionSetId = (state: IRootState): string | null =>
  state.auditBuilder.expandedQuestionSetId;

export const auditBuilderSelectors = {
  getTemplateDetails,
  getRootItemId,
  isOpened,
  getPage,
  isLoading,
  hasUnsavedChanges,
  getValidationStatus,
  getScrollElement,
  makeGetTemplatePreviewItemTree,
  makeGetStatsById,
  makeGetTemplateItemPreviewById,
  getTemplatePreviewTotals,
  getItemsDictionary,
  getRootItem,
  getItemsCount,
  makeIsNADisabled,
  getOpenedSectionId,
  isInformationPhotosUploading,
  isSectionExpanded,
  getTemplateSections,
  getSectionVisibilityModalData,
  getSaveChangesModalState,
  isPublishChangesModalOpened,
  isConfirmTemplateDeleteModalOpened,
  getActionTemplatesItemsQty,
  getItemsByActionTemplateGroupBySection,
  getAccentColor,
  getConfirmDeleteItem,
  isPublished,
  isCreateDraftCopyModalOpened,
  isGalleryUsageAllowed,
  getQuestionSet,
  getEditQuestionModalState,
  getOpenedSection,
  getAnswerTypes,
  getAnswerTypesArray,
  getAddQuestionSetModalTargetPlace,
  getQuestionSets,
  getQuestionSetPreview,
  getQuestionSetPreviewItems,
  getSelectedQuestionSetId,
  getQuestionSetsSearchTerm,
  isNewQuestionSet,
  getExpandedQuestionSetId,
};
