import { BaseApiClient } from '@repo/shared/api';
import { transformTemplateDetailsToDto } from '@infrastructure/AuditBuilder/transformers/transformTemplateDetailsToDto';
import { AuditTemplateDetailsDto } from '@infrastructure/AuditBuilder/models/AuditTemplateDetailsDto';
import { transformDtoToTemplateDetails } from '@infrastructure/AuditBuilder/transformers/transformDtoToTemplateDetails';
import { AuditTemplateDetails } from '@domain/AuditTemplates/AuditTemplateDetails';

class TemplateBuilderApiClient extends BaseApiClient {
  public async getTemplateDetails(templateId: string, draft?: boolean) {
    const template = await this._apiService.get<AuditTemplateDetailsDto>({
      url: draft
        ? `audit/templates/draft/${templateId}`
        : `audit/templates/${templateId}`,
    });

    return transformDtoToTemplateDetails(template);
  }

  public async createDraft(template: AuditTemplateDetails): Promise<string> {
    const { id: newId } = await this._apiService.post<{ id: string }>({
      url: `audit/templates/draft`,
      body: transformTemplateDetailsToDto(template),
    });

    return newId;
  }

  public async saveAsDraft(publishedTemplateId: string): Promise<string> {
    const { id } = await this._apiService.post<{ id: string }>({
      url: `audit/templates/${publishedTemplateId}/save-as-draft`,
    });

    return id;
  }

  public async saveTemplate(template: AuditTemplateDetails): Promise<void> {
    await this._apiService.put({
      url: template.isDraft
        ? `audit/templates/draft/${template.id}`
        : `audit/templates/${template.id}`,
      body: transformTemplateDetailsToDto(template),
    });
  }

  public async publishDraft(templateId: string) {
    const { id } = await this._apiService.post<{ id: string }>({
      url: `audit/templates/draft/${templateId}/apply`,
    });

    return id;
  }
}

export default TemplateBuilderApiClient;
