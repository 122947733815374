import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@src/core/frameworks/redux';
import { IPagingMeta, ITableFilters } from '@repo/shared/types';
import { TransferTaskType } from '@domain/TransferTasks/TransferTaskType';
import {
  importTasksAdapter,
  exportTasksAdapter,
} from '@application/TransferTasks/store/entityAdapters';
import { ImportRecord } from '@domain/TransferTasks/ImportRecord';
import { convertImportRecordsToSummary } from '@application/TransferTasks/utils/convertImportRecordsToSummary';
import { ImportTaskDetailsState } from '@application/TransferTasks/models/ImportTaskDetailsState';

const getType = (state: IRootState): TransferTaskType | null =>
  state.transferTasks.type;

const getTableFilters = (state: IRootState): ITableFilters =>
  state.transferTasks.filters;

const getLoading = (state: IRootState): boolean =>
  state.transferTasks.loading;

const getTableMeta = (state: IRootState): IPagingMeta | null =>
  state.transferTasks.meta;

const getImportTasks = (state: IRootState) =>
  importTasksAdapter.getSelectors().selectAll(state.transferTasks.importTasks);

const getExportTasks = (state: IRootState) =>
  exportTasksAdapter.getSelectors().selectAll(state.transferTasks.exportTasks);

const getError = (state: IRootState): string | null =>
  state.transferTasks.error;

const isCreateExportTaskModalOpened = (state: IRootState): boolean =>
  state.transferTasks.showCreateExportTaskModal;

const isCreateImportModalOpened = (state: IRootState): boolean =>
  state.transferTasks.createImportTaskModal.opened;

const getCreateImportTaskModalValidation = createSelector(
  (
    state: IRootState
  ): {
    loading: boolean;
    error: string | null;
    data: ImportRecord[] | null;
  } => state.transferTasks.createImportTaskModal.validation,
  ({ data, loading, error }) => ({
    loading,
    error,
    data: convertImportRecordsToSummary(data || []),
  })
);

const getImportTaskDetailsModalState = createSelector(
  (state: IRootState): ImportTaskDetailsState =>
    state.transferTasks.importTaskDetailsModal,
  ({ id, data, loading, error }) => ({
    id,
    data: convertImportRecordsToSummary(data?.records || []),
    loading,
    error,
  })
);

export const transferTasksSelectors = {
  getType,
  getTableFilters,
  getLoading,
  getTableMeta,
  getImportTasks,
  getExportTasks,
  getError,
  isCreateExportTaskModalOpened,
  isCreateImportModalOpened,
  getCreateImportTaskModalValidation,
  getImportTaskDetailsModalState,
};
