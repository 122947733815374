import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 64px;
`;

export const Content = styled.div`
  background: ${colors.white};
  padding: 20px 20px 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.blue7};
  padding: 15px 20px 10px;
`;
