import React from 'react';
import { FileMetadata } from '@repo/shared/types';

import { FileSourceType, MimeType } from '@repo/shared/enums';
import { Error, UploadFilesContainer } from './styled';
import { FormInstance } from 'antd/es/form';

import UploadFiles from '@components/shared/UploadFiles/UploadFiles';

interface IProps {
  onChange?: (value: FileMetadata[]) => void;
  value?: FileMetadata[];
  form: FormInstance;
}

const UploadFilesFormItem: React.FC<React.PropsWithChildren<IProps>> = ({
  onChange,
  value,
  form,
}) => {
  const errors = form.getFieldError('files');

  return (
    <UploadFilesContainer>
      <UploadFiles
        onUploadFinished={(files) => {
          if (onChange) {
            onChange([...(form.getFieldValue('files') || []), ...files]);
          }
        }}
        onRemoveFile={(fileId) => {
          if (onChange && value) {
            onChange([
              ...(form.getFieldValue('files') || []).filter(
                (file: FileMetadata) => file.id !== fileId
              ),
            ]);
          }
        }}
        source={FileSourceType.TemplateInformationFile}
        allowedMimeTypes={[
          MimeType.pdf,
          MimeType.doc,
          MimeType.docx,
          MimeType.xls,
          MimeType.xlsx,
        ]}
        uploadLimit={5}
        hasError={errors.length > 0}
      />
      {errors.length > 0 && <Error>{errors.join(', ')}</Error>}
    </UploadFilesContainer>
  );
};

export default UploadFilesFormItem;
