import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import mime from 'mime';
import uniq from 'lodash/uniq';

import {
  Modal,
  FileSourceType,
  UploadStatus,
  AssigneesModalContext,
  FeedbackModalPage,
  MimeType,
  FileOrigin,
  ConciseListType,
} from '@repo/shared/enums';
import {
  IAnyObject,
  IConcise,
  IRoleConcise,
  ITagConcise,
  ITimeZone,
  ISharedFilters,
  IUserConcise,
  IFileUpload,
  IAuditObjectAttribute,
  IParticipant,
  IRating,
  IFeedback,
  FileMetadata,
  TemplateConcise,
} from '@repo/shared/types';
import { apiUrls } from '@config';
import { config } from '@repo/shared/config';
import { Logger } from '@repo/shared/services';
import { createGetEntitiesThunk } from '@utils';
import { delay } from '@repo/shared/utils';
import { getErrorMessage } from '@repo/shared/utils';
import { intl } from '@repo/shared/components/IntlGlobalProvider';
import { generalSelectors } from '@store';
import { IRootState } from '@src/core/frameworks/redux';
import { ApiConflictError, ValidationError } from '@repo/shared/errors';
import { InternalApiService } from '@repo/shared/api';
import { schedulesActions } from '@application/Schedules/store/schedulesActions';
import { schedulePlansActions } from '@application/SchedulePlans/store/schedulePlansActions';

const apiService = InternalApiService.getInstance();

const getConciseTemplates = createGetEntitiesThunk<
  TemplateConcise,
  null,
  TemplateConcise[]
>({
  apiUrl: `${apiUrls.auditTemplates}/concise`,
  entityName: 'auditTemplatesConcise',
});
const getConciseUsers = createGetEntitiesThunk<
  IUserConcise,
  null,
  IUserConcise[]
>({
  apiUrl: `${apiUrls.users}/concise`,
  entityName: 'usersConcise',
});
const getConciseUserGroups = createGetEntitiesThunk<IConcise, null, IConcise[]>(
  {
    apiUrl: `${apiUrls.userGroups}/concise`,
    entityName: 'userGroupsConcise',
  }
);
const getConciseAuditObjectAttributes = createGetEntitiesThunk<
  IAuditObjectAttribute,
  null,
  IAuditObjectAttribute[]
>({
  apiUrl: `${apiUrls.auditObjects}/attributes/all`,
  entityName: 'auditObjectsAttributesConcise',
});
const getConciseActionTemplates = createGetEntitiesThunk<
  IConcise,
  null,
  IConcise[]
>({
  apiUrl: `${apiUrls.actionTemplates}/concise`,
  entityName: 'actionTemplatesConcise',
});
const getConciseAuditObjects = createGetEntitiesThunk<
  IConcise,
  null,
  IConcise[]
>({
  apiUrl: `${apiUrls.auditObjects}/concise`,
  entityName: 'auditObjectsConcise',
});
const getConciseJobTitles = createGetEntitiesThunk<IConcise, null, IConcise[]>({
  apiUrl: `${apiUrls.jobTitles}/concise`,
  entityName: 'jobTitlesConcise',
});
const getConciseAccessibleAuditObjects = createGetEntitiesThunk<
  IConcise,
  null,
  IConcise[]
>({
  apiUrl: `${apiUrls.auditObjects}/accessible/concise`,
  entityName: 'accessibleAuditObjectsConcise',
});
const getConciseAuditObjectGroups = createGetEntitiesThunk<
  IConcise,
  null,
  IConcise[]
>({
  apiUrl: `${apiUrls.auditObjectsGroups}/concise`,
  entityName: 'auditObjectGroupsConcise',
});
const getConciseTags = createGetEntitiesThunk<ITagConcise, null, ITagConcise[]>(
  {
    apiUrl: `${apiUrls.tags}/concise`,
    entityName: 'tagsConcise',
  }
);
const getConciseRoles = createGetEntitiesThunk<
  IRoleConcise,
  null,
  IRoleConcise[]
>({
  apiUrl: `${apiUrls.roles}/concise`,
  entityName: 'rolesConcise',
});
const getConciseDataByType = createAsyncThunk<void, ConciseListType[]>(
  'shared/getConciseDataByType',
  async (conciseTypes, { dispatch }) => {
    const typesToActionsMap: Record<ConciseListType, any> = {
      [ConciseListType.Users]: getConciseUsers,
      [ConciseListType.AuditObjects]: getConciseAuditObjects,
      [ConciseListType.Attributes]: getConciseAuditObjectAttributes,
      [ConciseListType.Roles]: getConciseRoles,
    };

    conciseTypes.forEach((type) => dispatch(typesToActionsMap[type]()));
  }
);
const setUploadProgress = createAction<{ id: string; progress: number }>(
  'general/setUploadProgress'
);
const getTimeZones = createAsyncThunk<
  ITimeZone[],
  undefined,
  { rejectValue: string }
>('account/getTimeZones', async (_, { rejectWithValue }) => {
  try {
    return apiService.get({
      url: 'appinfo/timezones',
      skipCompanyId: true,
    });
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});
const startUpload = createAction<string>('general/startUpload');
const uploadFile = createAsyncThunk<
  { fileId: string; response: IAnyObject },
  {
    file: FileMetadata;
    getUploadPath?: (fileId: string) => string;
    source?: FileSourceType;
    allowedMimeTypes?: MimeType[];
    skipCompanyId?: boolean;
    skipStoreUpdate?: boolean;
  },
  {
    rejectValue: {
      id: string;
      errorText: string;
      errorCode?: number;
      error?: string;
    };
  }
>(
  'general/uploadFile',
  async (
    {
      file,
      getUploadPath = (fileId: string) => `${apiUrls.files}/${fileId}`,
      source: fileSourceType,
      allowedMimeTypes,
      skipCompanyId,
    },
    { rejectWithValue, dispatch, signal, getState }
  ) => {
    try {
      if (!file.file) {
        throw new Error(
          'uploadFile action: file property is required for uploading'
        );
      }

      if (
        Array.isArray(allowedMimeTypes) &&
        !allowedMimeTypes.includes(file.file.type as MimeType)
      ) {
        throw new ValidationError(
          `${intl.formatMessage({
            id: 'IncorrectFileType',
          })}${
            Array.isArray(allowedMimeTypes) && allowedMimeTypes.length > 0
              ? ` ${intl.formatMessage(
                  { id: 'SupportedFileTypes' },
                  {
                    fileTypes: uniq(
                      allowedMimeTypes
                        .map((mimeType) => {
                          const ext = mime.getExtension(mimeType);
                          return ext === 'qt' ? 'mov' : ext;
                        })
                        .filter((ext) => !!ext)
                    )
                      .join(', ')
                      .toUpperCase(),
                  }
                )}`
              : ''
          }`
        );
      }

      if (Math.floor(file.file.size / 1000000) > config.maxUploadFileSizeMb) {
        throw new ValidationError(
          ` ${intl.formatMessage(
            { id: 'FileSizeExceededTheAllowedMaximumOfXMb' },
            {
              fileSize: config.maxUploadFileSizeMb,
            }
          )}`
        );
      }

      const source = apiService.getCancelTokenSource();

      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const activeUploadsQtyExceedsMaxAllowed = () => {
        const uploads = generalSelectors.getFilesUploads(getState());

        const activeOrPendingUploads = Object.values<IFileUpload>(
          uploads
        ).filter(({ status }) => status === UploadStatus.Uploading);

        return activeOrPendingUploads.length >= config.maxConcurrentUploadsQty;
      };

      while (activeUploadsQtyExceedsMaxAllowed()) {
        await delay(100);
      }

      dispatch(startUpload(file.id));

      const formData = new FormData();

      formData.append('file', file.file);

      const response = await apiService.post<IAnyObject>({
        url: getUploadPath(file.id),
        host: apiService.getFileApiHost(),
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        body: formData,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;

          dispatch(
            setUploadProgress({
              id: file.id,
              progress: Math.floor((loaded * 100) / total),
            })
          );
        },
        cancelToken: source.token,
        query: {
          source: fileSourceType,
        },
        requestId: file.id,
        skipCompanyId,
      });

      if (!generalSelectors.getFilesUploads(getState())[file.id]) {
        throw new Error('upload-cancelled');
      }

      return {
        fileId: file.id,
        response,
      };
    } catch (e: unknown) {
      if (
        !(e instanceof ValidationError) &&
        !(e instanceof ApiConflictError) &&
        !(e instanceof Error && e.message === 'upload-cancelled')
      ) {
        Logger.captureException(e);
      }

      return rejectWithValue({
        errorText:
          e instanceof ValidationError ? e.message : getErrorMessage(e),
        id: file.id,
        ...(e instanceof ApiConflictError
          ? {
              errorCode: 409,
              error: e.message,
            }
          : {}),
      });
    }
  }
);

export const generalActions = {
  showModal: createAction<{ name: Modal; data?: IAnyObject | null }>(
    'general/showModal'
  ),
  hideModal: createAsyncThunk<Modal, Modal, { rejectValue: string }>(
    'general/hideModal',
    async (modal) => {
      // wait until closing modal animation if finished, then reset modal data in configureStore
      await delay(250);
      return modal;
    }
  ),
  selectTableRows: createAction<string[]>('general/selectTableRows'),
  getConciseData: createAsyncThunk(
    'general/getConciseData',
    async (_, { dispatch }) => {
      dispatch(getConciseUsers());
      dispatch(getConciseUserGroups());
      dispatch(getConciseAuditObjects());
      dispatch(getConciseAuditObjectGroups());
      dispatch(getConciseTemplates());
      dispatch(getConciseTags());
      dispatch(getConciseRoles());
      dispatch(getConciseAccessibleAuditObjects());
      dispatch(getTimeZones());
      dispatch(getConciseJobTitles());
      dispatch(getConciseAuditObjectAttributes());
      dispatch(schedulesActions.getConciseSchedules());
      dispatch(schedulePlansActions.getConciseSchedulePlans());
    }
  ),
  setUploadProgress,
  startUpload,
  getConciseUsers,
  getConciseUserGroups,
  getConciseAuditObjects,
  getConciseAuditObjectGroups,
  getConciseTemplates,
  getConciseTags,
  getConciseRoles,
  getConciseAccessibleAuditObjects,
  getConciseActionTemplates,
  getConciseAuditObjectAttributes,
  getConciseJobTitles,
  signOut: createAction('general/signOut'),
  resetAccountData: createAction('general/resetAccountData'),
  getTimeZones,
  setSharedFilters: createAction<Partial<ISharedFilters>>(
    'general/setSharedFilters'
  ),
  registerFileUploads: createAction<FileMetadata[]>(
    'general/registerFileUploads'
  ),
  resetFileUploads: createAction('general/resetFileUploads'),
  uploadFile,
  copyAuditFile: createAsyncThunk<
    FileMetadata,
    { sourceId: string; sourceType: FileSourceType; auditId: string },
    { rejectValue: string }
  >(
    'general/copyAuditFile',
    async ({ sourceId, sourceType, auditId }, { rejectWithValue }) => {
      try {
        const { id } = await apiService.post<{ id: string }>({
          host: apiService.getFileApiHost(),
          url: `audit/${auditId}/stream/file/${sourceId}/copy`,
          query: {
            source: sourceType,
          },
        });

        return {
          ...(await apiService.get<FileMetadata>({
            host: apiService.getFileApiHost(),
            url: `files/${id}/metadata`,
          })),
          id,
        };
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  deleteFileUpload: createAction<string>('general/deleteFileUpload'),
  deleteFile: createAsyncThunk<
    string,
    string,
    { rejectValue: { id: string; error: string } }
  >('general/deleteFile', async (id, { rejectWithValue }) => {
    try {
      await apiService.delete({
        host: apiService.getFileApiHost(),
        url: `${apiUrls.files}/${id}`,
      });

      return id;
    } catch (e) {
      return rejectWithValue({ error: getErrorMessage(e), id });
    }
  }),
  assigneesModal: {
    toggle: createAction<boolean>('general/assigneesModal/toggle'),
    getParticipants: createAsyncThunk<
      IParticipant[],
      string,
      { rejectValue: string; state: IRootState }
    >(
      'general/assigneesModal/getParticipants',
      async (auditObjectId, { rejectWithValue }) => {
        try {
          return apiService.get({
            url: `${apiUrls.auditObjects}/${auditObjectId}/participants`,
          });
        } catch (e) {
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    setContext: createAction<AssigneesModalContext | null>(
      'general/assigneesModal/setContext'
    ),
  },
  feedbackModal: {
    setPage: createAction<FeedbackModalPage | null>(
      'account/feedbackModal/setPage'
    ),
    setRating: createAction<IRating>('account/feedbackModal/setRating'),
    saveFeedback: createAsyncThunk<void, IFeedback, { state: IRootState }>(
      'account/feedbackModal/saveFeedback',
      async (feedback, { rejectWithValue }) => {
        try {
          await apiService.post({
            url: 'review/save-feedback',
            body: {
              ...feedback,
            },
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
  },
  toggleMobileMenu: createAction<boolean>('general/toggleMobileMenu'),
  getConciseDataByType,
};
