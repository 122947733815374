import React from 'react';

import { Container } from './styled';

import OnboardingAssistantChat from '@presentation/Onboarding/OnboardingModal/OnboardingAssistant/OnboardingAssistantChat/OnboardingAssistantChat';
import OnboardingAssistantProgress from '@presentation/Onboarding/OnboardingModal/OnboardingAssistant/OnboardingAssistantProgress/OnboardingAssistantProgress';

interface IProps {}

const OnboardingAssistant: React.FC<React.PropsWithChildren<IProps>> = () => {
  return (
    <Container>
      <OnboardingAssistantProgress />
      <OnboardingAssistantChat />
    </Container>
  );
};

export default OnboardingAssistant;
