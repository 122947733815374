import React from 'react';

import { UsagePurpose } from '@repo/shared/enums';
import { enumToSelectOptions } from '@utils';
import { useTranslatedOptions } from '@utils';

import ChatButtonsSelect from '@presentation/Onboarding/OnboardingModal/OnboardingAssistant/OnboardingAssistantChat/OnboardingAssistantChatMessages/CurrentStepAddons/ChatButtonsSelect/ChatButtonsSelect';

const options = enumToSelectOptions<UsagePurpose>(UsagePurpose);

interface IProps {}

const UsagePurposeChatButtons: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const buttons = useTranslatedOptions(options).map(({ label }) => label);

  return <ChatButtonsSelect buttons={buttons} />;
};

export default UsagePurposeChatButtons;
