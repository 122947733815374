import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  & > div {
    background: ${colors.gray3};
    padding: 24px;
    border-radius: 20px;
    width: 445px;
  }
`;
