export enum ImportRecordType {
  Success = 0,
  Warning = 1,
  Error = 2,
}

export enum ImportRecordCode {
  CsvFileError = 0,
  CsvLineParsingError = 1,
  ValidationKeyNotSet = 2,
  ValidationNameNotSet = 3,
  ItemDuplicated = 4,
  ItemUpdate = 5,
  ItemCreate = 6,
  ItemDelete = 7,
  ItemSaveError = 8,
  AuditObjectAttributeAssigned = 9,
  AuditObjectAttributeError = 10,
  AuditObjectGroupAssigned = 11,
  ItemDeleteError = 12,
  ValidationUserRoleNotSet = 13,
  ValidationUserRoleNotExists = 14,
  AuditObjectNotExists = 15,
  ValidationUserNotExists = 16,
  ValidationTransferToUserNotExist = 17,
  CsvLineMissingFields = 18,
}

export type ImportRecord = {
  id: string;
  type: ImportRecordType;
  code: ImportRecordCode;
  value: string;
};
