import { AnswerType, ItemType } from '@repo/shared/enums';
import {
  IConcise,
  ICondition,
  IConditionCompare,
  IInformation,
  ITagConcise,
} from '@repo/shared/types';
import { AnswerTypeData } from '@domain/AuditTemplates/AnswerTypeData';
import { SectionVisibility } from '@domain/AuditTemplates/SectionVisibility';

interface TemplateItemBase {
  id: string;
  childrenIds: string[];
  index: number;
  parentId: string;
}

export interface TemplateRootItem extends Omit<TemplateItemBase, 'parentId'> {
  itemType: ItemType.Root;
  parentId: null;
  disableNotApplicable: boolean;
}

export interface TemplateSectionItem extends TemplateItemBase {
  itemType: ItemType.Section;
  text: string;
  visibility: SectionVisibility | null;
  disableNotApplicable: boolean;
}

export interface TemplateQuestionSetItem extends TemplateItemBase {
  itemType: ItemType.QuestionSet;
  text: string;
  questionSetId: string;
}

export interface TemplateConditionItem extends TemplateItemBase {
  itemType: ItemType.Condition;
  questionSetItemId: string | null;
  condition: IConditionCompare;
}

export interface TemplateQuestionBase extends TemplateItemBase {
  text: string;
  isCritical: boolean;
  hasInformation: boolean;
  information: IInformation | null;
  itemTags: ITagConcise[];
  isSignatureRequired: boolean;
  disableNotApplicable: boolean;
  actionTemplates: IConcise[];
  questionSetItemId: string | null;
  conditions: ICondition[];
}

export type TemplateSimpleQuestionItem = {
  itemType: ItemType.Item;
} & TemplateQuestionBase &
  AnswerTypeData;

export type TemplateConditionalQuestionItem = {
  itemType: ItemType.ConditionalItem;
} & TemplateQuestionBase &
  AnswerTypeData;

export type TemplateQuestionItem =
  | TemplateSimpleQuestionItem
  | TemplateConditionalQuestionItem;

export type TemplateItem =
  | TemplateRootItem
  | TemplateSectionItem
  | TemplateQuestionItem
  | TemplateConditionItem
  | TemplateQuestionSetItem;

export function isTemplateQuestionItem(
  item: TemplateItem
): item is TemplateQuestionItem {
  return (
    item.itemType === ItemType.Item ||
    item.itemType === ItemType.ConditionalItem
  );
}

export function isTemplateSimpleQuestionItem(
  item: TemplateItem
): item is TemplateSimpleQuestionItem {
  return item.itemType === ItemType.Item;
}

export function isTemplateConditionalQuestionItem(
  item: TemplateItem
): item is TemplateConditionalQuestionItem {
  return item.itemType === ItemType.ConditionalItem;
}

export function isTemplateConditionItem(
  item: TemplateItem
): item is TemplateConditionItem {
  return item.itemType === ItemType.Condition;
}

export function isTemplateSectionItem(
  item: TemplateItem
): item is TemplateSectionItem {
  return item.itemType === ItemType.Section;
}

export function isQuestionSetItem(
  item: TemplateItem
): item is TemplateQuestionSetItem {
  return item.itemType === ItemType.QuestionSet;
}

export function isRootItem(item: TemplateItem): item is TemplateRootItem {
  return item.itemType === ItemType.Root;
}

export function getQuestionSetParentIfExists(
  items: Record<string, TemplateItem>,
  item: TemplateItem
): TemplateQuestionSetItem | null {
  let parentId = item.parentId;

  while (parentId) {
    const parent = items[parentId];

    if (parent && parent.itemType === ItemType.QuestionSet) {
      return parent;
    }

    parentId = parent.parentId;
  }

  return null;
}

export function isButtonsAnswerType(item: TemplateItem) {
  return (
    (item.itemType === ItemType.Item ||
      item.itemType === ItemType.ConditionalItem) &&
    (item.answerType === AnswerType.YesNoButtons ||
      item.answerType === AnswerType.PassFailButtons)
  );
}
