import styled from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  margin-right: -90px;

  &:before {
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: calc(100% - 90px);
    content: '';
    height: 30px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;

export const Content = styled.div`
  margin-right: 90px;
  padding-top: 6px;
  padding-bottom: 20px;
`;
