import styled from 'styled-components';

import logoIcon from './logo-icon.svg';
import { colors, BREAKPOINTS } from '@repo/shared/config';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: ${colors.white};
  padding: 11px 0;
  background: linear-gradient(83.16deg, #238dc1, #54c0e8);

  &:hover {
    color: ${colors.white};
  }

  & > * {
    margin: 0 8px;
  }
`;

export const Icon = styled.span`
  display: block;
  width: 21px;
  height: 21px;
  background: ${colors.gray2};
  margin-right: 10px;
  border-radius: 4px;

  &:after {
    display: block;
    content: '';
    width: 21px;
    height: 21px;
    background: url(${logoIcon}) no-repeat center center;
  }
`;

export const Text = styled.span`
  font-size: 13px;
  text-align: center;
`;

export const SignUpLink = styled.a`
  display: block;
  background: ${colors.white};
  border-radius: 10px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${colors.blue3};
  padding: 5px 8px;
  border: 0;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
`;

export const CloseButton = styled.button`
  width: 20px;
  height: 20px;
  padding: 0;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
    color: ${colors.white};
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
