import { AuthService } from '../auth';
import { BaseApiService } from './BaseApiService';
import { AuthenticationError } from '../errors';
import {
  HttpMethods,
  IApiService,
  RequestApiParams,
  RequestFileParams,
} from '../types';

export class InternalApiService extends BaseApiService implements IApiService {
  private static _apiHost = import.meta.env.VITE_API_BASE_URL!;
  private static _fileApiHost = import.meta.env.VITE_FILES_API_BASE_URL!;

  private _companyId?: string;
  private static _instance: InternalApiService | null = null;

  private constructor() {
    super();
  }

  public static getInstance(): InternalApiService {
    if (!InternalApiService._instance) {
      InternalApiService._instance = new InternalApiService();
    }
    return InternalApiService._instance;
  }

  public static configure(companyId: string): void {
    const instance = InternalApiService.getInstance();
    instance._companyId = companyId;
  }

  public resetCompanyId() {
    delete this._companyId;
  }

  async post<T>(params: RequestApiParams): Promise<T> {
    return this._makeInternalApiRequest({
      ...params,
      method: HttpMethods.post,
    });
  }

  async get<T>(params: RequestApiParams): Promise<T> {
    return this._makeInternalApiRequest({
      ...params,
      method: HttpMethods.get,
    });
  }

  async put<T>(params: RequestApiParams): Promise<T> {
    return this._makeInternalApiRequest({
      ...params,
      method: HttpMethods.put,
    });
  }

  async delete<T>(params: RequestApiParams): Promise<T> {
    return this._makeInternalApiRequest({
      ...params,
      method: HttpMethods.delete,
    });
  }

  async getFile<T>({
    id,
    path,
    companyId,
    skipCompanyId,
    responseType,
    cacheResponse,
    withWatermark,
  }: RequestFileParams): Promise<T> {
    const token = await AuthService.getAuthToken();

    return this._makeRequest({
      responseType,
      method: HttpMethods.get,
      url: this._constructUrl({
        host: InternalApiService._fileApiHost,
        path: path || `files/${id}`,
        companyId: skipCompanyId ? undefined : companyId || this._companyId,
      }),
      query: withWatermark ? { withWatermark: true } : undefined,
      token,
      cacheResponse: cacheResponse,
      requestId: path,
    });
  }

  private async _makeInternalApiRequest({
    companyId,
    skipCompanyId,
    url,
    host,
    onAuthError,
    ...params
  }: RequestApiParams) {
    const token = await AuthService.getAuthToken();

    try {
      return await this._makeRequest({
        ...params,
        url: this._constructUrl({
          host: host || InternalApiService._apiHost,
          path: url,
          companyId: companyId || this._companyId,
          skipCompanyId,
        }),
        token,
      });
    } catch (e) {
      if (e instanceof AuthenticationError && onAuthError) {
        onAuthError();
      }

      throw e;
    }
  }

  getApiHost(): string {
    return InternalApiService._apiHost;
  }

  getFileApiHost(): string {
    return InternalApiService._fileApiHost;
  }

  getCurrentCompanyId() {
    return this._companyId;
  }

  async getAuthHeaders() {
    const token = await AuthService.getAuthToken();

    if (!token) {
      return {} as Record<string, string>;
    }

    return {
      Authorization: `Bearer ${token}`,
    };
  }
}
