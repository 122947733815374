import { ItemType } from '@repo/shared/enums';
import { TemplateItem } from '@domain/AuditTemplates/TemplateItem';

export function checkIsParentNADisabled(
  items: Record<string, TemplateItem>,
  itemId: string,
  includeItem?: boolean
): boolean {
  let id = includeItem ? itemId : items[itemId].parentId;

  while (id) {
    const item = items[id];

    if (
      item.itemType !== ItemType.Root &&
      item.itemType !== ItemType.Condition &&
      item.itemType !== ItemType.QuestionSet &&
      item.disableNotApplicable
    ) {
      return true;
    }

    id = items[id].parentId;
  }

  return false;
}
