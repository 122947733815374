import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  AlertTriangle,
  Copy,
  FileText,
  Flag,
  Folder,
  Grid,
  HelpCircle,
  Settings,
  Trello,
  Users,
} from 'react-feather';
import { useSelector } from 'react-redux';
import { Link, matchRoutes, useLocation } from 'react-router-dom';

import { routes } from '@config';
import { useAppDispatch, usePermission, usePrevious } from '@hooks';
import { useAppRoutes } from '@src/core/router/useAppRoutes';
import { useLgMediaQuery } from '@repo/shared/hooks';
import {
  AnalyticsEvent,
  Permission,
  RoleType,
  SelfGuidedTourStep,
} from '@repo/shared/enums';
import { accountActions, accountSelectors } from '@store';
import { auditTemplatesSelectors } from '@application/AuditTemplates/store/auditTemplatesSelectors';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { AdditionalSubMenuTitleContent, LinkButton, Menu } from './styled';
import { onboardingActions } from '@application/Onboarding/store/onboardingActions';
import { OnboardingModalMode } from '@application/Onboarding/enums/OnboardingModalMode';
import { config } from '@repo/shared/config';

import TourPopover from '@components/account/SelfGuidedTour/TourPopover/TourPopover';
import HelpCenter from '@components/account/AppLayout/HelpCenter/HelpCenter';

const genericKeys = {
  actions: 'actions',
  audits: 'audits-submenu',
  billing: 'billing',
  auditorPerformanceReport: 'auditorPerformanceReport',
  auditObjectPerformanceReport: 'auditObjectPerformanceReport',
  tags: 'tags',
  users: 'usersGeneric',
  auditObjects: 'auditObjects',
  issues: 'issuesList',
  issueTypes: 'issueTypes',
  advancedSchedules: 'advancedSchedules',
  templateComponents: 'templateComponents',
  tasks: 'tasks',
};

const pathnameToKeyMap = {
  [routes.completedAudits]: genericKeys.audits,
  [routes.completedAudit]: genericKeys.audits,
  [routes.pendingAudits]: genericKeys.audits,
  [routes.pendingAudit]: genericKeys.audits,
  [routes.expiredAudits]: genericKeys.audits,
  [routes.expiredAudit]: genericKeys.audits,
  [routes.myAudits]: genericKeys.audits,
  [routes.completedActions]: genericKeys.actions,
  [routes.completedAction]: genericKeys.actions,
  [routes.pendingActions]: genericKeys.actions,
  [routes.pendingAction]: genericKeys.actions,
  [routes.myActions]: genericKeys.actions,
  [routes.myAction]: genericKeys.actions,
  [routes.expiredActions]: genericKeys.actions,
  [routes.expiredAction]: genericKeys.actions,
  [routes.subscription]: genericKeys.billing,
  [routes.invoices]: genericKeys.billing,
  [routes.auditorPerformanceReportRealTime]:
    genericKeys.auditorPerformanceReport,
  [routes.auditorPerformanceReportHistorical]:
    genericKeys.auditorPerformanceReport,
  [routes.auditObjectPerformanceReportRealTime]:
    genericKeys.auditObjectPerformanceReport,
  [routes.auditObjectPerformanceReportHistorical]:
    genericKeys.auditObjectPerformanceReport,
  [routes.tagsItems]: genericKeys.tags,
  [routes.tagsAudits]: genericKeys.tags,
  [routes.tagsActions]: genericKeys.tags,
  [routes.users.list]: genericKeys.users,
  [routes.users.userDetails]: genericKeys.users,
  [routes.users.userGroups]: genericKeys.users,
  [routes.users.userAccess]: genericKeys.users,
  [routes.auditObjects.list]: genericKeys.auditObjects,
  [routes.auditObjects.auditObjectDetails]: genericKeys.auditObjects,
  [routes.auditObjects.auditObjectAccess]: genericKeys.auditObjects,
  [routes.auditObjects.auditObjectAttributes]: genericKeys.auditObjects,
  [routes.auditObjects.auditObjectGroups]: genericKeys.auditObjects,
  [routes.issueTypes]: genericKeys.issueTypes,
  [routes.issueTypeDetails]: genericKeys.issueTypes,
  [routes.pendingIssues]: genericKeys.issues,
  [routes.pendingIssue]: genericKeys.issues,
  [routes.archiveIssues]: genericKeys.issues,
  [routes.archiveIssue]: genericKeys.issues,
  [routes.myIssues]: genericKeys.issues,
  [routes.myIssue]: genericKeys.issues,
  [routes.advancedSchedules]: genericKeys.advancedSchedules,
  [routes.advancedScheduleDetails]: genericKeys.advancedSchedules,
  [routes.questionSets]: genericKeys.templateComponents,
  [routes.customResponseTypes]: genericKeys.templateComponents,
  [routes.createQuestionSet]: genericKeys.templateComponents,
  [routes.import]: genericKeys.tasks,
  [routes.export]: genericKeys.tasks,
};

const tourOpenedKeys = ['settings', 'library', 'audits', 'getting-started'];

const useCurrentMenuItemKey = (): string => {
  const location = useLocation();
  const routes = useAppRoutes();
  const matchedRoutes = matchRoutes(routes, location);

  if (
    matchedRoutes &&
    matchedRoutes.length > 0 &&
    matchedRoutes[0]?.route.path &&
    pathnameToKeyMap[matchedRoutes[0]?.route.path]
  ) {
    return pathnameToKeyMap[matchedRoutes[0]?.route.path];
  }

  return pathnameToKeyMap[location.pathname] || location.pathname;
};

const useMenuItems = (): any[] => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const [
    canManageBilling,
    canDoAudits,
    canViewAuditsResults,
    canManageTemplates,
    canScheduleAudits,
    canViewCorrectiveActions,
    canViewAnalytics,
    canManageGeneralSetup,
    canDoCorrectiveActions,
    canViewIssues,
    canManageIssueTypes,
  ] = usePermission([
    Permission.CanManageBilling,
    Permission.CanDoAudits,
    Permission.CanViewAuditResults,
    Permission.CanManageAuditTemplates,
    Permission.CanScheduleAudits,
    Permission.CanViewCorrectiveActions,
    Permission.CanViewAnalytics,
    Permission.CanManageGeneralSetup,
    Permission.CanDoCorrectiveActions,
    Permission.CanViewIssues,
    Permission.CanManageIssueTypes,
  ]);
  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const appConfiguration = useSelector(accountSelectors.getAppConfiguration);
  const company = useSelector(accountSelectors.getCompany);
  const { loading: templatesLoading } = useSelector(
    auditTemplatesSelectors.getTemplatesGroups
  );
  const { loading: auditsLoading } = useSelector(auditsSelectors.getAudits);
  const isAdmin = useSelector(accountSelectors.isAdmin);
  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const currentUserRoleType = currentUser?.role?.roleType;
  const isAuditeeOrObserver =
    currentUserRoleType === RoleType.Observer ||
    currentUserRoleType === RoleType.Auditee;
  const isAuditor = currentUserRoleType === RoleType.Auditor;
  const canViewSetupPages = !isAuditeeOrObserver && !isAuditor;
  const canViewAuditsMenu =
    canDoAudits ||
    canViewAuditsResults ||
    canViewCorrectiveActions ||
    canDoCorrectiveActions ||
    canScheduleAudits;
  const showSelfGuidedTour = useLgMediaQuery();

  return [
    {
      key: routes.dashboard,
      icon: <Grid />,
      label: (
        <Link to={routes.dashboard}>{formatMessage({ id: 'Dashboard' })}</Link>
      ),
    },
    ...(canViewAuditsMenu
      ? [
          {
            key: 'audits',
            icon: <Trello />,
            label: <>{formatMessage({ id: 'Audits' })}</>,
            children: [
              ...(canDoAudits || canViewAuditsResults
                ? [
                    {
                      key: genericKeys.audits,
                      className: 'onboarding-popover-holder',
                      label: (
                        <>
                          <TourPopover
                            step={SelfGuidedTourStep.ViewAudits}
                            placement="right"
                            overlayStyle={{ height: 40, width: 160 }}
                            disabledNextBtn={auditsLoading}
                          >
                            <Link
                              to={
                                canDoAudits
                                  ? routes.myAudits
                                  : routes.completedAudits
                              }
                              style={{ display: 'block' }}
                              onClick={() => {
                                dispatch(
                                  auditsActions.toggleViewAuditModal({
                                    opened: false,
                                  })
                                );
                              }}
                            >
                              {formatMessage({ id: 'AllAudits' })}
                            </Link>
                          </TourPopover>
                        </>
                      ),
                    },
                  ]
                : []),
              ...(canViewCorrectiveActions || canDoCorrectiveActions
                ? [
                    {
                      key: genericKeys.actions,
                      label: (
                        <Link to={routes.myActions}>
                          {formatMessage({ id: 'CorrectiveActions' })}
                        </Link>
                      ),
                    },
                  ]
                : []),
              ...(canScheduleAudits
                ? [
                    {
                      key: routes.schedules,
                      className: 'onboarding-popover-holder',
                      label: (
                        <>
                          <TourPopover
                            step={SelfGuidedTourStep.AddSchedule}
                            placement="right"
                            overlayStyle={{
                              height: 40,
                              width: 150,
                            }}
                            scrollTo={0}
                          >
                            <Link
                              to={routes.schedules}
                              style={{ display: 'block' }}
                            >
                              {formatMessage({ id: 'Schedules' })}
                            </Link>
                          </TourPopover>
                        </>
                      ),
                    },
                    {
                      key: genericKeys.advancedSchedules,
                      label: (
                        <Link
                          to={routes.advancedSchedules}
                          style={{ display: 'block' }}
                        >
                          {formatMessage({ id: 'AdvancedSchedules' })}
                        </Link>
                      ),
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(canManageTemplates
      ? [
          {
            key: 'library',
            icon: <Folder />,
            label: formatMessage({ id: 'Library' }),
            className: 'onboarding-popover-holder',
            children: [
              {
                key: routes.auditTemplates,
                label: (
                  <>
                    <TourPopover
                      step={SelfGuidedTourStep.CreateTemplate}
                      placement="right"
                      overlayStyle={{ height: 40, width: 150 }}
                      disabledNextBtn={templatesLoading}
                    >
                      <Link
                        to={routes.auditTemplates}
                        style={{ display: 'block' }}
                      >
                        {formatMessage({ id: 'AuditTemplates' })}
                      </Link>
                    </TourPopover>
                  </>
                ),
              },
              {
                key: routes.actionTemplates,
                label: (
                  <Link to={routes.actionTemplates}>
                    {formatMessage({ id: 'ActionTemplates' })}
                  </Link>
                ),
              },
              {
                key: genericKeys.templateComponents,
                label: (
                  <Link to={routes.questionSets}>
                    {formatMessage({ id: 'QuestionSets' })}
                  </Link>
                ),
              },
            ],
          },
        ]
      : []),
    ...(canViewIssues || canManageIssueTypes
      ? [
          {
            key: 'issues',
            icon: <AlertTriangle />,
            label: formatMessage({ id: 'Issues' }),
            children: [
              ...(canViewIssues
                ? [
                    {
                      key: genericKeys.issues,
                      label: (
                        <Link to={routes.myIssues}>
                          {formatMessage({ id: 'Issues' })}
                        </Link>
                      ),
                    },
                  ]
                : []),
              ...(canManageIssueTypes
                ? [
                    {
                      key: routes.issuesQrCodes,
                      label: (
                        <Link to={routes.issuesQrCodes}>
                          {formatMessage({ id: 'QrCodes' })}
                        </Link>
                      ),
                    },
                    {
                      key: genericKeys.issueTypes,
                      label: (
                        <Link to={routes.issueTypes}>
                          {formatMessage({ id: 'IssueTypes' })}
                        </Link>
                      ),
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(canViewAnalytics
      ? [
          {
            key: 'reports',
            icon: <FileText />,
            label: formatMessage({ id: 'Analytics' }),
            children: [
              {
                key: routes.summaryReport,
                label: (
                  <Link to={routes.summaryReport}>
                    {formatMessage({ id: 'Summary' })}
                  </Link>
                ),
              },
              {
                key: routes.auditPerformanceReport,
                label: (
                  <Link to={routes.auditPerformanceReport}>
                    {formatMessage({ id: 'AuditPerformance' })}
                  </Link>
                ),
              },
              {
                key: genericKeys.auditorPerformanceReport,
                label: (
                  <Link to={routes.auditorPerformanceReportRealTime}>
                    {formatMessage({ id: 'AuditorPerformance' })}
                  </Link>
                ),
              },
              {
                key: genericKeys.auditObjectPerformanceReport,
                label: (
                  <Link to={routes.auditObjectPerformanceReportRealTime}>
                    {formatMessage(
                      { id: 'AuditObjectPerformance' },
                      { auditObjectName: auditObjectName.single }
                    )}
                  </Link>
                ),
              },
              {
                key: routes.itemAnalysisReport,
                label: (
                  <Link to={routes.itemAnalysisReport}>
                    {formatMessage({ id: 'ItemAnalysis' })}
                  </Link>
                ),
              },
              {
                key: routes.customReport,
                label: (
                  <Link to={routes.customReport}>
                    {formatMessage({ id: 'Custom' })}
                  </Link>
                ),
              },
            ],
          },
        ]
      : []),
    ...(canViewSetupPages
      ? [
          {
            key: 'audit-objects',
            icon: <Copy />,
            label: (
              <div
                style={{
                  maxWidth: '115px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {auditObjectName.plural}
              </div>
            ),
            children: [
              {
                key: genericKeys.auditObjects,
                label: (
                  <Link to={routes.auditObjects.list}>
                    {formatMessage({ id: 'List' })}
                  </Link>
                ),
              },
              {
                key: routes.auditObjects.groupsList,
                label: (
                  <Link to={routes.auditObjects.groupsList}>
                    {formatMessage({ id: 'Groups' })}
                  </Link>
                ),
              },
              {
                key: routes.auditObjects.attributesList,
                label: (
                  <Link to={routes.auditObjects.attributesList}>
                    {formatMessage({ id: 'Attributes' })}
                  </Link>
                ),
              },
            ],
          },
          {
            key: 'users',
            icon: <Users />,
            label: formatMessage({ id: 'Users' }),
            children: [
              {
                key: genericKeys.users,
                label: (
                  <Link to={routes.users.list}>
                    {formatMessage({ id: 'List' })}
                  </Link>
                ),
              },
              {
                key: routes.users.groupsList,
                label: (
                  <Link to={routes.users.groupsList}>
                    {formatMessage({ id: 'Groups' })}
                  </Link>
                ),
              },
              {
                key: routes.users.jobTitles,
                label: (
                  <Link to={routes.users.jobTitles}>
                    {formatMessage({ id: 'JobTitles' })}
                  </Link>
                ),
              },
            ],
          },
        ]
      : []),
    {
      key: 'settings',
      icon: <Settings />,
      label: formatMessage({ id: 'Settings' }),
      children: [
        ...(canManageGeneralSetup
          ? [
              {
                key: routes.generalSettings,
                className: 'onboarding-popover-holder',
                label: (
                  <>
                    <TourPopover
                      step={SelfGuidedTourStep.Settings}
                      placement="right"
                      overlayStyle={{
                        marginTop: -40,
                        marginLeft: -25,
                        height: 290,
                        width: 185,
                      }}
                      scrollToSpotlight
                    >
                      <Link
                        to={routes.generalSettings}
                        style={{ display: 'block' }}
                      >
                        {formatMessage({ id: 'General' })}
                      </Link>
                    </TourPopover>
                  </>
                ),
              },
            ]
          : []),
        ...(canViewSetupPages
          ? [
              {
                key: routes.roles,
                label: (
                  <Link to={routes.roles}>
                    {formatMessage({ id: 'AccessRoles' })}
                  </Link>
                ),
              },
              {
                key: routes.scores,
                label: (
                  <Link to={routes.scores}>
                    {formatMessage({ id: 'Scoring' })}
                  </Link>
                ),
              },
            ]
          : []),
        {
          key: genericKeys.tags,
          label: (
            <Link to={routes.tagsAudits}>{formatMessage({ id: 'Tags' })}</Link>
          ),
        },
        {
          key: routes.notifications,
          label: (
            <Link to={routes.notifications}>
              {formatMessage({ id: 'Notifications' })}
            </Link>
          ),
        },
        ...(canManageBilling
          ? [
              {
                key: genericKeys.billing,
                label: (
                  <Link to={routes.subscription}>
                    {formatMessage({ id: 'Billing' })}
                  </Link>
                ),
              },
            ]
          : []),
        ...(canManageGeneralSetup
          ? [
              {
                key: routes.integration,
                label: (
                  <Link to={routes.integration}>
                    {formatMessage({ id: 'Integration' })}
                  </Link>
                ),
              },
              {
                key: genericKeys.tasks,
                label: (
                  <Link to={routes.import}>
                    {formatMessage({ id: 'DataTransfer' })}
                  </Link>
                ),
              },
            ]
          : []),
      ],
    },
    ...(isAdmin && appConfiguration?.showGettingStarted
      ? [
          {
            key: 'getting-started',
            icon: <Flag />,
            label: (
              <AdditionalSubMenuTitleContent>
                {formatMessage({ id: 'GettingStarted' })}
              </AdditionalSubMenuTitleContent>
            ),
            style: {
              marginTop: '45px',
            },
            children: [
              ...(company.hasSampleData
                ? [
                    {
                      key: 'account-page',
                      label: (
                        <LinkButton
                          type="button"
                          onClick={() => {
                            dispatch(
                              onboardingActions.toggleOnboardingModal(
                                OnboardingModalMode.Welcome
                              )
                            );
                          }}
                        >
                          {formatMessage({ id: 'WelcomePage' })}
                        </LinkButton>
                      ),
                    },
                    {
                      key: 'sample-audit',
                      label: (
                        <LinkButton
                          onClick={() => {
                            dispatch(
                              auditsActions.toggleStartSampleAuditModal(true)
                            );
                          }}
                        >
                          {formatMessage({ id: 'SampleAudit' })}
                        </LinkButton>
                      ),
                    },
                  ]
                : []),
              // {
              //   key: 'ai-assistant',
              //   label: (
              //     <LinkButton
              //       type="button"
              //       onClick={() => {
              //         dispatch(
              //           onboardingActions.toggleOnboardingModal(
              //             OnboardingModalMode.AIAssistant
              //           )
              //         );
              //       }}
              //     >
              //       {formatMessage({ id: 'AIAssistant' })}
              //     </LinkButton>
              //   ),
              // },
              ...(showSelfGuidedTour
                ? [
                    {
                      key: 'self-guided-tour',
                      label: (
                        <LinkButton
                          type="button"
                          onClick={() => {
                            dispatch(accountActions.selfGuidedTour.start());
                          }}
                        >
                          {formatMessage({ id: 'SelfGuidedTour' })}
                        </LinkButton>
                      ),
                    },
                  ]
                : []),
              {
                key: 'live-consultation',
                label: (
                  <LinkButton
                    type="button"
                    onClick={() => {
                      window?.open(config.urls.interactiveDemo, '_blank');

                      dispatch(
                        accountActions.sendAnalyticsEvent(
                          AnalyticsEvent.LiveConsultationClicked
                        )
                      );
                    }}
                  >
                    {formatMessage({ id: 'LiveConsultation' })}
                  </LinkButton>
                ),
              },
              {
                key: 'sample-data',
                label: (
                  <LinkButton
                    type="button"
                    onClick={() => {
                      dispatch(
                        accountActions.toggleSampleDataModal({
                          visible: true,
                          operation: company.hasSampleData ? 'delete' : 'add',
                        })
                      );
                    }}
                  >
                    {formatMessage({
                      id: company.hasSampleData
                        ? 'DeleteSampleData'
                        : 'AddSampleData',
                    })}
                  </LinkButton>
                ),
              },
              {
                key: 'download-apps',
                label: (
                  <LinkButton
                    type="button"
                    onClick={() => {
                      dispatch(accountActions.toggleDownloadAppsModal(true));
                    }}
                  >
                    {formatMessage({ id: 'DownloadApps' })}
                  </LinkButton>
                ),
              },
            ],
          },
        ]
      : []),
    ...(appConfiguration?.showHelpCenter
      ? [
          {
            key: 'help-center',
            icon: <HelpCircle />,
            style: {
              marginTop: isAdmin ? '0px' : '45px',
            },
            label: (
              <HelpCenter>
                <LinkButton
                  type="button"
                  onClick={() => {
                    dispatch(accountActions.toggleHelpCenter(true));
                  }}
                >
                  {formatMessage({ id: 'HelpCenter' })}
                </LinkButton>
              </HelpCenter>
            ),
          },
        ]
      : []),
  ];
};

interface IProps {
  onSelect?: () => void;
}

const MainMenu: React.FC<React.PropsWithChildren<IProps>> = ({ onSelect }) => {
  const location = useLocation();

  const { step: tourStep } = useSelector(accountSelectors.getSelfGuidedTour);

  const { pathname } = location;
  const currentMenuItemKey = useCurrentMenuItemKey();

  const [openKeys, setOpenKeys] = useState<string[] | undefined>(undefined);
  const prevStep = usePrevious<SelfGuidedTourStep | null>(tourStep);

  useEffect(() => {
    if (prevStep === null && tourStep !== null) {
      setOpenKeys(tourOpenedKeys);
    }

    if (prevStep !== undefined && prevStep !== null && tourStep === null) {
      setOpenKeys(['getting-started']);
    }
  }, [tourStep, prevStep]);

  const menuItems = useMenuItems();

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={[currentMenuItemKey]}
      defaultOpenKeys={[pathname.split('/')[1]]}
      selectedKeys={[currentMenuItemKey]}
      openKeys={openKeys}
      inlineIndent={0}
      items={menuItems}
      onSelect={onSelect}
      onOpenChange={(keys) => {
        setOpenKeys(keys.length > 0 ? [keys[keys.length - 1]] : []);
      }}
    />
  );
};

export default MainMenu;
