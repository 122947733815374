import { createReducer } from '@reduxjs/toolkit';

import { QuestionSetsState } from '@application/QuestionSets/models/QuestionSetsState';
import { questionSetsEntityAdapter } from '@application/QuestionSets/store/entityAdapters';
import { getInitialFilters } from '@utils';
import { questionSetsActions } from '@application/QuestionSets/store/questionSetsActions';
import { QuestionSetDetailsModalTab } from '@application/QuestionSets/enums/QuestionSetDetailsModalTab';

export const questionSetsReducer = createReducer<QuestionSetsState>(
  {
    data: questionSetsEntityAdapter.getInitialState(),
    loading: false,
    meta: null,
    error: null,
    filters: {
      ...getInitialFilters(),
    },
    editQuestionSetCandidateId: null,
    deleteQuestionSetModal: null,
    questionSetDetailsModal: {
      questionSetId: null,
      tab: QuestionSetDetailsModalTab.Questions,
    },
    questionSetDetails: {
      loading: false,
      data: null,
      error: null,
    },
    questionSetTemplates: {
      loading: false,
      error: null,
      data: [],
    },
  },
  (builder) =>
    builder
      .addCase(
        questionSetsActions.getQuestionSets.pending,
        (state, { meta: { arg } }) => {
          state.loading = true;
          state.error = null;

          if (arg === null) {
            state.filters = {
              ...getInitialFilters(),
            };
          } else {
            state.filters = {
              ...state.filters,
              ...arg,
            };
          }
        }
      )
      .addCase(
        questionSetsActions.getQuestionSets.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.meta = payload.meta;
          questionSetsEntityAdapter.setAll(state.data, payload.data);
        }
      )
      .addCase(
        questionSetsActions.getQuestionSets.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload || null;
        }
      )
      .addCase(
        questionSetsActions.toggleConfirmEditQuestionSetModal,
        (state, { payload }) => {
          state.editQuestionSetCandidateId = payload;
        }
      )
      .addCase(
        questionSetsActions.toggleDeleteQuestionSetsModal,
        (state, { payload }) => {
          state.deleteQuestionSetModal = payload;
        }
      )
      .addCase(
        questionSetsActions.toggleQuestionSetDetailsModal,
        (state, { payload }) => {
          state.questionSetDetailsModal.questionSetId = payload;
          state.questionSetDetailsModal.tab =
            QuestionSetDetailsModalTab.Questions;
        }
      )
      .addCase(
        questionSetsActions.setQuestionSetDetailsModalTab,
        (state, { payload }) => {
          state.questionSetDetailsModal.tab = payload;
        }
      )
      .addCase(questionSetsActions.getQuestionSetDetails.pending, (state) => {
        state.questionSetDetails.loading = true;
        state.questionSetDetails.error = null;
      })
      .addCase(
        questionSetsActions.getQuestionSetDetails.fulfilled,
        (state, { payload }) => {
          state.questionSetDetails.loading = false;
          state.questionSetDetails.data = payload;
        }
      )
      .addCase(
        questionSetsActions.getQuestionSetDetails.rejected,
        (state, { payload }) => {
          state.questionSetDetails.loading = false;
          state.questionSetDetails.error = payload || null;
        }
      )
      .addCase(questionSetsActions.getQuestionSetTemplates.pending, (state) => {
        state.questionSetTemplates.loading = true;
        state.questionSetTemplates.error = null;
      })
      .addCase(
        questionSetsActions.getQuestionSetTemplates.fulfilled,
        (state, { payload }) => {
          state.questionSetTemplates.loading = false;
          state.questionSetTemplates.data = payload;
        }
      )
      .addCase(
        questionSetsActions.getQuestionSetTemplates.rejected,
        (state, { payload }) => {
          state.questionSetTemplates.loading = false;
          state.questionSetTemplates.error = payload || null;
        }
      )
);
