import React from 'react';
import { useSelector } from 'react-redux';

import { OnboardingModalMode } from '@application/Onboarding/enums/OnboardingModalMode';
import {
  AnimatedContent,
  Container,
} from '@presentation/Onboarding/OnboardingModal/styled';
import { useOpacityTransition } from '@repo/shared/hooks';
import { onboardingSelectors } from '@application/Onboarding/store/onboardingSelectors';

import WelcomeContent from '@presentation/Onboarding/OnboardingModal/WelcomeContent/WelcomeContent';
import OnboardingAssistant from '@presentation/Onboarding/OnboardingModal/OnboardingAssistant/OnboardingAssistant';

interface IProps {}

const OnboardingModalContent: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const mode = useSelector(onboardingSelectors.getOnboardingModalMode);

  const transition = useOpacityTransition<OnboardingModalMode | null>(mode);

  return (
    <Container>
      {transition.map(({ item, props, key }) => {
        if (item === OnboardingModalMode.Welcome) {
          return (
            <AnimatedContent key={key} style={props}>
              <WelcomeContent />
            </AnimatedContent>
          );
        }

        if (item === OnboardingModalMode.AIAssistant) {
          return (
            <AnimatedContent key={key} style={props}>
              <OnboardingAssistant />
            </AnimatedContent>
          );
        }

        return null;
      })}
    </Container>
  );
};

export default OnboardingModalContent;
