import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IPagedResponse, ITableFilters } from '@repo/shared/types';
import { ExportTask, ImportTask } from '@domain/TransferTasks/TransferTask';
import { Logger } from '@repo/shared/services';
import { getErrorMessage } from '@repo/shared/utils';
import TransferTasksApiClient from '@infrastructure/TransferTasks/api/TransferTasksApiClient';
import { transferTasksSelectors } from '@application/TransferTasks/store/transferTasksSelectors';
import { TransferTaskType } from '@domain/TransferTasks/TransferTaskType';
import { ImportRecord } from '@domain/TransferTasks/ImportRecord';
import { TransferTaskDataType } from '@domain/TransferTasks/TransferTaskDataType';
import { ImportTaskDetails } from '@domain/TransferTasks/ImportTaskDetails';

import { CreateImportTaskParams } from '@application/TransferTasks/models/CreateImportTaskParams';

const transferTasksApiClient = new TransferTasksApiClient();

const setTaskType = createAction<TransferTaskType | null>(
  'transferTasks/setTaskType'
);

const getImportTasks = createAsyncThunk<
  IPagedResponse<ImportTask>,
  Partial<ITableFilters> | undefined | null,
  { rejectValue: string }
>('transferTasks/getImportTasks', async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState();

    const filters = transferTasksSelectors.getTableFilters(state);

    return await transferTasksApiClient.getImportTasks(filters);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const getExportTasks = createAsyncThunk<
  IPagedResponse<ExportTask>,
  Partial<ITableFilters> | undefined | null,
  { rejectValue: string }
>('transferTasks/getExportTasks', async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState();

    const filters = transferTasksSelectors.getTableFilters(state);

    return await transferTasksApiClient.getExportTasks(filters);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const resetTableData = createAction('transferTasks/resetTableData');

const toggleCreateExportTaskModal = createAction<boolean>(
  'transferTasks/toggleCreateExportTaskModal'
);

const toggleCreateImportTaskModal = createAction<boolean>(
  'transferTasks/toggleCreateImportTaskModal'
);

const validateImportTask = createAsyncThunk<
  ImportRecord[],
  CreateImportTaskParams,
  { rejectValue: string }
>(
  'transferTasks/validateImportTask',
  async ({ importFile, dataType }, { rejectWithValue }) => {
    try {
      return await transferTasksApiClient.validateImportTask(
        importFile.id,
        dataType
      );
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const createImportTask = createAsyncThunk<
  void,
  CreateImportTaskParams,
  { rejectValue: string }
>(
  'transferTasks/createImportTask',
  async ({ importFile, dataType }, { rejectWithValue }) => {
    try {
      await transferTasksApiClient.createImportTask(importFile.id, dataType);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const createExportTask = createAsyncThunk<
  void,
  TransferTaskDataType,
  { rejectValue: string }
>('transferTasks/createExportTask', async (dataType, { rejectWithValue }) => {
  try {
    await transferTasksApiClient.createExportTask(dataType);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const getImportTaskDetails = createAsyncThunk<
  ImportTaskDetails,
  string,
  { rejectValue: string }
>('transferTasks/getImportTaskDetails', async (taskId, { rejectWithValue }) => {
  try {
    return await transferTasksApiClient.getImportTaskDetails(taskId);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const toggleImportTaskDetailsModal = createAction<string | null>(
  'transferTasks/getImportTaskDetails'
);

const updateImportTask = createAction<ImportTask>(
  'transferTasks/updateImportTask'
);

const updateExportTask = createAction<ExportTask>(
  'transferTasks/updateExportTask'
);

export const transferTasksActions = {
  setTaskType,
  getImportTasks,
  getExportTasks,
  getImportTaskDetails,
  resetTableData,
  toggleCreateExportTaskModal,
  toggleCreateImportTaskModal,
  validateImportTask,
  createImportTask,
  createExportTask,
  toggleImportTaskDetailsModal,
  updateImportTask,
  updateExportTask,
};
