import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../../frameworks/redux';
import {
  IAuditsCompletionChartItem,
  IDetailsModalItemsFilters,
  IUnresolvedActionsChartItem,
} from '@repo/shared/types';
import { ActionStatus } from '@repo/shared/enums';
import {
  Priority,
  AuditStatusReports,
  DetailsModalType,
  ShowUnresolvedActionsBy,
} from '@repo/shared/enums';
import { auditsDashboardAdapter } from '@store/entityAdapters';
import { generalSelectors } from '../general/general.selectors';
import { itemAnalysisReportActions } from '@store';
import { intl } from '@repo/shared/components/IntlGlobalProvider';
import { actionStatuses } from '@config';
import { getTranslatedPriorityLabel } from '@components/shared/PriorityLabel/PriorityLabel';

export const dashboardSelectors = {
  getFilters: createSelector(
    generalSelectors.getSharedFilters,
    ({ templateIds, auditObjectIds, auditObjectGroupIds }) => ({
      templateIds,
      auditObjectIds,
      auditObjectGroupIds,
    })
  ),
  getTotals: (state: IRootState) => state.dashboard.auditsTotals,
  getAuditsCompletion: createSelector(
    (state: IRootState) => state.dashboard.auditsCompletion,
    ({ data, filters, error, loading }) => {
      return {
        data: data
          ? {
              chart: data.chart
                .filter((item: IAuditsCompletionChartItem) => item.count)
                .map(
                  ({
                    scoreMax,
                    scoreMin,
                    ...item
                  }: IAuditsCompletionChartItem) => ({
                    ...item,
                    onClickAction: itemAnalysisReportActions.detailsModal.show({
                      title:
                        item.label || intl.formatMessage({ id: 'ScoreSystem' }),
                      count: item.count,
                      type: DetailsModalType.Audits,
                      filters: {
                        scoreElementId: item.id,
                        auditStatus: AuditStatusReports.Completed,
                        startDate: filters.startDate,
                        endDate: filters.endDate,
                      },
                    }),
                  })
                ),
              averageScore: data.averageScore,
            }
          : null,
        filters,
        error,
        loading,
      };
    }
  ),
  getUnresolvedActions: createSelector(
    [(state: IRootState) => state.dashboard.unresolvedActions],
    ({ data, filters, error, loading }) => {
      return {
        data: data
          ? data
              .filter((item: IUnresolvedActionsChartItem) => item.count > 0)
              .map((item: IUnresolvedActionsChartItem) => {
                const detailedModalFilters: Partial<IDetailsModalItemsFilters> =
                  {
                    hasCorrectiveActions: true,
                  };

                if (filters.showBy === ShowUnresolvedActionsBy.ByPriority) {
                  detailedModalFilters.actionPriority = item.value as Priority;
                  detailedModalFilters.actionStatuses = [
                    ActionStatus.Opened,
                    ActionStatus.Submitted,
                    ActionStatus.Rejected,
                  ];
                }

                if (filters.showBy === ShowUnresolvedActionsBy.ByStatus) {
                  detailedModalFilters.actionStatuses = [
                    item.value as ActionStatus,
                  ];
                }

                let detailsModalTitle = intl.formatMessage({
                  id: 'Pending',
                });

                if (
                  filters.showBy === ShowUnresolvedActionsBy.ByStatus &&
                  actionStatuses[item.value]
                ) {
                  detailsModalTitle = intl.formatMessage({
                    id: actionStatuses[item.value],
                  });
                } else if (
                  filters.showBy === ShowUnresolvedActionsBy.ByPriority &&
                  getTranslatedPriorityLabel(item.value as Priority)
                ) {
                  detailsModalTitle = `${getTranslatedPriorityLabel(
                    item.value as Priority
                  )} ${intl.formatMessage({ id: 'Priority' }).toLowerCase()}`;
                }

                return {
                  ...item,
                  rest:
                    filters.showBy === ShowUnresolvedActionsBy.ByStatus
                      ? item.count -
                        (item?.dueIn7Days || 0) -
                        (item?.pastDue || 0)
                      : item.count,
                  onClickAction: itemAnalysisReportActions.detailsModal.show({
                    title: `${detailsModalTitle} ${intl
                      .formatMessage({
                        id: 'Actions',
                      })
                      .toLowerCase()}`,
                    count: item.count,
                    type: DetailsModalType.ItemsByActions,
                    filters: detailedModalFilters,
                  }),
                };
              })
          : null,
        filters,
        error,
        loading,
      };
    }
  ),
  getAudits: (state: IRootState) => ({
    ...state.dashboard.audits,
    data: auditsDashboardAdapter
      .getSelectors()
      .selectAll(state.dashboard.audits.data),
  }),
};
