import styled from 'styled-components';

export const Container = styled.div`
  padding: 5px 0;
`;

export const Trigger = styled.div`
  width: 90px;
`;

export const FullScreenModalContent = styled.div`
  padding: 50px 0 0;
`;
