import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';

import { Container, Text, SignUpLink, Icon, CloseButton } from './styled';
import { accountSelectors } from '@store';
import { Announcement as IAnnouncement } from '@repo/shared/types';
import * as UserPreferencesService from '@services/userPreferences.service';

interface IProps {}

const LS_ITEM_NAME = 'lastHiddenAnnouncementId';

const Announcement: React.FC<React.PropsWithChildren<IProps>> = () => {
  const { formatMessage } = useIntl();

  const [announcement, setAnnouncement] = useState<IAnnouncement | null>(null);

  const configuration = useSelector(accountSelectors.getAppConfiguration);

  const latestAnnouncement = useMemo(() => {
    return configuration.news.length > 0
      ? configuration.news[configuration.news.length - 1]
      : null;
  }, [configuration.news]);

  useEffect(() => {
    async function initAnnouncement() {
      const lastHiddenId =
        await UserPreferencesService.getItem<string>(LS_ITEM_NAME);

      if (latestAnnouncement && latestAnnouncement?.id !== lastHiddenId) {
        setAnnouncement(latestAnnouncement);
      }
    }

    initAnnouncement();
  }, [latestAnnouncement]);

  if (announcement) {
    return (
      <Container>
        <Icon />
        <Text>{announcement.text}</Text>
        <SignUpLink
          href={announcement.url}
          target="announcement"
          rel="noopener noreferrer"
        >
          {announcement.buttonLabel || formatMessage({ id: 'LearnMore' })}
        </SignUpLink>
        <CloseButton
          type="button"
          onClick={async () => {
            await UserPreferencesService.setItem(LS_ITEM_NAME, announcement.id);
            setAnnouncement(null);
          }}
        >
          <X />
        </CloseButton>
      </Container>
    );
  }

  return null;
};

export default Announcement;
