import React from 'react';
import AntAvatar, { AvatarProps } from 'antd/es/avatar';

import { colors } from '@repo/shared/config';
import { IConcise } from '@repo/shared/types';

function getInitials(name: string) {
  const groups = name.match(/\b\w/g) || [];
  return ((groups.shift() || '') + (groups.pop() || '')).toUpperCase();
}

interface IProps extends AvatarProps {
  user: IConcise;
}

const Avatar: React.FC<React.PropsWithChildren<IProps>> = ({
  user,
  ...props
}) => {
  return (
    <div>
      <AntAvatar
        {...props}
        style={{
          backgroundColor: colors.blue2,
          fontWeight: 'normal',
          display: 'flex',
        }}
        gap={6}
      >
        <div style={{ fontSize: '10px' }}>{getInitials(user.name)}</div>
      </AntAvatar>
    </div>
  );
};

export default Avatar;
