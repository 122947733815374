import { forwardRef, SVGAttributes } from 'react';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
}

const Component = forwardRef<SVGSVGElement, IProps>(
  ({ size = 16, title, ...rest }, ref) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <path
          d="M14 10.666V5.33262C13.9998 5.0988 13.938 4.86916 13.821 4.66673C13.704 4.46429 13.5358 4.29619 13.3333 4.17928L8.66667 1.51262C8.46397 1.39559 8.23405 1.33398 8 1.33398C7.76595 1.33398 7.53603 1.39559 7.33333 1.51262L2.66667 4.17928C2.46418 4.29619 2.29599 4.46429 2.17897 4.66673C2.06196 4.86916 2.00024 5.0988 2 5.33262V10.666C2.00024 10.8998 2.06196 11.1294 2.17897 11.3318C2.29599 11.5343 2.46418 11.7024 2.66667 11.8193L7.33333 14.486C7.53603 14.603 7.76595 14.6646 8 14.6646C8.23405 14.6646 8.46397 14.603 8.66667 14.486L13.3333 11.8193C13.5358 11.7024 13.704 11.5343 13.821 11.3318C13.938 11.1294 13.9998 10.8998 14 10.666Z"
          fill="#31BBE7"
          stroke="#44444A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 10.6679V5.33457C13.9998 5.10075 13.938 4.87111 13.821 4.66868C13.704 4.46625 13.5358 4.29815 13.3333 4.18124L8.66667 1.51457C8.46397 1.39755 8.23405 1.33594 8 1.33594C7.76595 1.33594 7.53603 1.39755 7.33333 1.51457L2.66667 4.18124C2.46418 4.29815 2.29599 4.46625 2.17897 4.66868C2.06196 4.87111 2.00024 5.10075 2 5.33457V10.6679C2.00024 10.9017 2.06196 11.1314 2.17897 11.3338C2.29599 11.5362 2.46418 11.7043 2.66667 11.8212L7.33333 14.4879C7.53603 14.6049 7.76595 14.6665 8 14.6665C8.23405 14.6665 8.46397 14.6049 8.66667 14.4879L13.3333 11.8212C13.5358 11.7043 13.704 11.5362 13.821 11.3338C13.938 11.1314 13.9998 10.9017 14 10.6679Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.18018 4.64062L8.00018 8.00729L13.8202 4.64062"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 14.72V8"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default Component;
