import {
  AnswerType,
  IntervalType,
  ItemType,
  TemperatureUnit,
} from '@repo/shared/enums';
import { UnexpectedError } from '@repo/shared/errors';
import {
  TemplateItem,
  TemplateQuestionItem,
} from '@domain/AuditTemplates/TemplateItem';

interface BaseCondition {
  id: string;
  questionSetItemAnswerId?: string;
}

export interface ButtonsCondition extends BaseCondition {
  name: string;
  points: number;
  color: string;
  markAsFailed: boolean;
}

export type ButtonsData = { conditions: [ButtonsCondition, ButtonsCondition] };

export interface NumericInterval extends BaseCondition {
  fromNumber: number | null;
  toNumber: number | null;
  points: number | null;
  markAsFailed: boolean;
  selected?: boolean;
  number?: number | null;
  fromIntervalType: IntervalType;
  toIntervalType: IntervalType;
}

export type NumericIntervalsData = { conditions: NumericInterval[] };

export type SliderData = {
  min: number;
  max: number;
  increment: number;
  conditions: NumericInterval[];
};

interface ChecklistCondition extends BaseCondition {
  name: string;
  points: number;
}

export type ChecklistData = {
  conditions: [ChecklistCondition, ChecklistCondition];
};

export type TextData = {
  id: string;
  questionSetItemAnswerId?: string;
  text: string;
};

interface DropdownCondition extends BaseCondition {
  name: string;
  points: number | null;
  markAsFailed: boolean;
}

export type DropdownData = {
  conditions: DropdownCondition[];
};

export type TemperatureData = {
  units: TemperatureUnit;
  conditions: NumericInterval[];
};

export type ButtonsAnswerTypeData = {
  answerType: AnswerType.PassFailButtons | AnswerType.YesNoButtons;
  data: ButtonsData;
};

export type NumericAnswerTypeData = {
  answerType: AnswerType.Numeric;
  data: NumericIntervalsData;
};

export type ChecklistAnswerTypeData = {
  answerType: AnswerType.Checklist;
  data: ChecklistData;
};

export type SliderAnswerTypeData = {
  answerType: AnswerType.Slider;
  data: SliderData;
};

export type TextAnswerTypeData = {
  answerType: AnswerType.Text;
  data: TextData;
};

export type DropdownAnswerTypeData = {
  answerType: AnswerType.Dropdown;
  data: DropdownData;
};

export type TemperatureAnswerTypeData = {
  answerType: AnswerType.Temperature;
  data: TemperatureData;
};

export type AnswerTypeData =
  | ButtonsAnswerTypeData
  | NumericAnswerTypeData
  | ChecklistAnswerTypeData
  | SliderAnswerTypeData
  | TextAnswerTypeData
  | DropdownAnswerTypeData
  | TemperatureAnswerTypeData;

export type NumericLikeAnswerTypeData =
  | NumericAnswerTypeData
  | SliderAnswerTypeData
  | TemperatureAnswerTypeData;

export type AnswerTypeDataMapping = {
  [AnswerType.PassFailButtons]: ButtonsData;
  [AnswerType.YesNoButtons]: ButtonsData;
  [AnswerType.Slider]: NumericIntervalsData;
  [AnswerType.Text]: TextData;
  [AnswerType.Checklist]: ChecklistData;
  [AnswerType.Dropdown]: DropdownData;
  [AnswerType.Temperature]: NumericIntervalsData;
  [AnswerType.Numeric]: NumericIntervalsData;
};

export type AnswerDataWithPoints =
  | ButtonsData
  | NumericIntervalsData
  | DropdownData
  | ChecklistData
  | SliderData;

export type AnswerData =
  | ButtonsData
  | NumericIntervalsData
  | ChecklistData
  | DropdownData
  | TemperatureData
  | TextData
  | SliderData;

export function transformAnswerTypeDataArrayToMapping(
  answerTypeDataArray: AnswerTypeData[]
): AnswerTypeDataMapping {
  const mapping: { [key in AnswerType]: AnswerData | null } = {
    [AnswerType.PassFailButtons]: null,
    [AnswerType.YesNoButtons]: null,
    [AnswerType.Numeric]: null,
    [AnswerType.Slider]: null,
    [AnswerType.Text]: null,
    [AnswerType.Checklist]: null,
    [AnswerType.Dropdown]: null,
    [AnswerType.Temperature]: null,
  };

  for (const answerTypeData of answerTypeDataArray) {
    const { answerType, data } = answerTypeData;

    if (mapping[answerType] !== null) {
      throw new Error(`Duplicate answerType: ${AnswerType[answerType]}`);
    }

    mapping[answerType] = data;
  }

  for (const key in mapping) {
    const answerType = Number(key) as AnswerType;

    if (mapping[answerType] === null) {
      throw new UnexpectedError(`Missing answerType(s): ${answerType}`);
    }
  }

  return mapping as AnswerTypeDataMapping;
}

export function isNumericIntervalQuestion(
  answerTypeData: AnswerTypeData
): answerTypeData is NumericLikeAnswerTypeData {
  return (
    answerTypeData.answerType === AnswerType.Slider ||
    answerTypeData.answerType === AnswerType.Numeric ||
    answerTypeData.answerType === AnswerType.Temperature
  );
}

export function isButtonsQuestion(
  answerTypeData: AnswerTypeData
): answerTypeData is ButtonsAnswerTypeData {
  return (
    answerTypeData.answerType === AnswerType.PassFailButtons ||
    answerTypeData.answerType === AnswerType.YesNoButtons
  );
}

export function isSliderQuestion(
  answerTypeData: AnswerTypeData
): answerTypeData is SliderAnswerTypeData {
  return answerTypeData.answerType === AnswerType.Slider;
}

export function isQuestion(item: TemplateItem): item is TemplateQuestionItem {
  return (
    item.itemType === ItemType.Item ||
    item.itemType === ItemType.ConditionalItem
  );
}
