import { AuditTemplateDetails } from '@domain/AuditTemplates/AuditTemplateDetails';
import { ZERO_UUID } from '@config';
import { intl } from '@repo/shared/components/IntlGlobalProvider';
import { IScore } from '@repo/shared/types';
import {
  AuditReportTitlePageTemplate,
  AuditTemplateType,
  HistoricalTrendDepth,
} from '@repo/shared/enums';

export function getAuditTemplateBase({
  templateType,
  templateName,
  scoreSystem,
}: {
  templateType: AuditTemplateType;
  templateName?: string;
  scoreSystem?: IScore;
}): Omit<AuditTemplateDetails, 'data'> {
  return {
    id: ZERO_UUID,
    templateType,
    description: '',
    disclaimer: '',
    name: templateName || intl.formatMessage({ id: 'NewTemplate' }) || '',
    scoreSystem: scoreSystem || { id: ZERO_UUID, name: '' },
    isAuditorSignatureRequired: false,
    isAuditeeSignatureRequired: false,
    signatureAgreement: null,
    historicalTrendDepth: HistoricalTrendDepth.Last4,
    isSample: false,
    isDefault: false,
    isDraft: true,
    pdfSettings: {
      pdfTemplate: AuditReportTitlePageTemplate.Full,
      showAuditCompletionDateTime: false,
      showAuditDuration: false,
      showAuditStartDateTime: false,
      showLocationMap: false,
      showAverageScore: false,
      showScoreHistory: false,
      accentColor: null,
      includeSummary: true,
      includePhotos: false,
      includeNAAnswers: true,
      includeDetails: false,
    },
    estimatedTimeMins: null,
    makeAuditReportPubliclyAccessible: true,
    includePdfReport: false,
    restrictGalleryUsage: false,
    addWatermarkToGalleryPhotos: false,
    addTimestampToCameraPhotos: false,
  };
}
