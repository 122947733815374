import React from 'react';

import {
  LogoContainer,
  SidebarFooter,
  Sider,
  AccountsSelectorWrapper,
} from './styled';
import { e2eTestElements } from '@config';

import AppLogo from '@components/shared/AppLogo';
import TrialNotifier from '@components/account/AppLayout/TrialNotifier/TrialNotifier';
import AccountsSelector from '@components/account/AppLayout/AccountsSelector/AccountsSelector';
import MainMenu from '@components/account/AppLayout/MainMenu/MainMenu';
import CopyrightAndLocale from '@components/account/AppLayout/CopyrightAndLocale/CopyrightAndLocale';

const Sidebar: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Sider>
      <LogoContainer>
        <AppLogo data-e2e={e2eTestElements.common.logo} />
      </LogoContainer>
      <AccountsSelectorWrapper>
        <AccountsSelector />
      </AccountsSelectorWrapper>
      <MainMenu />
      <SidebarFooter>
        <TrialNotifier />
        <CopyrightAndLocale />
      </SidebarFooter>
    </Sider>
  );
};

export default Sidebar;
