import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const YearCopy = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > * {
    margin: 0 5px 7px;
  }
`;

export const CopyRight = styled.div`
  font-size: 12px;
  color: ${colors.gray7};
  margin-top: 1px;
`;
