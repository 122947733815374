import React from 'react';
import { Menu, X } from 'react-feather';
import { useSelector } from 'react-redux';

import { Button } from './styled';
import { useAppDispatch } from '@hooks';
import { generalSelectors, generalActions } from '@store';

interface IProps {
  theme: 'light' | 'dark';
}

const MobileMenuTrigger: React.FC<React.PropsWithChildren<IProps>> = ({
  theme,
}) => {
  const dispatch = useAppDispatch();

  const opened = useSelector(generalSelectors.isMobileMenuOpened);

  return (
    <Button
      type="button"
      theme={theme}
      onClick={() => {
        dispatch(generalActions.toggleMobileMenu(!opened));
      }}
    >
      {opened ? <X /> : <Menu />}
    </Button>
  );
};

export default MobileMenuTrigger;
