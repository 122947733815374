import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'antd/es/modal';
import { useNavigate, useLocation } from 'react-router-dom';
import { X } from 'react-feather';

import { accountActions } from '@store';
import { Close } from './styled';
import { useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';
import { AnalyticsEvent } from '@repo/shared/enums';
import { useOpacityTransition } from '@repo/shared/hooks';
import { onboardingSelectors } from '@application/Onboarding/store/onboardingSelectors';
import { OnboardingModalMode } from '@application/Onboarding/enums/OnboardingModalMode';
import { onboardingActions } from '@application/Onboarding/store/onboardingActions';
import { useLgMediaQuery } from '@repo/shared/hooks';

import MobileFullScreenModal from '@components/shared/MobileFullScreenModal/MobileFullScreenModal';
import OnboardingModalContent from '@presentation/Onboarding/OnboardingModal/OnboardingModalContent';

const OnboardingModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const fromCompanySetup = location.state?.fromCompanySetup || false;

  const showDesktopLayout = useLgMediaQuery();

  const mode = useSelector(onboardingSelectors.getOnboardingModalMode);

  useEffect(() => {
    if (fromCompanySetup) {
      dispatch(
        onboardingActions.toggleOnboardingModal(OnboardingModalMode.Welcome)
      );
    }
  }, [fromCompanySetup]);

  function closeModal() {
    dispatch(onboardingActions.toggleOnboardingModal(null));
    navigate(location.pathname, { replace: true });
  }

  const opened = mode !== null;

  if (showDesktopLayout) {
    return (
      <Modal
        open={opened}
        style={{ maxWidth: 'none' }}
        styles={{
          body: {
            marginBottom: '0px',
          },
        }}
        className="modal-no-padding modal-onboarding"
        closeIcon={
          <Close data-e2e={e2eTestElements.account.welcomeModal.closeButton}>
            <X />
          </Close>
        }
        onCancel={() => {
          closeModal();

          if (fromCompanySetup) {
            dispatch(
              accountActions.sendAnalyticsEvent(
                AnalyticsEvent.WelcomePopupClosed
              )
            );
          }
        }}
        footer={null}
      >
        <OnboardingModalContent />
      </Modal>
    );
  }

  return (
    <MobileFullScreenModal
      opened={opened}
      onClose={() => {
        closeModal();
      }}
    >
      <OnboardingModalContent />
    </MobileFullScreenModal>
  );
};

export default OnboardingModal;
