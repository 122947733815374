import { AnswerDataWithPoints } from '@domain/AuditTemplates/AnswerTypeData';

export const calculateTemplatePreviewQuestionPoints = (
  data: AnswerDataWithPoints
): [number, number] => {
  let points = 0;

  if (data.conditions) {
    for (let i = 0; i < data.conditions.length; i++) {
      const dataItemPoints = data.conditions[i].points || 0;

      if (dataItemPoints > points) {
        points = dataItemPoints;
      }
    }
  }

  return [points, 0];
};
