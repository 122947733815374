import { AuditTemplateDetails } from '@domain/AuditTemplates/AuditTemplateDetails';
import { AnswerType, AuditTemplateType, ItemType } from '@repo/shared/enums';
import {
  createQuestionItem,
  createRootItem,
  createSectionItem,
} from '@application/AuditBuilder/utils/createTemplateItem';
import { IScore } from '@repo/shared/types';
import { getAuditTemplateBase } from '@application/AuditBuilder/utils/getAuditTemplateBase';
import { TemplateItem } from '@domain/AuditTemplates/TemplateItem';
import { IRootState } from '@src/core/frameworks/redux';

export function createAuditTemplateDetails(
  {
    templateType,
    scoreSystem,
  }: {
    templateType: AuditTemplateType;
    scoreSystem: IScore;
  },
  state: IRootState
): AuditTemplateDetails {
  const root = createRootItem();

  const section = createSectionItem({
    parentId: root.id,
    index: 0,
  });

  root.childrenIds.push(section.id);

  const items: TemplateItem[] = [root, section];

  for (let i = 0; i < 2; i++) {
    const answerType =
      templateType === AuditTemplateType.Audit
        ? AnswerType.PassFailButtons
        : AnswerType.Checklist;

    const item = createQuestionItem(
      {
        parentId: section.id,
        itemType: ItemType.Item,
        answerType,
        index: i,
      },
      state
    );

    section.childrenIds.push(item.id);

    items.push(item);
  }

  return {
    ...getAuditTemplateBase({ templateType, scoreSystem }),
    data: items.reduce<Record<string, TemplateItem>>((acc, item) => {
      const a = acc;
      a[item.id] = item;
      return a;
    }, {}),
  };
}
