import {
  isTemplateQuestionItem,
  isTemplateSectionItem,
  TemplateItem,
} from '@domain/AuditTemplates/TemplateItem';
import { PerformAuditItem } from '@domain/PerformAudit/PerformAuditItem';
import { colors } from '@repo/shared/config';
import { ItemType } from '@repo/shared/enums';
import { IAnswerData } from '@repo/shared/types';

export function convertTemplateItemToPerformAuditItem(
  item: TemplateItem
): PerformAuditItem {
  const isQuestion = isTemplateQuestionItem(item);
  const isSection = isTemplateSectionItem(item);

  return {
    id: item.id,
    itemType: item.itemType,
    childrenIds: item.childrenIds,
    index: item.index,
    parentId: item.parentId,
    text: isQuestion || isSection ? item.text : null,
    answerType: isQuestion ? item.answerType : null,
    information: isQuestion ? item.information : null,
    isSignatureRequired: isQuestion ? item.isSignatureRequired : false,
    actionTemplates: isQuestion ? item.actionTemplates : [],
    hasInformation: isQuestion ? !!item.information : false,
    condition: item.itemType === ItemType.Condition ? item.condition : null,
    disableNotApplicable:
      isSection || isQuestion ? item.disableNotApplicable : false,
    itemTags: isQuestion ? item.itemTags : [],
    required: false,
    sectionAuditObjectsTags: [],
    isCritical: isQuestion ? item.isCritical : false,
    data: isQuestion ? (item.data as IAnswerData) : null,
    templateItemId: item.id,
    failedInRowCount: 0,
    notApplicable: false,
    notApplicableInRowCount: 0,
    groupIndex: null,
    points: {
      total: 0,
      selected: 0,
      score: 0,
      previousScore: null,
      color: colors.gray8,
    },
    itemsCount: null,
    actions: [],
    files: [],
    signature: null,
    flags: [],
    flagsInRow: [],
    tags: [],
    note: null,
    conditionValue: null,
  };
}
