import React, { useCallback, useState } from 'react';
import { ArrowUp } from 'react-feather';
import { useIntl } from 'react-intl';
import Spin from 'antd/es/spin';

import {
  Container,
  Content,
  InputHolder,
  Input,
  SendBtn,
  PublishingDisclaimer,
} from './styled';
import { onboardingActions } from '@application/Onboarding/store/onboardingActions';
import { useAppDispatch } from '@hooks';
import { useSelector } from 'react-redux';
import { onboardingSelectors } from '@application/Onboarding/store/onboardingSelectors';

interface IProps {}

const SendOnboardingAssistantChatMessage: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const publishing = useSelector(onboardingSelectors.isAssistantThinking);
  const starting = useSelector(onboardingSelectors.isStarting);

  const [message, setMessage] = useState<string>('');

  const publishMessage = useCallback(async () => {
    const msg = message;
    setMessage('');
    await dispatch(onboardingActions.sendUserMessage(msg));
  }, [publishing, message]);

  return (
    <Container>
      <PublishingDisclaimer style={{ opacity: publishing ? 1 : 0 }}>
        <Spin size="small" spinning />
        {formatMessage({ id: 'WorkingOnIt' })}...
      </PublishingDisclaimer>
      <Content>
        <InputHolder>
          <Input
            value={message}
            placeholder={formatMessage({ id: 'TypeYourMessageHere' })}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            onKeyDown={async (e) => {
              if (e.key === 'Enter' && message.trim().length && !publishing) {
                e.preventDefault();
                await publishMessage();
              }
            }}
          />
        </InputHolder>
        <SendBtn
          type="button"
          disabled={publishing || starting}
          onClick={async () => {
            await publishMessage();
          }}
        >
          <ArrowUp />
        </SendBtn>
      </Content>
    </Container>
  );
};

export default SendOnboardingAssistantChatMessage;
