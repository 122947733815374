export enum AuditBuilderPage {
  TemplateSetup,
  QuestionSetSetup,
  Build,
  EditSection,
  Preview,
  ActionTemplates,
  PdfReport,
  QuestionSetActiveTemplates,
}
