import React from 'react';

import { Container, Content, Footer } from './styled';

import Header from '@components/account/AppLayout/MobileAndTableLayout/Header/Header';
import CopyrightAndLocale from '@components/account/AppLayout/CopyrightAndLocale/CopyrightAndLocale';
import SharedModals from '@components/account/AppLayout/DesktopLayout/SharedModals';
import Announcement from '@components/shared/Announcement/Announcement';

interface IProps {}

const MobileAndTabletLayout: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
}) => {
  return (
    <Container>
      <Header />
      <Announcement />
      <Content>{children}</Content>
      <Footer>
        <CopyrightAndLocale />
      </Footer>
      <SharedModals />
    </Container>
  );
};

export default MobileAndTabletLayout;
