import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px -2px 0 35px;
`;

export const Button = styled.button`
  display: block;
  padding: 2px 8px;
  font-size: 14px;
  line-height: 21px;
  border-radius: 5px;
  margin: 0 2px 4px;
  border: 0;
  background: #31bbe71a;
  color: ${colors.blue2};
  transition: all 0.2s ease-in-out;

  ${({ selected }: { selected: boolean }) =>
    selected
      ? `
    cursor: default;
    background: ${colors.blue2};
    color: ${colors.white};
  `
      : `
    background: #31bbe71a;
    color: ${colors.blue2};
  `}
`;
