import React from 'react';

import { useAppDispatch } from '@trends/shared/store';
import { accountActions } from '@store';

import ErrorBoundary from '@repo/shared/components/ErrorBoundary/ErrorBoundary';

interface IProps {}

const ChunkErrorBoundary: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
}) => {
  const dispatch = useAppDispatch();

  return (
    <ErrorBoundary
      onChunkError={() => {
        dispatch(accountActions.toggleNewVersionReleasedModal(true));
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ChunkErrorBoundary;
