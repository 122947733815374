import React, { useLayoutEffect } from 'react';
import { X } from 'react-feather';

import { Container, CloseButton } from './styled';
import { useOpacityTransition, useScrollBlock } from '@repo/shared/hooks';

interface IProps {
  opened: boolean;
  zIndex?: number;
  onClose?: () => void;
}

const MobileFullScreenModal: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  opened,
  zIndex,
  onClose,
}) => {
  const transition = useOpacityTransition(opened);

  const [blockScroll, allowScroll] = useScrollBlock('auto hidden');

  useLayoutEffect(() => {
    let intervalId: any;

    if (opened) {
      intervalId = setInterval(() => {
        blockScroll();
      }, 200);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        allowScroll();
      }
    };
  }, [opened]);

  return (
    <>
      {transition.map(({ item, props, key }) => {
        if (item) {
          return (
            <Container
              key={key}
              style={{
                zIndex: zIndex || 1000,
                ...props,
              }}
            >
              {!!onClose && (
                <CloseButton type="button" onClick={onClose}>
                  <X />
                </CloseButton>
              )}
              {children}
            </Container>
          );
        }

        return null;
      })}
    </>
  );
};

export default MobileFullScreenModal;
