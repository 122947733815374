export enum FileSourceType {
  AuditItemFile = 0,
  AuditItemSignature = 1,
  AuditSignature = 2,
  CorrectiveActionFile = 3,
  CorrectiveActionCommentFile = 4,
  TemplateInformationFile = 5,
  CompanyFile = 6,
  IssueFile = 7,
}

export enum FileOrigin {
  Gallery = 0,
  Camera = 1,
}

export enum ImageGalleryMode {
  Slider,
  Crop,
  Draw,
}

export enum ThumbSize {
  xs = 48,
  md = 76,
  lg = 150,
}

export enum UploadStatus {
  Pending,
  Uploading,
  Done,
  Error,
}

export enum FileStatus {
  WaitingUpload = 0,
  Processing = 1,
  Processed = 2,
  Error = 3,
  PreviewReady = 4,
  Offline = 5,
}

export enum DrawingObjectType {
  Line,
  Rectangle,
  Circle,
  Arrow,
  Text,
  Eraser,
}

export enum DrawingSize {
  Thin = 3,
  Medium = 6,
  Thick = 9,
}

export enum DrawingColor {
  Blue,
  Orange,
  Red,
  Green,
}

export enum MimeType {
  png = 'image/png',
  gif = 'image/gif',
  jpeg = 'image/jpeg',
  heic = 'image/heic',
  svg = 'image/svg+xml',
  tiff = 'image/tiff',
  webp = 'image/webp',
  bmp = 'image/bmp',
  ico = 'image/vnd.microsoft.icon',
  mp3 = 'audio/mpeg',
  wav = 'audio/wav',
  ogg = 'audio/ogg',
  mp4 = 'video/mp4',
  webm = 'video/webm',
  avi = 'video/x-msvideo',
  avi2 = 'video/avi',
  avi3 = 'video/msvideo',
  mpeg = 'video/mpeg',
  mov = 'video/quicktime',
  pdf = 'application/pdf',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt = 'application/vnd.ms-powerpoint',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  json = 'application/json',
  xml = 'application/xml',
  csv = 'text/csv',
  plain = 'text/plain',
  html = 'text/html',
  css = 'text/css',
}
