import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import AntSelect, { SelectProps } from 'antd/es/select';
import styled from 'styled-components';

import { generalSelectors } from '@store';
import { colors } from '@repo/shared/config';
import { date, dateInTimeZone } from '@utils';
import { ITimeZone } from '@repo/shared/types';

import Select from '@components/shared/ant/Select/Select';

const { Option } = AntSelect;

const OptionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TZName = styled.div`
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;
  flex-grow: 1;
`;

const TZOffset = styled.div`
  font-size: 10px;
  font-weight: 600;
  text-align: right;
  color: ${colors.gray8};
`;

interface IProps extends SelectProps<any> {
  width?: string;
  extraOptions?: { id: string | number | null; name: string }[];
  e2eDataAttr?: string;
}

const now = date().format();

const TimeZonesSelect: React.FC<React.PropsWithChildren<IProps>> =
  React.forwardRef((props, _) => {
    const { loading, data } = useSelector(generalSelectors.getTimeZones);
    const { formatMessage } = useIntl();

    return (
      <Select
        {...props}
        placeholder={
          loading ? `${formatMessage({ id: 'loading' })}...` : props.placeholder
        }
        value={loading ? [] : props.value}
        showSearch
        filterOption={(inputValue: string, option: any) =>
          option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
        }
        disabled={loading}
      >
        {(data || []).map(({ ianaName, text }: ITimeZone) => (
          <Option value={ianaName} key={ianaName}>
            <OptionContent>
              <TZName>{text}</TZName>
              <TZOffset>
                {dateInTimeZone(now, ianaName).format('[GMT]Z')}
              </TZOffset>
            </OptionContent>
          </Option>
        ))}
      </Select>
    );
  });

export default TimeZonesSelect;
