import styled from 'styled-components';

import { BREAKPOINTS, colors } from '@repo/shared/config';
import { hexToRGB } from '@repo/shared/utils';
import rightArrow from './right-arrow.svg';

export const Container = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 740px;
  padding: 60px 20px 30px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const ScrollContainer = styled.div`
  flex-grow: 1;
  font-size: 14px;
  margin-right: -15px;

  @media (min-width: ${BREAKPOINTS.LG}px) {
    margin-right: -110px;
  }

  @media (min-width: ${BREAKPOINTS.XL}px) {
    margin-right: -190px;
  }
`;

export const Content = styled.div`
  padding-right: 20px;

  @media (min-width: ${BREAKPOINTS.LG}px) {
    padding-right: 110px;
  }

  @media (min-width: ${BREAKPOINTS.XL}px) {
    padding-right: 190px;
  }
`;

export const Header = styled.div`
  margin-bottom: 30px;

  @media (min-width: ${BREAKPOINTS.LG}px) {
    margin-bottom: 64px;
  }
`;

export const OpenOnboardingAssistantBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 16px 16px 16px 20px;
  color: ${colors.white};
  background: ${colors.blue2};
  border: 0;
  border-radius: 5px;
  font-size: 13px;
  text-transform: uppercase;
`;

export const HeaderTitle = styled.h3`
  margin-bottom: 6px;
  font-size: 28px;
`;

export const ContentTitle = styled.h3`
  margin-bottom: 6px;
  font-size: 24px;
`;

export const SubTitle = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: ${colors.gray8};
`;

export const Text = styled.div`
  font-size: 14px;
  margin-bottom: 19px;
  color: ${colors.gray8};
`;

export const ButtonsRow = styled.div`
  margin-bottom: 28px;
`;

export const Footer = styled.div`
  text-align: center;
  color: ${colors.gray7};
  font-size: 12px;
  margin-bottom: 20px;
`;

export const OnboardingAssistantBtnTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 6px;
`;

export const OnboardingAssistantBtnSubTitle = styled.div`
  font-weight: 500;
  font-size: 8px;
  text-transform: uppercase;
  line-height: 1;
`;

export const OnboardingAssistantBtnIcon = styled.div`
  margin-left: 16px;
  width: 32px;
  height: 32px;
`;

export const StartSampleAuditBtn = styled.button`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  color: ${colors.white};
  background: ${colors.blue2};
  border: 0;
  border-radius: 5px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;

  &:after {
    display: block;
    content: '';
    width: 22px;
    height: 22px;
    margin-left: 15px;
    background:
      url(${rightArrow}) no-repeat center center,
      ${hexToRGB(colors.white, 0.2)};
    background-size:
      55% 55%,
      100% 100%;
    border-radius: 100%;
  }
`;
