import styled from 'styled-components';

import { colors } from '../../config';

export const Container = styled.div`
  position: relative !important;
` as any;

export const Wrapper = styled.div`
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
` as any;

export const Scroller = styled.div`
  position: absolute !important;
  scroll-behavior: smooth;
` as any;

export const TrackX = styled.div`
  display: none;
` as any;

export const ThumbX = styled.div`
  display: none;
` as any;

export const TrackYEl = styled.div`
  position: absolute;
  border-radius: 4px;
  width: 6px;
  top: 0.32px;
  right: 0.32px;
  bottom: 0.32px;
  background: none;
  z-index: 10;
` as any;

export const ThumbYEl = styled.div`
  background: ${colors.gray5};
  cursor: pointer;
  border-radius: 4px;
` as any;
