import { AuditTemplateSuggestion } from '@domain/AuditTemplates/AuditTemplateSuggestion';
import { AuditTemplateDetails } from '@domain/AuditTemplates/AuditTemplateDetails';
import {
  createQuestionItem,
  createRootItem,
  createSectionItem,
} from '@application/AuditBuilder/utils/createTemplateItem';
import { AnswerType, AuditTemplateType, ItemType } from '@repo/shared/enums';
import { getAuditTemplateBase } from '@application/AuditBuilder/utils/getAuditTemplateBase';
import { IScore } from '@repo/shared/types';
import { TemplateItem } from '@domain/AuditTemplates/TemplateItem';
import { IRootState } from '@src/core/frameworks/redux';

export function createTemplateFromSuggestion(
  {
    templateType,
    suggestion,
    scoreSystem,
  }: {
    templateType: AuditTemplateType;
    suggestion: AuditTemplateSuggestion;
    scoreSystem: IScore;
  },
  state: IRootState
): AuditTemplateDetails {
  const root = createRootItem();

  const items: TemplateItem[] = [root];

  for (let i = 0; i < suggestion.data.length; i++) {
    const sectionSuggestion = suggestion.data[i];

    const section = createSectionItem({
      parentId: root.id,
      text: sectionSuggestion.sectionName,
      index: i,
    });

    root.childrenIds.push(section.id);
    items.push(section);

    for (let j = 0; j < sectionSuggestion.items.length; j++) {
      const answerType =
        templateType === AuditTemplateType.Audit
          ? AnswerType.PassFailButtons
          : AnswerType.Checklist;

      const item = createQuestionItem(
        {
          parentId: section.id,
          itemType: ItemType.Item,
          answerType,
          text: sectionSuggestion.items[j],
          index: j,
        },
        state
      );

      section.childrenIds.push(item.id);
      items.push(item);
    }
  }

  return {
    ...getAuditTemplateBase({
      templateType,
      templateName: suggestion.templateName,
      scoreSystem,
    }),
    data: items.reduce<Record<string, TemplateItem>>((acc, item) => {
      const a = acc;
      a[item.id] = item;
      return a;
    }, {}),
  };
}
