export enum ItemType {
  Root = 0,
  Section = 1,
  Item = 2,
  ConditionalItem = 3,
  Condition = 4,
  QuestionSet = 5,
}

export enum AnswerType {
  PassFailButtons,
  Numeric,
  Checklist,
  YesNoButtons,
  Text,
  Dropdown,
  Temperature,
  Slider,
}

export enum ChecklistOptionName {
  Complete = 'Complete',
  Incomplete = 'Incomplete',
}

export enum TemperatureUnit {
  C,
  F,
}

export enum IntervalType {
  NotSet,
  LessThan,
  LessThanOrEqualTo,
  EqualTo,
  GreaterThan,
  GreaterThanOrEqualTo,
}

export enum IntervalFieldType {
  FromIntervalType = 'fromIntervalType',
  FromNumber = 'fromNumber',
  ToIntervalType = 'toIntervalType',
  ToNumber = 'toNumber',
  Points = 'points',
  MarkAsFailed = 'markAsFailed',
}

export enum TriggerType {
  Passed = 0,
  Failed = 1,
  IsAnswered = 2,
}

export enum ActionType {
  RequirePhoto = 0,
  RequireAction = 1,
  AddItems = 2,
}
