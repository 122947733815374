// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export function sendVWOEvent() {
  window.VWO = window.VWO || [];
  window.VWO.event =
    VWO.event ||
    function () {
      VWO.push(['event'].concat([].slice.call(arguments)));
    };
  window.VWO.event('formSubmitEvent');
}
