import React, { useEffect, useState } from 'react';
import { Calendar, Flag } from 'react-feather';
import YouTube, { YouTubeEvent, YouTubePlayer } from 'react-youtube';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Row from 'antd/es/row';
import Col from 'antd/es/col';

import {
  ButtonsRow,
  Container,
  ScrollContainer,
  Content,
  Footer,
  Header,
  OnboardingAssistantBtnIcon,
  OnboardingAssistantBtnSubTitle,
  OnboardingAssistantBtnTitle,
  StartSampleAuditBtn,
  OpenOnboardingAssistantBtn,
  SubTitle,
  Text,
  HeaderTitle,
  ContentTitle,
} from './styled';
import { AIWhite } from '@icons';
import { accountActions, accountSelectors } from '@store';
import { AnalyticsEvent } from '@repo/shared/enums';
import BlueIconButton from '@presentation/Onboarding/OnboardingModal/BlueIconButton/BlueIconButton';
import { config } from '@repo/shared/config';
import { e2eTestElements } from '@config';
import { useAppDispatch } from '@hooks';
import { onboardingActions } from '@application/Onboarding/store/onboardingActions';
import { OnboardingModalMode } from '@application/Onboarding/enums/OnboardingModalMode';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { useLgMediaQuery } from '@repo/shared/hooks';
import { Logger } from '@repo/shared/services';

import Scrollbar from '@repo/shared/components/Scrollbar/Scrollbar';

interface IProps {}

const WelcomeContent: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const company = useSelector(accountSelectors.getCompany);

  const showSelfGuidedTourButton = useLgMediaQuery();

  const [videoPlayer, setVideoPlayer] = useState<YouTubePlayer | null>(null);

  useEffect(() => {
    return () => {
      if (videoPlayer && typeof videoPlayer.stopVideo === 'function') {
        try {
          videoPlayer.stopVideo();
        } catch (e) {
          Logger.captureException(e);
        }
      }
    };
  }, [videoPlayer]);

  return (
    <Container>
      <Header>
        <Row gutter={[14, 14]} justify="space-between">
          <Col>
            <HeaderTitle>{formatMessage({ id: 'Welcome' })}!</HeaderTitle>
            <SubTitle>
              {formatMessage({ id: 'GetStartedWithYourFirstAudit' })}!
            </SubTitle>
          </Col>
          {company.hasSampleData && (
            <Col>
              <StartSampleAuditBtn
                type="button"
                data-e2e={
                  e2eTestElements.account.welcomeModal.startSampleAuditButton
                }
                onClick={() => {
                  dispatch(onboardingActions.toggleOnboardingModal(null));
                  dispatch(auditsActions.toggleStartSampleAuditModal(true));
                }}
              >
                {formatMessage({ id: 'StartSampleAudit' })}
              </StartSampleAuditBtn>
              {/*<OpenOnboardingAssistantBtn*/}
              {/*  type="button"*/}
              {/*  onClick={() => {*/}
              {/*    dispatch(*/}
              {/*      onboardingActions.toggleOnboardingModal(*/}
              {/*        OnboardingModalMode.AIAssistant*/}
              {/*      )*/}
              {/*    );*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <div>*/}
              {/*    <OnboardingAssistantBtnTitle>*/}
              {/*      {formatMessage({ id: 'StartYourFirstAudit' })}*/}
              {/*    </OnboardingAssistantBtnTitle>*/}
              {/*    <OnboardingAssistantBtnSubTitle>*/}
              {/*      {formatMessage({ id: 'WithAIAssistant' })}*/}
              {/*    </OnboardingAssistantBtnSubTitle>*/}
              {/*  </div>*/}
              {/*  <OnboardingAssistantBtnIcon>*/}
              {/*    <AIWhite />*/}
              {/*  </OnboardingAssistantBtnIcon>*/}
              {/*</OpenOnboardingAssistantBtn>*/}
            </Col>
          )}
        </Row>
      </Header>
      <ScrollContainer>
        <Scrollbar>
          <Content>
            <ContentTitle>
              {formatMessage({ id: 'LearnTheBasics' })}!
            </ContentTitle>
            <SubTitle>
              {formatMessage({
                id: 'GetStartedByWatchingQuickOverviewOfThePlatform',
              })}
              !
            </SubTitle>
            <div style={{ height: 315, marginBottom: '15px' }}>
              <YouTube
                videoId="WmwN592TAlQ"
                style={{ margin: '26px 0 32px' }}
                opts={{ width: '100%', maxWidth: '560px', height: 315 }}
                onReady={(event: YouTubeEvent) => {
                  setVideoPlayer(event.target);
                }}
                onPlay={() => {
                  dispatch(
                    accountActions.sendAnalyticsEvent(
                      AnalyticsEvent.LearnTheBasicsVideoClicked
                    )
                  );
                }}
              />
            </div>
            <Text>
              {formatMessage({ id: 'ThenTryOurConciergeOnboarding' })}
            </Text>
            <ButtonsRow>
              <Row gutter={[14, 14]}>
                <Col>
                  <BlueIconButton
                    onClick={() => {
                      dispatch(
                        accountActions.sendAnalyticsEvent(
                          AnalyticsEvent.LiveConsultationClicked
                        )
                      );

                      window.open(config.urls.interactiveDemo, '_blank');
                    }}
                    icon={<Calendar />}
                    desc="Calendly"
                    e2eDataAttr={
                      e2eTestElements.account.welcomeModal
                        .scheduleLiveConsultButton
                    }
                    text={formatMessage({ id: 'ScheduleLiveConsultation' })}
                  />
                </Col>
                {showSelfGuidedTourButton && (
                  <Col>
                    <BlueIconButton
                      onClick={() => {
                        dispatch(onboardingActions.toggleOnboardingModal(null));
                        dispatch(accountActions.selfGuidedTour.start());
                      }}
                      icon={<Flag />}
                      desc={formatMessage({ id: '3Minutes' })}
                      text={formatMessage({ id: 'DoSelfGuidedTour' })}
                      e2eDataAttr={
                        e2eTestElements.account.welcomeModal
                          .doSelfGuidedTourButton
                      }
                    />
                  </Col>
                )}
              </Row>
            </ButtonsRow>
            <Footer>
              {formatMessage({
                id: 'TipYouCanReturnHereAtAnyTimeFromTheSideMenu',
              })}
            </Footer>
          </Content>
        </Scrollbar>
      </ScrollContainer>
    </Container>
  );
};

export default WelcomeContent;
