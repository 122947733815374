import styled from 'styled-components';
import { animated } from 'react-spring';

import { colors } from '@repo/shared/config';

export const Container = styled(animated.div)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: ${({ theme }: { theme: 'light' | 'dark' }) =>
    theme === 'light' ? colors.white : colors.blue7};
  z-index: 1;
`;
