import React, { useMemo, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, RouteObject, useLocation } from 'react-router-dom';

import {
  PerformanceReportType,
  Permission,
  RoleType,
} from '@repo/shared/enums';
import { accountSelectors } from '@store';
import { routes } from '@config';
import { usePermission } from '@hooks/account';

import NavigateWithId from '@components/account/AppRoutes/NavigateWithId';
import NavyBlueContainer from '@components/shared/NavyBlueContainer/NavyBlueContainer';
import ChangeDefaultPassword from '@components/account/AuthForms/ChangeDefaultPassword';
import NonProtectedRoute from '@components/account/AppRoutes/NonProtectedRoute';
import ResetPassword from '@components/account/AuthForms/ResetPassword/ResetPassword';
import SignIn from '@components/account/AuthForms/SignIn';
import SetNewPassword from '@components/account/AuthForms/ResetPassword/SetNewPassword';
import ProtectedRoute from '@components/account/AppRoutes/ProtectedRoute';
import NotFound from '@components/shared/NotFound/NotFound';
import SignUpCallback from '@components/account/AuthForms/SignUp/SignUpCallback';
import SignUp from '@components/account/AuthForms/SignUp/SignUp';

import {
  MyActions,
  ActionsTags,
  ActionTemplates,
  AuditTemplates,
  Accounts,
  Attributes,
  AttributeAssignments,
  AttributeDetails,
  AuditObjectAccess,
  AuditObjectAttributes,
  AuditObjectDetails,
  AuditObjectGroupDetails,
  AuditObjectGroupAssignments,
  AuditObjectGroupQrCodes,
  AuditObjectGroups,
  AuditObjects,
  AuditObjectQrCodes,
  AuditObjectsGroups,
  AuditsTags,
  AuditPerformanceReport,
  PendingAudits,
  CompletedAudits,
  CompletedActions,
  SchedulePlanPeriodDetails,
  Schedules,
  ExpiredActions,
  SchedulePlans,
  SchedulePlanDetails,
  CreateIssuePublicPage,
  CreateIssuePublicPageError,
  MyIssues,
  ExpiredAudits,
  IssueTypes,
  IssueTypeDetails,
  IssueTypeRoot,
  IssueTypeQrCodes,
  IssueTypeDetailsQrCodes,
  CustomResponseTypes,
  CustomReport,
  Dashboard,
  EditProfile,
  ResolvedIssues,
  PendingIssues,
  GeneralSettings,
  HistoricalPerformanceReport,
  Integrations,
  Invoices,
  ItemAnalysisReport,
  ItemsTags,
  QuestionSets,
  JobTitles,
  PendingActions,
  Payment,
  RealTimePerformanceReport,
  Roles,
  Scores,
  PublicAuditReport,
  SummaryReport,
  Notifications,
  UserDetails,
  Users,
  UserAccess,
  UserGroupDetails,
  UserGroups,
  UsersGroups,
  UserGroupAssignments,
  Subscription,
  ImportTasks,
  ExportTasks,
} from './lazyRoutes';

import Loader from '@repo/shared/components/Loader';

export function useAppRoutes(): RouteObject[] {
  const location = useLocation();

  const user = useSelector(accountSelectors.getCurrentUser);
  const currentUserRoleType = user?.role?.roleType;
  const isAuditeeOrObserver =
    currentUserRoleType === RoleType.Observer ||
    currentUserRoleType === RoleType.Auditee;
  const isAuditor = currentUserRoleType === RoleType.Auditor;
  const appConfiguration = useSelector(accountSelectors.getAppConfiguration);

  const [
    canDoAudits,
    canAssignAudits,
    canManageAudits,
    canScheduleAudits,
    canViewAuditsResults,
    canManageTemplates,
    canManageBilling,
    canViewCorrectiveActions,
    canViewAnalytics,
    canManageGeneralSetup,
    canDoCorrectiveActions,
    canViewIssues,
    canManageIssueTypes,
  ] = usePermission([
    Permission.CanDoAudits,
    Permission.CanAssignAudits,
    Permission.CanManageAudits,
    Permission.CanScheduleAudits,
    Permission.CanViewAuditResults,
    Permission.CanManageAuditTemplates,
    Permission.CanManageBilling,
    Permission.CanViewCorrectiveActions,
    Permission.CanViewAnalytics,
    Permission.CanManageGeneralSetup,
    Permission.CanDoCorrectiveActions,
    Permission.CanViewIssues,
    Permission.CanManageIssueTypes,
  ]);

  return useMemo(() => {
    const canViewAuditsPages =
      canDoAudits || canAssignAudits || canManageAudits || canScheduleAudits;
    const canViewSetupPages = !isAuditeeOrObserver && !isAuditor;

    let appRoutes: RouteObject[] = [
      {
        path: routes.createIssue,
        element: (
          <Suspense fallback={<Loader />}>
            <CreateIssuePublicPage />
          </Suspense>
        ),
      },
      {
        path: routes.createIssueError,
        element: (
          <Suspense fallback={<Loader />}>
            <CreateIssuePublicPageError />
          </Suspense>
        ),
      },
      {
        path: routes.publicAuditReport,
        element: (
          <Suspense fallback={<Loader />}>
            <PublicAuditReport />
          </Suspense>
        ),
      },
      {
        path: routes.signUpCallback,
        element: (
          <Suspense fallback={<Loader />}>
            <SignUpCallback />
          </Suspense>
        ),
      },
      {
        path: routes.signin,
        element: (
          <NonProtectedRoute>
            <SignIn />
          </NonProtectedRoute>
        ),
      },
      {
        path: routes.adminSignIn,
        element: (
          <NonProtectedRoute>
            <SignIn adminSignIn />
          </NonProtectedRoute>
        ),
      },
      ...(appConfiguration.allowSignUp
        ? [
            {
              path: routes.signUp,
              element: (
                <NonProtectedRoute>
                  <SignUp />
                </NonProtectedRoute>
              ),
            },
          ]
        : []),
      {
        path: routes.resetPassword,
        element: (
          <NonProtectedRoute>
            <ResetPassword />
          </NonProtectedRoute>
        ),
      },
      {
        path: routes.setNewPassword,
        element: (
          <NonProtectedRoute>
            <SetNewPassword />
          </NonProtectedRoute>
        ),
      },
      {
        path: routes.changePassword,
        element: (
          <ProtectedRoute Layout={NavyBlueContainer} skipChangePasswordCheck>
            <ChangeDefaultPassword />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.dashboard,
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.notifications,
        element: (
          <ProtectedRoute>
            <Notifications />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.profile,
        element: (
          <ProtectedRoute>
            <EditProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.notFound,
        element: (
          <ProtectedRoute>
            <NotFound />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.tagsItems,
        element: (
          <ProtectedRoute>
            <ItemsTags />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.tagsAudits,
        element: (
          <ProtectedRoute>
            <AuditsTags />
          </ProtectedRoute>
        ),
      },
      {
        path: routes.tagsActions,
        element: (
          <ProtectedRoute>
            <ActionsTags />
          </ProtectedRoute>
        ),
      },
      ...(appConfiguration.allowSignUp
        ? [
            {
              path: routes.accounts,
              element: (
                <ProtectedRoute>
                  <Accounts />
                </ProtectedRoute>
              ),
            },
          ]
        : []),
      {
        path: routes.billing,
        element: <Navigate to={routes.subscription} replace />,
      },
      {
        path: routes.correctiveActions,
        element: <Navigate to={routes.pendingActions} replace />,
      },
      {
        path: routes.settings,
        element: <Navigate to={routes.generalSettings} replace />,
      },
      {
        path: routes.auditorPerformanceReport,
        element: (
          <Navigate to={routes.auditorPerformanceReportRealTime} replace />
        ),
      },
      {
        path: routes.audits,
        element: <Navigate to={routes.pendingAudits} replace />,
      },
      {
        path: routes.tags,
        element: <Navigate to={routes.tagsAudits} replace />,
      },
    ];

    if (canViewSetupPages) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.users.list,
          element: (
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.users.jobTitles,
          element: (
            <ProtectedRoute>
              <JobTitles />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.users.userDetails,
          element: (
            <ProtectedRoute>
              <UserDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.users.userGroups,
          element: (
            <ProtectedRoute>
              <UserGroups />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.users.userAccess,
          element: (
            <ProtectedRoute>
              <UserAccess />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.users.user,
          element: <Navigate to={routes.users.userDetails} replace />,
        },
        {
          path: routes.users.groupsList,
          element: (
            <ProtectedRoute>
              <UsersGroups />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.users.groupDetails,
          element: (
            <ProtectedRoute>
              <UserGroupDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.users.groupAssignments,
          element: (
            <ProtectedRoute>
              <UserGroupAssignments />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.list,
          element: (
            <ProtectedRoute>
              <AuditObjects />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.auditObjectDetails,
          element: (
            <ProtectedRoute>
              <AuditObjectDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.auditObjectGroups,
          element: (
            <ProtectedRoute>
              <AuditObjectGroups />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.auditObjectAttributes,
          element: (
            <ProtectedRoute>
              <AuditObjectAttributes />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.auditObjectAccess,
          element: (
            <ProtectedRoute>
              <AuditObjectAccess />
            </ProtectedRoute>
          ),
        },
        ...(canManageIssueTypes
          ? [
              {
                path: routes.auditObjects.auditObjectQRCodes,
                element: (
                  <ProtectedRoute>
                    <AuditObjectQrCodes />
                  </ProtectedRoute>
                ),
              },
            ]
          : []),
        {
          path: routes.auditObjects.auditObject,
          element: (
            <Navigate to={routes.auditObjects.auditObjectDetails} replace />
          ),
        },
        {
          path: routes.auditObjects.groupsList,
          element: (
            <ProtectedRoute>
              <AuditObjectsGroups />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.groupDetails,
          element: (
            <ProtectedRoute>
              <AuditObjectGroupDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.groupAssignments,
          element: (
            <ProtectedRoute>
              <AuditObjectGroupAssignments />
            </ProtectedRoute>
          ),
        },
        ...(canManageIssueTypes
          ? [
              {
                path: routes.auditObjects.groupQRCodes,
                element: (
                  <ProtectedRoute>
                    <AuditObjectGroupQrCodes />
                  </ProtectedRoute>
                ),
              },
            ]
          : []),
        {
          path: routes.auditObjects.attributeAssignments,
          element: (
            <ProtectedRoute>
              <AttributeAssignments />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.attributeDetails,
          element: (
            <ProtectedRoute>
              <AttributeDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjects.attributesList,
          element: (
            <ProtectedRoute>
              <Attributes />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.roles,
          element: (
            <ProtectedRoute>
              <Roles />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.scores,
          element: (
            <ProtectedRoute>
              <Scores />
            </ProtectedRoute>
          ),
        },
      ];
    }

    if (canViewAuditsPages) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.schedules,
          element: (
            <ProtectedRoute>
              <Schedules />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.scheduleDetails,
          element: (
            <ProtectedRoute>
              <Schedules />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.schedulesLegacy,
          element: <Navigate to={routes.schedules} replace />,
        },
        {
          path: routes.advancedSchedules,
          element: (
            <ProtectedRoute>
              <SchedulePlans />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.advancedScheduleDetails,
          element: (
            <ProtectedRoute>
              <SchedulePlanDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.advancedSchedulePeriodDetails,
          element: (
            <ProtectedRoute>
              <SchedulePlanPeriodDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.pendingAudit,
          element: (
            <ProtectedRoute>
              <PendingAudits />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.myAudits,
          element: (
            <ProtectedRoute>
              <PendingAudits />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.pendingAudits,
          element: (
            <ProtectedRoute>
              <PendingAudits />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.myAudit,
          element: <NavigateWithId to={routes.pendingAudit} />,
        },
      ];
    }

    if (canViewAuditsPages || canViewAuditsResults) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.completedAudits,
          element: (
            <ProtectedRoute>
              <CompletedAudits />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.completedAudit,
          element: (
            <ProtectedRoute>
              <CompletedAudits />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditReport,
          element: <NavigateWithId to={routes.completedAudit} />,
        },
        {
          path: routes.expiredAudits,
          element: (
            <ProtectedRoute>
              <ExpiredAudits />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.expiredAudit,
          element: (
            <ProtectedRoute>
              <ExpiredAudits />
            </ProtectedRoute>
          ),
        },
      ];
    }

    if (canViewCorrectiveActions || canDoCorrectiveActions) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.pendingActions,
          element: (
            <ProtectedRoute>
              <PendingActions />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.pendingAction,
          element: (
            <ProtectedRoute>
              <PendingActions />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.myActions,
          element: (
            <ProtectedRoute>
              <MyActions />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.myAction,
          element: (
            <ProtectedRoute>
              <MyActions />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.completedActions,
          element: (
            <ProtectedRoute>
              <CompletedActions />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.completedAction,
          element: (
            <ProtectedRoute>
              <CompletedActions />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.expiredActions,
          element: (
            <ProtectedRoute>
              <ExpiredActions />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.expiredAction,
          element: (
            <ProtectedRoute>
              <ExpiredActions />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.actionDetails,
          element: <NavigateWithId to={routes.pendingAction} />,
        },
      ];
    }

    if (canManageTemplates) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.auditTemplates,
          element: (
            <ProtectedRoute>
              <AuditTemplates />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.builderDraft,
          element: (
            <ProtectedRoute>
              <AuditTemplates />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.builderPublished,
          element: (
            <ProtectedRoute>
              <AuditTemplates />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.builderNewDraft,
          element: (
            <ProtectedRoute>
              <AuditTemplates />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.actionTemplates,
          element: (
            <ProtectedRoute>
              <ActionTemplates />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.questionSets,
          element: (
            <ProtectedRoute>
              <QuestionSets />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.editQuestionSet,
          element: (
            <ProtectedRoute>
              <QuestionSets />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.createQuestionSet,
          element: (
            <ProtectedRoute>
              <QuestionSets />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.customResponseTypes,
          element: (
            <ProtectedRoute>
              <CustomResponseTypes />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.templateComponents,
          element: <Navigate to={routes.questionSets} replace />,
        },
        {
          path: routes.library,
          element: <Navigate to={routes.auditTemplates} replace />,
        },
      ];
    }

    if (canViewAnalytics) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.summaryReport,
          element: (
            <ProtectedRoute>
              <SummaryReport />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditPerformanceReport,
          element: (
            <ProtectedRoute>
              <AuditPerformanceReport />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.customReport,
          element: (
            <ProtectedRoute>
              <CustomReport />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.itemAnalysisReport,
          element: (
            <ProtectedRoute>
              <ItemAnalysisReport />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditorPerformanceReportHistorical,
          element: (
            <ProtectedRoute>
              <HistoricalPerformanceReport
                type={PerformanceReportType.Auditor}
              />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditorPerformanceReportRealTime,
          element: (
            <ProtectedRoute>
              <RealTimePerformanceReport type={PerformanceReportType.Auditor} />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjectPerformanceReportHistorical,
          element: (
            <ProtectedRoute>
              <HistoricalPerformanceReport
                type={PerformanceReportType.Object}
              />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.auditObjectPerformanceReportRealTime,
          element: (
            <ProtectedRoute>
              <RealTimePerformanceReport type={PerformanceReportType.Object} />
            </ProtectedRoute>
          ),
        },
      ];
    }

    if (canManageBilling) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.subscription,
          element: (
            <ProtectedRoute>
              <Subscription />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.invoices,
          element: (
            <ProtectedRoute>
              <Invoices />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.payment,
          element: (
            <ProtectedRoute>
              <Payment />
            </ProtectedRoute>
          ),
        },
      ];
    }

    if (canManageIssueTypes) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.issueTypes,
          element: (
            <ProtectedRoute>
              <IssueTypes />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.issueTypeDetails,
          element: (
            <ProtectedRoute>
              <IssueTypeRoot />
            </ProtectedRoute>
          ),
          children: [
            {
              index: true,
              element: <IssueTypeDetails />,
            },
            {
              path: 'qr-codes',
              element: <IssueTypeDetailsQrCodes />,
            },
          ],
        },
        {
          path: routes.createIssueType,
          element: (
            <ProtectedRoute>
              <IssueTypeDetails />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.issuesQrCodes,
          element: (
            <ProtectedRoute>
              <IssueTypeQrCodes />
            </ProtectedRoute>
          ),
        },
      ];
    }

    if (canViewIssues) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.pendingIssues,
          element: (
            <ProtectedRoute>
              <PendingIssues />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.pendingIssue,
          element: (
            <ProtectedRoute>
              <PendingIssues />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.myIssues,
          element: (
            <ProtectedRoute>
              <MyIssues />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.myIssue,
          element: (
            <ProtectedRoute>
              <MyIssues />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.archiveIssues,
          element: (
            <ProtectedRoute>
              <ResolvedIssues />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.archiveIssue,
          element: (
            <ProtectedRoute>
              <ResolvedIssues />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.issueDetails,
          element: <NavigateWithId to={routes.pendingIssue} />,
        },
      ];
    }

    if (canManageGeneralSetup) {
      appRoutes = [
        ...appRoutes,
        {
          path: routes.generalSettings,
          element: (
            <ProtectedRoute>
              <GeneralSettings />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.integration,
          element: (
            <ProtectedRoute>
              <Integrations />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.import,
          element: (
            <ProtectedRoute>
              <ImportTasks />
            </ProtectedRoute>
          ),
        },
        {
          path: routes.export,
          element: (
            <ProtectedRoute>
              <ExportTasks />
            </ProtectedRoute>
          ),
        },
      ];
    }

    appRoutes = [
      ...appRoutes,
      {
        path: '*',
        element: (
          <Navigate
            to={!!user ? routes.notFound : routes.signin}
            state={{ referrer: location.pathname }}
            replace
          />
        ),
      },
    ];

    return appRoutes;
  }, [
    canDoAudits,
    canAssignAudits,
    canManageAudits,
    canScheduleAudits,
    canViewAuditsResults,
    canManageTemplates,
    canManageBilling,
    canViewCorrectiveActions,
    canViewAnalytics,
    canManageGeneralSetup,
    canDoCorrectiveActions,
  ]);
}
