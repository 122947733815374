import React from 'react';
import { ChevronRight } from 'react-feather';
import { useSelector } from 'react-redux';

import { IAccount } from '@repo/shared/types';
import { Account, Container, Name } from './styled';
import { accountSelectors } from '@store';

import AccountIcon from '@components/account/AppLayout/AccountsSelector/AccountIconContainer/AccountIconContainer';

interface IProps {
  accounts: IAccount[];
  onClick: (payload: { companyId: string; companyName: string }) => void;
  currentAccountDisabled?: boolean;
}

const ModalAccountsList: React.FC<React.PropsWithChildren<IProps>> = ({
  accounts,
  onClick,
  currentAccountDisabled,
}) => {
  const currentCompanyId = useSelector(accountSelectors.getCurrentCompanyId);

  return (
    <Container>
      {accounts.map((account) => {
        const {
          company: { id, name },
        } = account;

        return (
          <Account
            type="button"
            key={id}
            onClick={() => {
              onClick({ companyId: id, companyName: name });
            }}
            disabled={currentAccountDisabled && id === currentCompanyId}
            current={id === currentCompanyId}
          >
            <AccountIcon account={account} />
            <Name>{name}</Name>
            <ChevronRight />
          </Account>
        );
      })}
    </Container>
  );
};

export default ModalAccountsList;
