import { forwardRef, useState, useCallback } from 'react';
import ReactScrollbarsCustom from 'react-scrollbars-custom';

import {
  Container,
  Wrapper,
  Scroller,
  TrackX,
  ThumbX,
  TrackYEl,
  ThumbYEl,
} from './styled';
import { ScrollState } from 'react-scrollbars-custom/dist/types/types';

interface IProps {
  autoHide?: boolean;
  children: React.ReactNode;
  height?: string;
  noScrollX?: boolean;
  noScrollY?: boolean;
  native?: boolean;
  onScroll?: (scrollTop: number) => void;
  onScrollStop?: (scrollValues: ScrollState) => void;
}

const Scrollbar = forwardRef<ReactScrollbarsCustom, IProps>(
  (
    {
      children,
      autoHide = false,
      height = '100%',
      noScrollX,
      noScrollY,
      native,
      onScroll,
      onScrollStop,
    },
    ref: any
  ) => {
    const [isScrolling, setIsScrolling] = useState(false);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const showScrollbar = isScrolling || isMouseOver;

    const onScrollStart = useCallback(() => {
      setIsScrolling(true);
    }, []);
    const onScrollStopHelper = useCallback(() => {
      setIsScrolling(false);
    }, []);
    const onMouseEnter = useCallback(() => {
      setIsMouseOver(true);
    }, []);
    const onMouseLeave = useCallback(() => {
      setIsMouseOver(false);
    }, []);

    return (
      <ReactScrollbarsCustom
        ref={ref}
        style={{ height, width: '100%' }}
        noScrollX={noScrollX}
        noScrollY={noScrollY}
        onScrollStart={onScrollStart}
        onScroll={(scrollValues) => {
          if (onScroll) {
            // @ts-ignore
            onScroll(scrollValues.scrollTop);
          }
        }}
        onScrollStop={(scrollValues) => {
          onScrollStopHelper();

          if (onScrollStop) {
            onScrollStop(scrollValues);
          }
        }}
        noDefaultStyles
        native={native}
        scrollDetectionThreshold={500}
        renderer={({ key, elementRef, ...restProps }) => (
          <Container key={key} {...restProps} ref={elementRef} />
        )}
        wrapperProps={{
          renderer: ({ key, elementRef, ...restProps }) => (
            <Wrapper key={key} {...restProps} ref={elementRef} />
          ),
        }}
        scrollerProps={{
          renderer: ({ key, elementRef, ...restProps }) => (
            <Scroller key={key} {...restProps} ref={elementRef} />
          ),
        }}
        contentProps={{
          renderer: ({ key, elementRef, ...restProps }) => (
            <div key={key} {...restProps} ref={elementRef} />
          ),
        }}
        trackXProps={{
          renderer: ({ key, elementRef, ...restProps }) => (
            <TrackX key={key} {...restProps} ref={elementRef} />
          ),
        }}
        thumbXProps={{
          renderer: ({ key, elementRef, ...restProps }) => (
            <ThumbX key={key} {...restProps} ref={elementRef} />
          ),
        }}
        trackYProps={{
          renderer: ({ key, elementRef, style, ...restProps }) => (
            <TrackYEl
              key={key}
              {...restProps}
              ref={elementRef}
              style={{
                ...style,
                opacity: !autoHide || showScrollbar ? 1 : 0,
                transition: 'opacity 0.4s ease-in-out',
              }}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          ),
        }}
        thumbYProps={{
          renderer: ({ key, elementRef, ...restProps }) => (
            <ThumbYEl key={key} {...restProps} ref={elementRef} />
          ),
        }}
      >
        {children}
      </ReactScrollbarsCustom>
    );
  }
);

export default Scrollbar;
