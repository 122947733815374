import { createSelector, EntityState } from '@reduxjs/toolkit';

import { IRootState } from '@src/core/frameworks/redux';
import { IPagingMeta, ITableFilters } from '@repo/shared/types';
import { QuestionSet } from '@domain/QuestionSets/QuestionSet';
import { questionSetsEntityAdapter } from '@application/QuestionSets/store/entityAdapters';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';
import { QuestionSetDetails } from '@domain/QuestionSets/QuestionSetDetails';
import { QuestionSetDetailsModalTab } from '@application/QuestionSets/enums/QuestionSetDetailsModalTab';
import { QuestionSetTemplateMapping } from '@domain/QuestionSets/QuestionSetTemplateMapping';
import {
  isTemplateConditionalQuestionItem,
  isTemplateConditionItem,
  isTemplateQuestionItem,
  isTemplateSimpleQuestionItem,
  TemplateQuestionItem,
} from '@domain/AuditTemplates/TemplateItem';
import { findRootItemId } from '@application/AuditBuilder/utils/findRootItemId';

const getQuestionSetsFilters = (state: IRootState): ITableFilters =>
  state.questionSets.filters;

const getQuestionSetsData = (
  state: IRootState
): EntityState<QuestionSet, string> => state.questionSets.data;

const getQuestionSets = createSelector(
  [
    (state: IRootState): boolean => state.questionSets.loading,
    (state: IRootState): IPagingMeta | null => state.questionSets.meta,
    (state: IRootState): string | null => state.questionSets.error,
    getQuestionSetsData,
  ],
  (loading, meta, error, data) => ({
    loading,
    error,
    meta,
    data: questionSetsEntityAdapter.getSelectors().selectAll(data),
  })
);

const getQuestionSetsDictionary = createSelector(getQuestionSetsData, (data) =>
  questionSetsEntityAdapter.getSelectors().selectEntities(data)
);

const getConfirmEditQuestionSetModalCandidateId = (
  state: IRootState
): string | null => state.questionSets.editQuestionSetCandidateId;

const getConfirmDeleteQuestionSetModalState = (
  state: IRootState
): DeleteModalState<QuestionSet> => state.questionSets.deleteQuestionSetModal;

const getQuestionSetDetails = (state: IRootState): QuestionSetDetails | null =>
  state.questionSets.questionSetDetails.data;

const getQuestionSetDetailsLoading = (state: IRootState): boolean =>
  state.questionSets.questionSetDetails.loading;

const getQuestionSetDetailsError = (state: IRootState): string | null =>
  state.questionSets.questionSetDetails.error;

const getQuestionSetDetailsModalSetId = (state: IRootState): string | null =>
  state.questionSets.questionSetDetailsModal.questionSetId;

const getQuestionSetDetailsModalTab = (
  state: IRootState
): QuestionSetDetailsModalTab => state.questionSets.questionSetDetailsModal.tab;

const getQuestionSetItems = createSelector(
  getQuestionSetDetails,
  (questionSet) => {
    if (!questionSet?.data) {
      return [];
    }

    const { data: items } = questionSet;

    const rootItemId = findRootItemId(items);

    if (!rootItemId) {
      return [];
    }

    const result: TemplateQuestionItem[] = [];

    function traverse(nodeId: string): void {
      const node = items[nodeId];

      if (!node) return;

      for (const childId of node.childrenIds) {
        const child = items[childId];

        if (!child) {
          continue;
        }

        if (isTemplateQuestionItem(child)) {
          result.push(child);

          if (
            isTemplateConditionalQuestionItem(child) &&
            child.childrenIds.length > 0 &&
            isTemplateConditionItem(items[child.childrenIds[0]])
          ) {
            for (const condChildId of items[child.childrenIds[0]].childrenIds) {
              const condChild = items[condChildId];

              if (isTemplateQuestionItem(condChild)) {
                result.push(condChild);
              }
            }
          }
        } else {
          traverse(childId);
        }
      }
    }

    traverse(rootItemId);

    return result;
  }
);

const getQuestionSetTemplates = (
  state: IRootState
): {
  loading: boolean;
  data: QuestionSetTemplateMapping[];
  error: string | null;
} => state.questionSets.questionSetTemplates;

export const questionSetsSelectors = {
  getQuestionSetsFilters,
  getQuestionSets,
  getQuestionSetsDictionary,
  getConfirmEditQuestionSetModalCandidateId,
  getConfirmDeleteQuestionSetModalState,
  getQuestionSetDetails,
  getQuestionSetDetailsModalSetId,
  getQuestionSetDetailsModalTab,
  getQuestionSetDetailsLoading,
  getQuestionSetDetailsError,
  getQuestionSetItems,
  getQuestionSetTemplates,
};
