import { TemplateItem } from '@domain/AuditTemplates/TemplateItem';
import { transformDtoToTemplateItem } from '@infrastructure/AuditBuilder/transformers/transformDtoToTemplateItem';
import { QuestionSetDetailsDto } from '@infrastructure/QuestionSets/models/QuestionSetDetailsDto';
import { QuestionSetDetails } from '@domain/QuestionSets/QuestionSetDetails';

export function transformQuestionSetDetailsDto(
  dto: QuestionSetDetailsDto
): QuestionSetDetails {
  return {
    ...dto,
    data: Object.keys(dto.data).reduce<Record<string, TemplateItem>>(
      (acc, itemId) => {
        acc[itemId] = transformDtoToTemplateItem(dto.data, itemId);
        return acc;
      },
      {}
    ),
  };
}
