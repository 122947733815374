import React from 'react';

import { AssistantMessage } from '@application/Onboarding/models/OnboardingChatMessage';
import { Container, Icon, Text } from './styled';
import { AI } from '@icons';

interface IProps {
  message: AssistantMessage;
}

const AssistantChatMessage: React.FC<React.PropsWithChildren<IProps>> = ({
  message,
}) => {
  return (
    <Container>
      <Icon>
        <AI width={17} height={17} />
      </Icon>
      <Text>{message.text}</Text>
    </Container>
  );
};

export default AssistantChatMessage;
