import React from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@hooks';
import { Container, Trigger } from './styled';
import { accountActions, accountSelectors } from '@store';

import Popover from '@components/shared/ant/Popover';
import HelpCenterContent from '@components/account/AppLayout/HelpCenter/HelpCenterContent';

interface IProps {}

const HelpCenterPopover: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
}) => {
  const dispatch = useAppDispatch();

  const opened = useSelector(accountSelectors.isHelpCenterOpened);

  return (
    <Popover
      placement="right"
      overlayClassName="help-center-popover"
      open={opened}
      onOpenChange={(visible) => {
        dispatch(accountActions.toggleHelpCenter(visible));
      }}
      content={
        <Container>
          <HelpCenterContent />
        </Container>
      }
    >
      <Trigger>{children}</Trigger>
    </Popover>
  );
};

export default HelpCenterPopover;
