import styled from 'styled-components';
import AntSelect from 'antd/es/select';

import { hexToRGB } from '../../../../../core/utils/misc';
import { colors, Z_INDEX } from '@repo/shared/config';

const transparentBlue1 = hexToRGB(colors.blue1, 0.2);

export const StyledSelect = styled(AntSelect)`
  &:not(.ant-select-customize-input, .white-select) {
    .ant-select-selector {
      border-radius: 5px;
      background: ${colors.gray3};
      border-color: ${colors.gray3};
    }

    .ant-select-selection-placeholder {
      color: ${colors.gray8};
      opacity: 1;
    }

    &:hover,
    &.ant-select-open {
      .ant-select-selector {
        background: ${colors.white};
        box-shadow: 0 0 4px ${colors.blue2};
      }
    }

    &.ant-select-multiple .ant-select-selection-placeholder {
      right: 25px;
    }

    &.ant-select-disabled {
      &:hover,
      &.ant-select-open {
        .ant-select-selector {
          background: ${colors.gray3};
          box-shadow: none;
        }
      }
    }

    &.ant-select-open {
      .ant-select-selector {
        border-color: ${colors.blue2};
      }
    }

    ${({ width }: { width?: string }) =>
      !!width &&
      `
      width: ${width};
    `}
  }

  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 44px;
  }

  &.ant-select-lg .ant-select-selector {
    padding: 3px 12px;
  }

  .ant-select-item {
    min-height: 26px;
    padding: 1px 12px;
  }

  .ant-select-item-option {
    &:hover {
      background: ${transparentBlue1};
    }
  }

  .ant-select-item-option-active {
    background: ${transparentBlue1};
  }

  .ant-select-item-option-selected {
    background: ${transparentBlue1};

    .anticon-check {
      display: none;
    }
  }

  .ant-select-arrow {
    top: 52%;
    right: 10px;

    svg {
      color: ${colors.gray8};
      width: 15px;
      height: 15px;
    }
  }

  .ant-select-clear {
    right: 15px;
  }

  .ant-select-dropdown {
    z-index: ${Z_INDEX.ABOVE_SURFACE};
    border-radius: 5px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  }

  &.ant-select-multiple .ant-select-selection-item {
    border-radius: 5px;
    background: ${colors.gray6};
    border-color: ${colors.gray6};
  }
`;
