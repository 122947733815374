import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { Container, Main, Content, Title, Buttons } from './styled';
import { accountSelectors, accountActions } from '@store';
import { useAppDispatch } from '@hooks';
import { onboardingActions } from '@application/Onboarding/store/onboardingActions';
import { OnboardingModalMode } from '@application/Onboarding/enums/OnboardingModalMode';

import FullScreenModal from '@repo/shared/components/FullScreenModal/FullScreenModal';
import Scrollbar from '../../shared/Scrollbar/Scrollbar';
import Footer from '../../shared/Footer/Footer';
import Button from '@components/shared/ant/Button';
import UpdateCompanyForm from '@components/account/AuthForms/SignUp/UpdateCompanyForm';

const SetupCompanyModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isOnboardingUser = location.state?.onboarding || false;

  const opened = useSelector(accountSelectors.isSetupCompanyModalOpened);
  const currentUser = useSelector(accountSelectors.getCurrentUser);

  useEffect(() => {
    if (isOnboardingUser) {
      dispatch(accountActions.toggleSetupCompanyModal(true));
    }
  }, [isOnboardingUser]);

  function resetRouteState() {
    navigate(location.pathname, { replace: true, state: undefined });
  }

  return (
    <FullScreenModal
      opened={opened}
      noOpeningAnimation={currentUser === null || isOnboardingUser}
    >
      <Container>
        <Content>
          <Scrollbar>
            <Main>
              <Title level={4}>{formatMessage({ id: 'SetupCompany' })}</Title>
              <UpdateCompanyForm
                onSubmit={() => {
                  dispatch(accountActions.toggleSetupCompanyModal(false));

                  if (isOnboardingUser) {
                    dispatch(
                      onboardingActions.toggleOnboardingModal(
                        OnboardingModalMode.Welcome
                      )
                    );
                  }

                  resetRouteState();
                }}
              >
                {({ form, loading }) => (
                  <Buttons>
                    <Button
                      type="default"
                      disabled={loading}
                      onClick={() => {
                        form.resetFields();

                        dispatch(accountActions.toggleSetupCompanyModal(false));

                        if (isOnboardingUser) {
                          dispatch(
                            onboardingActions.toggleOnboardingModal(
                              OnboardingModalMode.Welcome
                            )
                          );
                        }

                        resetRouteState();
                      }}
                    >
                      {formatMessage({ id: 'Skip' })}
                    </Button>
                    <Button
                      type="primary"
                      disabled={loading}
                      loading={loading}
                      onClick={() => {
                        form.submit();
                      }}
                    >
                      {formatMessage({ id: 'Update' })}
                    </Button>
                  </Buttons>
                )}
              </UpdateCompanyForm>
            </Main>
          </Scrollbar>
        </Content>
        <Footer hasBackground />
      </Container>
    </FullScreenModal>
  );
};

export default SetupCompanyModal;
