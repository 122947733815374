import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { accountActions } from '@store';
import { isObject, notification } from '@utils';
import { e2eTestElements, routes } from '@config';
import { config } from '@repo/shared/config';
import { useAppDispatch } from '@hooks';
import { AnalyticsEvent } from '@repo/shared/enums';
import { sendVWOEvent } from './sendVWOEvent';

import {
  Disclaimer,
  FooterButton,
  SignUpSubmitBtn,
  SignUpTitle,
} from '@components/account/AuthForms/styled';
import Form from '@components/shared/ant/Form';
import { Input, InputPassword } from '@components/shared/ant/Input';

const CreateAccountForm: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  return (
    <>
      <SignUpTitle level={4}>{formatMessage({ id: 'CreateAnAccount' })}</SignUpTitle>
      <Form
        name="sign-up-form"
        layout="vertical"
        initialValues={{
          email: '',
          password: '',
        }}
        onFinish={async ({ email, password }: any) => {
          setLoading(true);

          const resultAction = await dispatch(
            accountActions.signUp({
              email,
              password,
            })
          );

          if (accountActions.signUp.fulfilled.match(resultAction)) {
            await dispatch(accountActions.getAppData());
            dispatch(accountActions.sendAnalyticsEvent(AnalyticsEvent.SignUp));
            sendVWOEvent();
          } else {
            if (
              isObject(resultAction.payload) &&
              resultAction.payload.profileAlreadyExists
            ) {
              notification.error({
                message: formatMessage({
                  id: 'AccountAlreadyExists',
                }),
                description: formatMessage(
                  { id: 'AccountAlreadyExistsDesc' },
                  {
                    signInLink: (
                      <a href={routes.signin}>
                        {formatMessage({ id: 'SignIn' })}
                      </a>
                    ),
                    multipleAccountGuideLink: (
                      <a href={config.urls.multipleAccounts} target="_blank">
                        {formatMessage({ id: 'MultipleAccounts' })}
                      </a>
                    ),
                  }
                ),
              });
            } else {
              notification.error({
                message: formatMessage({ id: 'Error' }),
                description: resultAction.payload as string,
              });
            }

            setLoading(false);
          }
        }}
      >
        <Form.Item
          name="email"
          label={formatMessage({ id: 'Email' })}
          rules={[
            {
              type: 'email',
              message: formatMessage({ id: 'PleaseEnterValidEmail' }),
            },
            {
              required: true,
              message: formatMessage({ id: 'PleaseEnterYourEmail' }),
            },
          ]}
        >
          <Input
            disabled={loading}
            data-e2e={e2eTestElements.account.signUpForm.emailInput}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label={formatMessage({ id: 'CreatePassword' })}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'PleaseEnterYourPassword',
              }),
            },
            {
              required: true,
              pattern: /^.{8,}$/,
              message: formatMessage({
                id: 'PasswordShouldContainMinimumEightCharacters',
              }),
            },
          ]}
        >
          <InputPassword
            disabled={loading}
            data-e2e={e2eTestElements.account.signUpForm.passwordInput}
          />
        </Form.Item>
        <Disclaimer>
          {formatMessage({
            id: 'BySigningUpYouConfirmThatYouReadAndAcceptOur',
          })}{' '}
          <a href={config.urls.termsOfUse} target="_blank">
            {formatMessage({
              id: 'TermsOfService',
            })}
          </a>{' '}
          {formatMessage({
            id: 'and',
          })}{' '}
          <a href={config.urls.privacyPolicy} target="_blank">
            {formatMessage({
              id: 'PrivacyPolicy',
            })}
          </a>
          .
        </Disclaimer>
        <Form.Item>
          <SignUpSubmitBtn
            data-e2e={e2eTestElements.account.signUpForm.submitButton}
            type="primary"
            htmlType="submit"
            style={{ margin: '24px 0 10px' }}
            loading={loading}
          >
            {formatMessage({
              id: 'GetStartedFree',
            })}
          </SignUpSubmitBtn>
        </Form.Item>
        <FooterButton
          type="button"
          onClick={() => {
            navigate(routes.signin);
          }}
        >
          {formatMessage({
            id: 'AlreadyHaveAccountSignIn',
          })}
        </FooterButton>
      </Form>
    </>
  );
};

export default CreateAccountForm;
