import styled from 'styled-components';
import Layout from 'antd/es/layout';

import { colors } from '@repo/shared/config';

const { Sider: AntSider } = Layout;

export const LogoContainer = styled.div`
  padding: 15px 20px;
  height: 64px;
  background: ${colors.blue6};
`;

export const Sider = styled(AntSider)`
  background: ${colors.blue6}!important;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;

    & > .ant-menu {
      flex-grow: 1;
    }
  }
`;

export const SidebarFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 20px 15px;
  margin: 0 -20px 0 -7px;
  text-align: center;

  & > * {
    &:first-child {
      margin-bottom: 20px;
    }
  }
`;

export const AccountsSelectorWrapper = styled.div`
  margin: 0 -9px 0 12px;
`;
