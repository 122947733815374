import React from 'react';

import PayWall from '@components/account/Billing/PayWall/PayWall';
import OnboardingModal from '@presentation/Onboarding/OnboardingModal/OnboardingModal';
import SetupCompanyModal from '@components/account/SetupCompanyModal/SetupCompanyModal';
import DownloadAppsModal from '@components/account/DownloadAppsModal/DownloadAppsModal';
import SampleDataModal from '@components/account/SampleDataModal/SampleDataModal';
import StartSampleAuditModal from '@presentation/Audits/StartSampleAuditModal';
import SelfGuidedTour from '@components/account/SelfGuidedTour/SelfGuidedTour';
import FormsDigitizationModal from '@components/shared/DigitizeFormsModal/FormsDigitizationModal';
import NewVersionReleasedModal from '@components/account/NewVersionReleasedModal';

interface IProps {}

const SharedModals: React.FC<React.PropsWithChildren<IProps>> = () => {
  return (
    <>
      <PayWall />
      <OnboardingModal />
      <SetupCompanyModal />
      <DownloadAppsModal />
      <SampleDataModal />
      <StartSampleAuditModal />
      <SelfGuidedTour />
      <FormsDigitizationModal />
      <NewVersionReleasedModal />
    </>
  );
};

export default SharedModals;
