import { BaseApiClient } from '@repo/shared/api';
import { NotUploadedFileDto } from '@infrastructure/PerformAudit/dto/NotUploadedFileDto';
import { transformNotUploadedFileDto } from '@infrastructure/PerformAudit/transformers/transformNotUploadedFileDto';

class PerformAuditApiClient extends BaseApiClient {
  public async getNotUploadedFiles(auditId: string) {
    const notUploadedFiles = await this._apiService.get<NotUploadedFileDto[]>({
      url: `audit/${auditId}/stream/files/pending`,
    });

    return notUploadedFiles.map(transformNotUploadedFileDto);
  }
}

export default PerformAuditApiClient;
