import React from 'react';

import { useLgMediaQuery } from '@repo/shared/hooks';

import HelpCenterPopover from '@components/account/AppLayout/HelpCenter/HelpCenterPopover';

interface IProps {}

const HelpCenter: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
}) => {
  const showPopover = useLgMediaQuery();

  if (showPopover) {
    return <HelpCenterPopover>{children}</HelpCenterPopover>;
  }

  return <>{children}</>;
};

export default HelpCenter;
