import { useMediaQuery } from 'react-responsive';

import { BREAKPOINTS } from '@repo/shared/config';

export const useXsMediaQuery = () =>
  useMediaQuery({ query: `(max-width: ${BREAKPOINTS.SM - 1}px)` });

export const useSmMediaQuery = () =>
  useMediaQuery({ query: `(min-width: ${BREAKPOINTS.SM}px)` });

export const useMdMediaQuery = () =>
  useMediaQuery({ query: `(min-width: ${BREAKPOINTS.MD}px)` });

export const useLgMediaQuery = () =>
  useMediaQuery({ query: `(min-width: ${BREAKPOINTS.LG}px)` });

export const useXlMediaQuery = () =>
  useMediaQuery({ query: `(min-width: ${BREAKPOINTS.XL}px)` });
