import { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { usePubNub } from 'pubnub-react';

import { Permission, RealtimeMessageType } from '@repo/shared/enums';
import { IRootState } from '@src/core/frameworks/redux';
import { accountSelectors, generalActions } from '@store';
import { Logger } from '@repo/shared/services';
import { UnexpectedError } from '@repo/shared/errors';
import { AmbassadorParams } from '@repo/shared/types';
import { useAppDispatch } from '@hooks/store';
import { InternalApiService } from '@repo/shared/api';
import { MessageEvent } from 'pubnub';
import { transferTasksActions } from '@application/TransferTasks/store/transferTasksActions';

const apiService = InternalApiService.getInstance();

export function usePermission(rolePermissionType: Permission): boolean;
export function usePermission(rolePermissionType: Permission[]): boolean[];
export function usePermission(rolePermissionTypes: Permission | Permission[]) {
  const hasPermission = useMemo<any>(accountSelectors.makeHasPermission, []);
  return useSelector((state: IRootState) =>
    hasPermission(state, rolePermissionTypes)
  );
}

export function useAmbassadorParams() {
  const [searchParams] = useSearchParams();
  const [ambassadorParams, setAmbassadorParams] = useState<AmbassadorParams>();

  useEffect(() => {
    function getAmbassadorParamsFromStorage() {
      const mbsy = localStorage.getItem('mbsy');
      const campaignId = localStorage.getItem('campaignId');
      const mbsyExpString = localStorage.getItem('mbsy_exp');

      if (mbsy && campaignId) {
        if (mbsyExpString) {
          const expirationDate = dayjs.utc(mbsyExpString);

          if (
            expirationDate.isValid() &&
            dayjs().utc().isBefore(expirationDate)
          ) {
            return { mbsy, campaignId };
          } else {
            localStorage.removeItem('mbsy');
            localStorage.removeItem('campaignId');
            localStorage.removeItem('mbsy_exp');
            return null;
          }
        } else {
          return { mbsy, campaignId };
        }
      }

      return null;
    }

    function saveAmbassadorParamsToStorage(
      mbsy: string,
      campaignId: string,
      mbsyExpString: string | undefined
    ) {
      localStorage.setItem('mbsy', mbsy);
      localStorage.setItem('campaignId', campaignId);

      if (mbsyExpString) {
        const expirationDate = dayjs.utc(
          mbsyExpString,
          'ddd, D MMM YYYY HH:mm:ss [GMT]',
          true
        );

        if (expirationDate.isValid()) {
          localStorage.setItem('mbsy_exp', expirationDate.toISOString());
        } else {
          Logger.captureException(
            new UnexpectedError(
              `Invalid mbsy_exp date format: ${mbsyExpString}`
            )
          );
        }
      }
    }

    async function getAndSetAmbassadorParams() {
      const mbsyParam = searchParams.get('mbsy');
      const campaignIdParam = searchParams.get('campaignid');
      const mbsyExpParam = searchParams.get('mbsy_exp');

      const mbsyExpString = mbsyExpParam
        ? decodeURIComponent(mbsyExpParam)
        : undefined;

      let mbsy = mbsyParam;
      let campaignId = campaignIdParam;

      if (mbsy && campaignId) {
        saveAmbassadorParamsToStorage(mbsy, campaignId, mbsyExpString);
      } else {
        const storedParams = getAmbassadorParamsFromStorage();
        if (storedParams) {
          mbsy = storedParams.mbsy;
          campaignId = storedParams.campaignId;
        }
      }

      if (mbsy && campaignId) {
        setAmbassadorParams({ mbsy, campaignId });
      }
    }

    getAndSetAmbassadorParams();
  }, [
    searchParams.get('mbsy'),
    searchParams.get('campaignid'),
    searchParams.get('mbsy_exp'),
  ]);

  return ambassadorParams;
}

export function useFavicon() {
  const appConfiguration = useSelector(accountSelectors.getAppConfiguration);

  useEffect(() => {
    if (!appConfiguration.appFaviconUrl) return;

    const faviconUrl = `${appConfiguration.appFaviconUrl}?v=${Date.now()}`;

    const updateFavicon = () => {
      const selectors =
        'link[rel="icon"], link[rel="shortcut icon"], link[rel="apple-touch-icon"]';
      document
        .querySelectorAll<HTMLLinkElement>(selectors)
        .forEach((link) => link.remove());

      const iconLink = document.createElement('link');
      iconLink.rel = 'icon';
      iconLink.href = faviconUrl;
      document.head.appendChild(iconLink);

      const appleIconLink = document.createElement('link');
      appleIconLink.rel = 'apple-touch-icon';
      appleIconLink.href = faviconUrl;
      document.head.appendChild(appleIconLink);
    };

    const timeoutId = setTimeout(updateFavicon, 200);

    return () => clearTimeout(timeoutId);
  }, [appConfiguration.appFaviconUrl]);
}

export function useRealtimeUpdates() {
  const pubnub = usePubNub();
  const dispatch = useAppDispatch();

  const companyId = useSelector(accountSelectors.getCurrentCompanyId);
  const currentUser = useSelector(accountSelectors.getCurrentUser);

  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    pubnub.setUUID(currentUser?.id || 'not-authenticated');
  }, [pubnub, currentUser?.id]);

  useEffect(() => {
    async function getPubnubToken(companyId: string) {
      try {
        const pubnubToken = await apiService.get<string>({
          url: `accounts/${companyId}/pubnub`,
          skipCompanyId: true,
        });

        setToken(pubnubToken);
      } catch (e) {
        Logger.captureException(e);
      }
    }

    if (companyId) {
      getPubnubToken(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (token && companyId) {
      pubnub.setToken(token);

      pubnub.addListener({
        message: (event: MessageEvent) => {
          switch (event.message.messageType) {
            case RealtimeMessageType.ConciseListUpdate:
              dispatch(
                generalActions.getConciseDataByType(
                  event.message.data.conciseLists
                )
              );
              break;
            case RealtimeMessageType.ImportFinished:
              dispatch(
                transferTasksActions.updateImportTask(event.message.data.dto)
              );
              break;
            case RealtimeMessageType.ExportFinished:
              dispatch(
                transferTasksActions.updateExportTask(event.message.data.dto)
              );
              break;
          }
        },
      });

      pubnub.subscribe({ channels: [`common_${companyId}`] });
    }
  }, [token, companyId]);
}
