import { v4 as uuid } from 'uuid';

import { AnswerType, ItemType } from '@repo/shared/enums';
import {
  TemplateConditionItem,
  TemplateConditionalQuestionItem,
  TemplateQuestionItem,
  TemplateRootItem,
  TemplateSectionItem,
  TemplateSimpleQuestionItem,
  TemplateQuestionSetItem,
} from '@domain/AuditTemplates/TemplateItem';
import { UnexpectedError } from '@repo/shared/errors';
import { IRootState } from '@src/core/frameworks/redux';
import { intl } from '@repo/shared/components/IntlGlobalProvider';
import { auditBuilderSelectors } from '@application/AuditBuilder/store/auditBuilderSelectors';
import { getAnswerTypeData } from '@application/AuditBuilder/utils/getAnswerTypeData';

export function createRootItem(): TemplateRootItem {
  return {
    id: uuid(),
    itemType: ItemType.Root,
    index: 0,
    parentId: null,
    childrenIds: [],
    disableNotApplicable: false,
  };
}

export function createSectionItem({
  id,
  text,
  parentId,
  index,
}: {
  id?: string;
  text?: string;
  parentId: string;
  index: number;
}): TemplateSectionItem {
  return {
    id: id || uuid(),
    itemType: ItemType.Section,
    childrenIds: [],
    text: text || intl.formatMessage({ id: 'Section' }),
    parentId,
    index,
    visibility: null,
    disableNotApplicable: false,
  };
}

export function createQuestionItem(
  {
    id,
    itemType = ItemType.Item,
    text,
    parentId,
    index,
    answerType,
  }: {
    id?: string;
    itemType?: ItemType.Item | ItemType.ConditionalItem;
    text?: string;
    parentId: string;
    index: number;
    answerType: AnswerType;
  },
  state: IRootState
): TemplateQuestionItem {
  const answerTypes = auditBuilderSelectors.getAnswerTypes(state);

  if (!answerTypes) {
    throw new UnexpectedError('answer types are missing');
  }

  const answerTypeData = getAnswerTypeData(answerType, answerTypes[answerType]);

  const base = {
    id: id || uuid(),
    text: text || intl.formatMessage({ id: 'Question' }),
    parentId,
    itemType,
    index,
    ...answerTypeData,
    childrenIds: [],
    isCritical: false,
    hasInformation: false,
    information: null,
    itemTags: [],
    isSignatureRequired: false,
    disableNotApplicable: false,
    questionSetItemId: null,
    actionTemplates: [],
    conditions: [],
  };

  if (itemType === ItemType.ConditionalItem) {
    return base as TemplateConditionalQuestionItem;
  }

  return base as TemplateSimpleQuestionItem;
}

export function createConditionItem({
  id,
  parentId,
}: {
  id?: string;
  parentId: string;
}): TemplateConditionItem {
  return {
    id: id || uuid(),
    itemType: ItemType.Condition,
    index: 0,
    parentId,
    questionSetItemId: null,
    childrenIds: [],
    condition: {
      value: 'Passed',
      field: 'name',
      compare: '==',
    },
  };
}

export function createQuestionSetItem({
  id,
  parentId,
  index,
  text,
  questionSetId,
}: {
  id?: string;
  parentId: string;
  index: number;
  text: string;
  questionSetId: string;
}): TemplateQuestionSetItem {
  return {
    id: id || uuid(),
    itemType: ItemType.QuestionSet,
    parentId,
    index,
    text,
    questionSetId,
    childrenIds: [],
  };
}
