import { createReducer } from '@reduxjs/toolkit';

import { auditsActions as actions } from '@application/Audits/store/auditsActions';
import { AuditsState } from '@application/Audits/models/AuditsState';
import { auditsAdapter } from '@application/Audits/store/entityAdapters';
import { getInitialAuditsFilters } from '@application/Audits/utils/getInitialAuditsFilters';
import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { IOrderByDirection } from '@repo/shared/types';

const initialAuditsFilters = getInitialAuditsFilters();

const initialListState = {
  data: auditsAdapter.getInitialState(),
  meta: null,
  loading: false,
  error: null,
  currentRequestId: null,
  filters: {
    [AuditsPage.My]: { ...initialAuditsFilters },
    [AuditsPage.Pending]: { ...initialAuditsFilters },
    [AuditsPage.Completed]: {
      ...initialAuditsFilters,
      orderBy: 'completeDate',
      orderByDirection: 'desc' as IOrderByDirection,
    },
    [AuditsPage.Expired]: {
      ...initialAuditsFilters,
      orderBy: 'expiredDate',
      orderByDirection: 'desc' as IOrderByDirection,
    },
  },
};

const initialDetailsState = {
  data: null,
  loading: false,
  error: null,
};

const initialState: AuditsState = {
  page: null,
  list: {
    ...initialListState,
  },
  details: {
    ...initialDetailsState,
  },
  viewAuditModal: { opened: false },
  reopenAuditModal: { opened: false },
  reportModalAudit: { opened: false },
  assignUsersToAuditsModal: { opened: false },
  openFiltersModal: false,
  openCreateOneTimeAuditModal: false,
  openStartSampleAuditModal: false,
  confirmDeleteModal: null,
  trendsModal: null,
};

export const auditsReducer = createReducer<AuditsState>(
  initialState,
  (builder) =>
    builder
      .addCase(
        actions.getAudits.pending,
        (state, { meta: { arg, requestId } }) => {
          state.list.loading = true;
          state.list.error = null;

          if (state.page === AuditsPage.My && arg === null) {
            state.list.data = auditsAdapter.getInitialState();
          }

          if (state.page !== null && state.list.filters !== null) {
            state.list.filters[state.page] =
              arg === null
                ? {
                    ...getInitialAuditsFilters(),
                  }
                : {
                    ...state.list.filters[state.page],
                    ...arg,
                  };
          }

          state.list.currentRequestId = requestId;
        }
      )
      .addCase(
        actions.getAudits.fulfilled,
        (state, { payload, meta: { arg, requestId } }) => {
          if (state.list.currentRequestId === requestId) {
            state.list.loading = false;
            state.list.meta = payload.meta;

            if (state.page === AuditsPage.My && arg !== null) {
              auditsAdapter.addMany(state.list.data, payload.data);
            } else {
              auditsAdapter.setAll(state.list.data, payload.data);
            }
          }
        }
      )
      .addCase(
        actions.getAudits.rejected,
        (state, { payload, meta: { requestId } }) => {
          if (state.list.currentRequestId === requestId) {
            state.list.loading = false;
            state.list.error = payload || null;
          }
        }
      )
      .addCase(actions.getAuditDetails.pending, (state) => {
        state.details.loading = true;
        state.details.error = null;
      })
      .addCase(actions.getAuditDetails.fulfilled, (state, { payload }) => {
        state.details.loading = false;
        state.details.data = payload;
      })
      .addCase(actions.getAuditDetails.rejected, (state, { payload }) => {
        state.details.loading = false;
        state.details.error = payload || null;
      })
      .addCase(actions.updatePendingAudit.fulfilled, (state, { payload }) => {
        if (state.details.data?.id === payload.id) {
          state.details.data = {
            ...state.details.data,
            ...payload,
          };
        }
      })
      .addCase(actions.resetListData, (state) => {
        state.list = {
          ...state.list,
          data: auditsAdapter.getInitialState(),
          meta: null,
          loading: false,
          error: null,
        };

        if (state.list.filters && state.page !== null) {
          state.list.filters = {
            ...state.list.filters,
            [state.page]: {
              ...state.list.filters[state.page],
              pageNumber: 1,
            },
          };
        }
      })
      .addCase(actions.resetDetailsData, (state) => {
        state.details = {
          ...initialDetailsState,
        };
      })
      .addCase(actions.toggleConfirmDeleteModal, (state, { payload }) => {
        state.confirmDeleteModal = payload;
      })
      .addCase(actions.toggleAssignUsersToAuditsModal, (state, { payload }) => {
        state.assignUsersToAuditsModal = payload;
      })
      .addCase(actions.setAuditsPage, (state, { payload }) => {
        state.page = payload;
      })
      .addCase(actions.toggleViewAuditModal, (state, { payload }) => {
        state.viewAuditModal = payload;
      })
      .addCase(actions.toggleCreateOneTimeAuditModal, (state, { payload }) => {
        state.openCreateOneTimeAuditModal = payload;
      })
      .addCase(actions.toggleFiltersModal, (state, { payload }) => {
        state.openFiltersModal = payload;
      })
      .addCase(actions.toggleAuditReportModal, (state, { payload }) => {
        state.reportModalAudit = payload;
      })
      .addCase(actions.toggleReopenAuditModal, (state, { payload }) => {
        state.reopenAuditModal = payload;
      })
      .addCase(actions.deleteAudits.fulfilled, (state, { payload }) => {
        auditsAdapter.removeMany(state.list.data, payload);
      })
      .addCase(actions.toggleStartSampleAuditModal, (state, { payload }) => {
        state.openStartSampleAuditModal = payload;
      })
      .addCase(actions.deleteAuditLocally, (state, { payload }) => {
        auditsAdapter.removeOne(state.list.data, payload);
      })
      .addCase(actions.updateAuditLocally, (state, { payload }) => {
        auditsAdapter.updateOne(state.list.data, payload);
      })
      .addCase(actions.toggleTrendsModal, (state, { payload }) => {
        state.trendsModal = payload;
      })
);
